(function () {
  var translations = {
    "ObjectTypes.ArchivedRevision": "Archived Revision",
    "ObjectTypes.ArchivedRevisions": "Archived Revisions",
    "ObjectTypes.ArchivedUser": "Archived User",
    "ObjectTypes.Assessment": "Assessment",
    "ObjectTypes.AssessmentContainer": "Assessment",
    "ObjectTypes.AssessmentPlural": "Assessments",
    "ObjectTypes.Certificate": "Certificate",
    "ObjectTypes.CertificateContainer": "Certificate",
    "ObjectTypes.CertificateLabel": "Certificate:",
    "ObjectTypes.CertificatePlural": "Certificates",
    "ObjectTypes.CertificateType": "Certificate Type",
    "ObjectTypes.Chapter": "Chapter",
    "ObjectTypes.ChapterPlural": "Chapters",
    "ObjectTypes.Course": "Course",
    "ObjectTypes.CourseContainer": "Course",
    "ObjectTypes.CourseLabel": "Course:",
    "ObjectTypes.CoursePlural": "Courses",
    "ObjectTypes.CourseType": "Course Type",
    "ObjectTypes.Document": "Document",
    "ObjectTypes.DocumentContainer": "Document",
    "ObjectTypes.DocumentPlural": "Documents",
    "ObjectTypes.Discussion": "Discussion",
    "ObjectTypes.DraftRevision": "Draft Revision",
    "ObjectTypes.EventLog": "Event Log",
    "ObjectTypes.StorageProviderDocument": "Document",
    "ObjectTypes.StorageProviderFolder": "Folder",
    "ObjectTypes.Folder": "Folder",
    "ObjectTypes.Group": "Group",
    "ObjectTypes.GroupPlural": "Groups",
    "ObjectTypes.Information": "Information",
    "ObjectTypes.JobTitle": "Job Title",
    "ObjectTypes.LinkedGroup": "Linked Group",
    "ObjectTypes.Location": "Location",
    "ObjectTypes.Manual": "Manual",
    "ObjectTypes.ManualContainer": "Manual",
    "ObjectTypes.ManualRevision": "Manual Revision",
    "ObjectTypes.Message": "Message",
    "ObjectTypes.PublishedRevision": "Published Revision",
    "ObjectTypes.SearchSuggestion": "Search Suggestion",
    "ObjectTypes.Section": "Section",
    "ObjectTypes.Standard": "Standard",
    "ObjectTypes.StandardPlural": "Standards",
    "ObjectTypes.StandardsManual": "Standards Manual",
    "ObjectTypes.StandardsNode": "Standards Node",
    "ObjectTypes.StorageProvider": "Provider",
    "ObjectTypes.StorageProvidersRoot": "Content Hub",
    "ObjectTypes.SubType": "Type",
    "ObjectTypes.Survey": "Survey",
    "ObjectTypes.SurveyContainer": "Survey",
    "ObjectTypes.SurveyPlural": "Surveys",
    "ObjectTypes.Tag": "Tag",
    "ObjectTypes.Test": "Test",
    "ObjectTypes.TestContainer": "Test",
    "ObjectTypes.TestLabel": "Test:",
    "ObjectTypes.TestPlural": "Tests",
    "ObjectTypes.TrainingRequest": "Training Request",
    "ObjectTypes.TrainingRequestLabel": "Training Request:",
    "ObjectTypes.TrainingRequestPlural": "Training Requests",
    "ObjectTypes.User": "User",
    "ObjectTypes.UserContainer": "Group",
    "ObjectTypes.UserPlural": "Users",
    "ObjectTypes.Workflow": "Workflow",
    "ObjectTypes.WorkflowPlural": "Workflows",
    "ObjectTypes.WorkflowPluralAbbreviation": "Workflows",
    "ObjectRoot.Assessments": "Assessments",
    "ObjectRoot.Certificates": "Certificates",
    "ObjectRoot.Courses": "Courses",
    "ObjectRoot.Documents": "Documents",
    "ObjectRoot.StandardsManuals": "Standards Manuals",
    "ObjectRoot.Surveys": "Surveys",
    "ObjectRoot.Tests": "Tests",
    "SearchType.Assessments": "Assessments",
    "SearchType.Certificates": "Certificates",
    "SearchType.ContentHub": "Content Hub",
    "SearchType.CertificateTypes": "Certificate Types",
    "SearchType.Courses": "Courses",
    "SearchType.CourseTypes": "Course Types",
    "SearchType.Discussions": "Discussions",
    "SearchType.Documents": "Documents",
    "SearchType.DocumentTypes": "Document Types",
    "SearchType.EventLog": "Event Log",
    "SearchType.Everything": "Everything",
    "SearchType.Folders": "Folders",
    "SearchType.FoldersAndDocs": "Folders and Documents",
    "SearchType.FoldersAndGroups": "Folders and Groups",
    "SearchType.Groups": "Groups",
    "SearchType.Manuals": "Standards Manuals",
    "SearchType.Tags": "Tags",
    "SearchType.Tests": "Tests",
    "SearchType.StandardsNodes": "Standards",
    "SearchType.ManualsAndStandardsNodes": "Standards Manuals",
    "SearchType.Surveys": "Surveys",
    "SearchType.Users": "Users",
    "SearchType.UsersAndGroups": "Users and Groups",
    "SearchType.Workflows": "Workflows",
    "CommonTerms.Acquire": "Acquire",
    "CommonTerms.Action": "Action",
    "CommonTerms.Actions": "Actions",
    "CommonTerms.Advance": "Advance",
    "CommonTerms.All": "All",
    "CommonTerms.Anyone": "Anyone",
    "CommonTerms.Approve": "Approve",
    "CommonTerms.Archive.Verb": "Archive",
    "CommonTerms.Archived": "Archived",
    "CommonTerms.ArchivedUser": "Archived User",
    "CommonTerms.Archiving": "Archiving",
    "CommonTerms.Assess": "Assess",
    "CommonTerms.Assignments": "Assignments",
    "CommonTerms.Asterisk": "*",
    "CommonTerms.Begin": "Begin",
    "CommonTerms.Back": "Back",
    "CommonTerms.Browse": "Browse",
    "CommonTerms.Cancel": "Cancel",
    "CommonTerms.Cancelled": "Cancelled",
    "CommonTerms.Change": "Change",
    "CommonTerms.CharsRemaining": "%(charCount)s remaining",
    "CommonTerms.CharactersRemainingSingular": "%(number)d character remaining",
    "CommonTerms.CharactersRemainingPlural": "%(number)d characters remaining",
    "CommonTerms.Checking": "Checking",
    "CommonTerms.Clear": "Clear",
    "CommonTerms.Close": "Close",
    "CommonTerms.CommaSeparator": ",",
    "CommonTerms.Comma": ",",
    "CommonTerms.Completed": "Completed",
    "CommonTerms.Continue": "Continue",
    "CommonTerms.Connect": "Connect",
    "CommonTerms.CopiedToClipboard": "Copied to clipboard",
    "CommonTerms.CopyToClipboard": "Copy to clipboard",
    "CommonTerms.CopyVerb": "Copy",
    "CommonTerms.Create": "Create",
    "CommonTerms.DateCreated": "Date Created",
    "CommonTerms.Delete": "Delete",
    "CommonTerms.Deleting": "Deleting",
    "CommonTerms.Description": "Description",
    "CommonTerms.DescriptionLabel": "Description:",
    "CommonTerms.Destination": "Destination",
    "CommonTerms.DestinationLabel": "Destination:",
    "CommonTerms.Details": "Details",
    "CommonTerms.Disable": "Disable",
    "CommonTerms.Disabled": "Disabled",
    "CommonTerms.Disconnect": "Disconnect",
    "CommonTerms.DisconnectParenthesis": "(Disconnect)",
    "CommonTerms.Dismiss": "Dismiss",
    "CommonTerms.Document": "Document",
    "CommonTerms.DocumentsStatus": "(Documents)",
    "CommonTerms.DollarSign": "$",
    "CommonTerms.Done": "Done",
    "CommonTerms.DownloadVerb": "Download",
    "CommonTerms.Draft.Noun": "Draft",
    "CommonTerms.DueDate": "Due Date",
    "CommonTerms.DueSoon": "Due Soon",
    "CommonTerms.Edit": "Edit",
    "CommonTerms.Ellipsis": "...",
    "CommonTerms.EllipsisFragment": "...%(fragment)s...",
    "CommonTerms.EmailNoun": "Email",
    "CommonTerms.EmailAddress": "Email Address",
    "CommonTerms.EmailAddresses": "Email Addresses",
    "CommonTerms.Enable": "Enable",
    "CommonTerms.Enabled": "Enabled",
    "CommonTerms.Enabling": "Enabling",
    "CommonTerms.EnableLabel": "Enable:",
    "CommonTerms.EndDate": "End Date",
    "CommonTerms.Error.Hmmm": "Hmmm...",
    "CommonTerms.ErrorLoading": "Error loading",
    "CommonTerms.Excel": "Excel",
    "CommonTerms.ExportVerb": "Export",
    "CommonTerms.ExpandRow": "Expand Row",
    "CommonTerms.File.Singular": "File",
    "CommonTerms.File.Plural": "Files",
    "CommonTerms.FillOut": "Fill out",
    "CommonTerms.Folder": "Folder",
    "CommonTerms.FolderLabel": "Folder:",
    "CommonTerms.FolderSeparator": "/",
    "CommonTerms.ForMoreInfo": "For more information",
    "CommonTerms.General": "General",
    "CommonTerms.GivenNameSurname": "%(givenName)s %(surname)s",
    "CommonTerms.GivenNameSurnameParenthesis": "(%(givenName)s %(surname)s)",
    "CommonTerms.Go": "Go",
    "CommonTerms.GoBack": "Go Back",
    "CommonTerms.GotIt": "Got it",
    "CommonTerms.Help": "Help",
    "CommonTerms.ImportItems": "Import %(count)s items",
    "CommonTerms.ImportSingleItem": "Import 1 item",
    "CommonTerms.Info": "Info",
    "CommonTerms.JobTitleLabel": "Job Title:",
    "CommonTerms.Label": "%(label)s:",
    "CommonTerms.LearnMore": "Learn More",
    "CommonTerms.LoadMore": "Load More",
    "CommonTerms.LoadingEllipsis": "Loading...",
    "CommonTerms.Location": "Location",
    "CommonTerms.LocationLabel": "Location:",
    "CommonTerms.LogoAltText": "PowerDMS Brand Logo",
    "CommonTerms.Logout": "Logout",
    "CommonTerms.Long": "Long",
    "CommonTerms.Manage": "Manage",
    "CommonTerms.Medium": "Medium",
    "CommonTerms.Name": "Name",
    "CommonTerms.NameLabel": "Name:",
    "CommonTerms.Never": "Never",
    "CommonTerms.New": "New",
    "CommonTerms.NewMessage": "New Message",
    "CommonTerms.Next": "Next",
    "CommonTerms.No": "No",
    "CommonTerms.None": "None",
    "CommonTerms.NoneStatus": "(None)",
    "CommonTerms.NotPublic": "Not Public",
    "CommonTerms.NotSetStatus": "(Not Set)",
    "CommonTerms.OK": "OK",
    "CommonTerms.Okay": "Okay",
    "CommonTerms.Open": "Open",
    "CommonTerms.OR": "OR",
    "CommonTerms.Overdue": "Overdue",
    "CommonTerms.DatePickerTooltip": "Open the calendar popup",
    "CommonTerms.Parentheses": "(%(text)s)",
    "CommonTerms.Password": "Password",
    "CommonTerms.PasswordLabel": "Password:",
    "CommonTerms.PermanentlyDelete": "Permanently Delete",
    "CommonTerms.Permissions": "Permissions",
    "CommonTerms.PhoneNumberLabel": "Phone Number:",
    "CommonTerms.PowerDMSAuthor": "PowerDMS Author",
    "CommonTerms.PowerDMSHome": "PowerDMS Home",
    "CommonTerms.PowerPoint": "PowerPoint",
    "CommonTerms.Print": "Print",
    "CommonTerms.Previous": "Previous",
    "CommonTerms.PrivacyPolicy": "Privacy Policy",
    "CommonTerms.Proceed": "Proceed",
    "CommonTerms.Public": "Public",
    "CommonTerms.Publish": "Publish",
    "CommonTerms.Published": "Published",
    "CommonTerms.Publishing": "Publishing",
    "CommonTerms.Remove": "Remove",
    "CommonTerms.Renew": "Renew",
    "CommonTerms.Reset": "Reset",
    "CommonTerms.ResetPassword": "Reset Password",
    "CommonTerms.Restore": "Restore",
    "CommonTerms.Retry": "Retry",
    "CommonTerms.ReviewVerb": "Review",
    "CommonTerms.ReviewExceptionVerb": "Review Exception",
    "CommonTerms.Save": "Save",
    "CommonTerms.Saved": "Saved",
    "CommonTerms.Saving": "Saving",
    "CommonTerms.Search": "Search",
    "CommonTerms.Searching": "Searching...",
    "CommonTerms.Section": "Section",
    "CommonTerms.Sections": "Sections",
    "CommonTerms.Security": "Security",
    "CommonTerms.SendWelcomeEmail": "Send Welcome Email",
    "CommonTerms.ShortAdjective": "Short",
    "CommonTerms.SignVerb": "Sign",
    "CommonTerms.SiteKey": "Site Key",
    "CommonTerms.SiteKeyLabel": "Site Key:",
    "CommonTerms.SiteNameLabel": "Site Name:",
    "CommonTerms.Start": "Start",
    "CommonTerms.SortByLabel": "Sort By:",
    "CommonTerms.SortOrder": "Sort Order",
    "CommonTerms.StartDate": "Start Date",
    "CommonTerms.Status": "Status",
    "CommonTerms.Stop": "Stop",
    "CommonTerms.Submit": "Submit",
    "CommonTerms.SubmittingEllipsis": "Submitting...",
    "CommonTerms.Successful": "Successful",
    "CommonTerms.SurnameGivenName": "%(surname)s, %(givenName)s",
    "CommonTerms.TaggedWithLabel": "Tagged With:",
    "CommonTerms.Take": "Take",
    "CommonTerms.TakeAdditional": "Take Additional",
    "CommonTerms.TermsOfService": "Terms Of Service",
    "CommonTerms.APITermsAndConditions": "API Terms and Conditions",
    "CommonTerms.ToggleSecretVisibility": "Toggle Secret Visibility",
    "CommonTerms.TrainingNoun": "Training",
    "CommonTerms.Update": "Update",
    "CommonTerms.Updating": "Updating",
    "CommonTerms.Upload": "Upload",
    "CommonTerms.Username": "Username",
    "CommonTerms.ViewLess": "View Less",
    "CommonTerms.ViewMore": "View More",
    "CommonTerms.VoteOn": "Vote on",
    "CommonTerms.Web": "Web",
    "CommonTerms.Word": "Word",
    "CommonTerms.Yes": "Yes",
    "AdminMenu.ApiAccess.ApiKeys": "API Keys",
    "AdminMenu.ApiAccess.ApiKeyRequest": "Request an API Key",
    "AdminMenu.ApiAccess.ApiKeyRequestText":
      "Request an API Key to gain access to the PowerDMS API",
    "AdminMenu.ApiAccess.Title": "API Access",
    "AdminMenu.ApiAccess.ClientID": "Client ID",
    "AdminMenu.ApiAccess.ClientSecret": "Client Secret",
    "AdminMenu.ApiAccess.ForMoreInformation":
      "For more information visit %(apiDocsUrl)s or contact %(apiSupportEmail)s.",
    "AdminMenu.ApiAccess.RedirectURL": "Redirect URL",
    "AdminMenu.ApiAccess.RedirectURLInfoCaption":
      "Learn more about redirect URLs",
    "AdminMenu.ApiAccess.RedirectURLInfo":
      "A redirect URL is the location where the authorization server sends the user once the app has been successfully authorized and granted a Client ID and Client Secret",
    "AdminMenu.ApiAccess.ApiClientName": "API Client Name",
    "AdminMenu.ApiAccess.ApiClientNameInfoCaption":
      "Learn more about API Client Name",
    "AdminMenu.ApiAccess.ApiClientNameInfo":
      "The name of the application that will be integrating with the PowerDMS API",
    "AdminMenu.ApiAccess.SendRequest": "Send Request",
    "AdminMenu.ApiAccess.ApiKeyRequestConfirmation":
      "I have read and agree to the %(termsOfService)s and the %(privacyPolicy)s.",
    "AdminMenu.ApiAccess.ApiKeyRequestApiTermsConfirmation":
      "I have read and agree to the %(apiTermsAndConditions)s.",
    "AdminMenu.ApiAccess.ApiKeyRequestError.RequiredField":
      "%(fieldName)s is required.",
    "AdminMenu.ApiAccess.ApiKeyRequestError.ServerRequestError":
      "Error creating new API Client. Please try again or contact support.",
    "AdminMenu.ApiAccess.ApiKeyRequestError.ApiClientNameContainsPowerDMS":
      "API Client Name cannot contain 'PowerDMS'.",
    "AdminMenu.ApiAccess.ApiKeyRequestError.MaxNumberOfClientsExceeded":
      "Maximum number of allowed clients reached.",
    "AdminMenu.ApiAccess.ApiKeyRequestError.RedirectUrlNotWellFormed":
      "Redirect URL is not well formed.",
    "AdminMenu.ApiAccess.ApiKeyRequestError.MaxLength":
      "The %(fieldName)s is limited to %(maxLength)s characters.",
    "AdminMenu.ApiAccess.ApiKeyRequestError.TOSConfirm":
      "Must agree to the Terms of Service and Privacy Policy.",
    "AdminMenu.ApiAccess.ApiKeyRequestError.ApiTOSConfirm":
      "Must agree to the API Terms and Conditions.",
    "AdminMenu.ApiAccess.ApiKeyActionError.DeleteRequest":
      "Error removing API Client. Please try again or contact support.",
    "AdminMenu.ApiAccess.ApiKeyActionError.UpdateRequest":
      "Error updating API Client. Please try again or contact support.",
    "AdminMenu.Backup.RequestPowerDMSBackup": "Request PowerDMS Backup",
    "AdminMenu.Backup.FeatureDescription":
      "This feature will backup your entire PowerDMS Database and Documents. This process will run during off-peak hours.\r\n                When completed, we will send you a notification that a file is available for download.\r\n                Please note that a backup file will be made available for download for 7 days before it is deleted.\r\n                After a successful backup, another backup cannot be requested for 24 hours.\r\n                Starting a new backup request will delete any previous backup files.",
    "AdminMenu.Backup.BackedUpWithin24Hours":
      "- this site has been backed up within the last 24 hours",
    "AdminMenu.Backup.CheckingLastBackupStatus":
      "Checking last backup status...",
    "AdminMenu.Backup.ErrorOccurredCheckingStatusOfBackup":
      "An error occurred trying to check the status of your backup.\r\n                Please try again by refreshing the page.\r\n                If the problem persists, please contact support.",
    "AdminMenu.Backup.RequestInProgress":
      "Backup is in progress, we will send you a notification when complete.",
    "AdminMenu.Backup.RequestCanceled":
      "Requested backup canceled. Please try again.",
    "AdminMenu.Backup.RequestFailed":
      "Requested backup failed. Please try again.\r\n                If your backup continues to fail, please contact support.",
    "AdminMenu.Backup.RequestedOn":
      "%(fileName)s (requested on %(requestedOn)s).",
    "AdminMenu.Backup.StartBackupRequest": "Start a Backup Request",
    "AdminMenu.Backup.Warning":
      "WARNING: Requesting a new backup will delete the current backup file.",
    "AdminMenu.GeneralSettings.TwelveHourOption": "12 Hour",
    "AdminMenu.GeneralSettings.TwentyFourHourOption": "24 Hour",
    "AdminMenu.GeneralSettings.ChangesToDefaultLanguageAndTimeFormat":
      "(Note: Changes to Site settings will not take effect until your next login.)",
    "AdminMenu.GeneralSettings.ChangesToTimeFormat":
      "(NOTE: Changes to Site settings will not take effect until your next login.)",
    "AdminMenu.GeneralSettings.Header": "General Application Settings",
    "AdminMenu.GeneralSettings.DefaultLanguageLabel": "Default Language:",
    "AdminMenu.GeneralSettings.TimeFormatLabel": "Time Format:",
    "AdminMenu.GeneralSettings.TimeZoneLabel": "Time Zone:",
    "AdminMenu.Licensing.AssessmentsLabel": "Assessments",
    "AdminMenu.Licensing.AssessmentLicenses": "Assessment Licenses",
    "AdminMenu.Licensing.ErrorLoadingLicensingInfo":
      "There was an error loading the licensing information.\r\n                Please refresh the page, and if you continue seeing this error, please use the contact info below.",
    "AdminMenu.Licensing.ForAdditionalLicenses":
      "For additional licenses, please email %(email)s or call %(phoneNumber)s.",
    "AdminMenu.Licensing.PurchasedLicenses": "%(purchased)s Purchased",
    "AdminMenu.Licensing.UsedLicenses": "%(used)s Used",
    "AdminMenu.Licensing.UserLicenses": "User Licenses",
    "AdminMenu.Licensing.ViewReport": "View report",
    "AdminMenu.Users.AllowUsersToAccessMobile":
      "Allow users to access PowerDMS through the mobile app (available on iOS and Android)",
    "AdminMenu.Users.AllowUsersToAuthenticate":
      "Allow mobile users to verify their identity using fingerprint, facial recognition, and other biometrics",
    "AdminMenu.Users.AllowUsersToAuthenticateAndSign":
      "Allow mobile users to verify their identity and sign documents using fingerprint, facial recognition, and other biometrics",
    "AdminMenu.Users.AllowUsersToChangeSecurity":
      "Allow Users To Change Their Account Settings %(emOpening)s(PowerDMS Password, Email, Security Questions)%(emClosing)s",
    "AdminMenu.Users.AllowUsersToEdit": "Allow Users To Edit Their Profiles",
    "AdminMenu.Users.AnErrorHasOccurred":
      "We're sorry, an error has occurred. %(breakTag)s\r\n                Code: %(errorHttpCode)s, technical details: %(error)s",
    "AdminMenu.Users.SendWelcomeEmail":
      "Send Welcome Email to New Users is enabled, but there are %(existingUsersLinkOpening)s%(usersWhoHaventLoggedIn)s existing users%(linkClosing)s\r\n                who have not logged in yet or received a welcome email in the past week.\r\n                Would you like to %(sendEmailLinkOpening)ssend a welcome email%(linkClosing)s to any of these users?",
    "AdminMenu.Users.SendEmailToNewUsers": "Send Welcome Email to New Users",
    "AdminMenu.Users.SendEmailToNewUsersDisabled":
      "New User Welcome Email is currently managed in Foundation.",
    "AdminMenu.Users.WelcomeEmailSent":
      "A welcome email has been sent to 1 user.",
    "AdminMenu.Users.WelcomeEmailSentPlural":
      "A welcome email has been sent to %(numUsers)d users.",
    "AdminMenu.WelcomeEmail.AnErrorHasOccurred":
      "We're sorry, an error has occured:",
    "AdminMenu.WelcomeEmail.DontSend": "Don't Send",
    "AdminMenu.WelcomeEmail.NewUsersWillReceiveWelcomeEmail":
      "Going forward, all new users will receive a welcome email.\r\n                The following %(usersWhoHaventLoggedIn)s existing users\r\n                have not logged in yet or received a welcome email in the past week.\r\n                Would you like to send these users a welcome email?\r\n        ",
    "AdminMenu.WelcomeEmail.NoEmailAssociatedWithUser":
      "There's no email address associated with this user.",
    "AdminMenu.WelcomeEmail.SelectedUser": "Send to 1 User",
    "AdminMenu.WelcomeEmail.SelectedUsers": "Send to %(selectedUsers)s Users",
    "AdminMenu.WelcomeEmail.WelcomeEmailEnabled": "Welcome Email Enabled",
    "AdvancedSearch.DefaultError":
      "We're sorry, an error has occurred. Please check your internet connection and try again.",
    "AdvancedSearch.DefaultErrorWithLink":
      "We're sorry, an error has occurred. Please check your internet connection and %(linkStart)stry again%(linkEnd)s.",
    "AdvancedSearch.DidYouMean": "Did you mean",
    "AdvancedSearch.ExportExcel":
      "Export all search results as an excel spreadsheet",
    "AdvancedSearch.ExportingStatus": "Exporting...",
    "AdvancedSearch.ExportPDF": "Export all search results as a pdf",
    "AdvancedSearch.PageNotFound":
      "Error connecting, please check your internet connection and try again.",
    "AdvancedSearch.SearchDiscussions": "Search Discussions",
    "AdvancedSearch.SearchEventLog": "Search Event Log",
    "AdvancedSearch.SearchWorkflows": "Search Workflows",
    "AdvancedSearch.SearchPhraseLabel": "Search Phrase:",
    "AdvancedSearch.SearchTypeLabel": "Search Type:",
    "AdvancedSearch.ServerError":
      "We're sorry, an internal error has occurred. If reducing the size of your search doesn't help, please contact support.",
    "AdvancedSearch.StandardsManualSuggestion":
      "Don't see the standards manual you are looking for? %(linkStart)sClick here%(linkEnd)s to see the organizations that we are working with to provide you with the most up-to-date electronic standards.",
    "AdvancedSearch.Title": "Advanced Search",
    "AdvancedSearch.Filters.AllFoldersPlaceholder": "All folders",
    "AdvancedSearch.Filters.AnonymousLabel": "Anonymous:",
    "AdvancedSearch.Filters.AssignedToGroupLabel": "Assigned to Group:",
    "AdvancedSearch.Filters.CertificateTypeLabel": "Certificate Type:",
    "AdvancedSearch.Filters.CourseTypeLabel": "Course Type:",
    "AdvancedSearch.Filters.DateRangeSeparator": "to",
    "AdvancedSearch.Filters.FirstNameLabel": "First Name:",
    "AdvancedSearch.Filters.DocTypesAllTypesPlaceholder": "All types",
    "AdvancedSearch.Filters.EnabledLabel": "Enabled:",
    "AdvancedSearch.Filters.FolderAlreadyAdded":
      "This folder has already been added as a filter",
    "AdvancedSearch.Filters.HasAdministratorPrivilegesLabel":
      "Has Administrator Privileges",
    "AdvancedSearch.Filters.HasDraftRevisionLabel": "Has Draft Revision:",
    "AdvancedSearch.Filters.IncludeLabel": "Include:",
    "AdvancedSearch.Filters.IncludePastSectionsLabel": "Include Past Sections:",
    "AdvancedSearch.Filters.LastNameLabel": "Last Name:",
    "AdvancedSearch.Filters.MasterAdministratorLabel": "Master Administrator",
    "AdvancedSearch.Filters.MemberOfLabel": "Member Of:",
    "AdvancedSearch.Filters.PrivilegesLabel": "Privileges:",
    "AdvancedSearch.Filters.ShowArchivedLabel": "Show Archived:",
    "AdvancedSearch.Filters.ShowArchivedAssessmentsLabel":
      "Show Archived Assessments:",
    "AdvancedSearch.Filters.ArchivedLabel": "Archived:",
    "AdvancedSearch.Filters.ShowDisabledArchivedLabel":
      "Show Disabled/Archived:",
    "AdvancedSearch.Filters.ShowExpiredRevisionsLabel":
      "Show Expired Revisions:",
    "AdvancedSearch.Filters.ShowTagsTypesFoldersLabel":
      "Show Tags/Types/Folders:",
    "AdvancedSearch.Filters.StartDateRangeLabel": "Start Date Range:",
    "AdvancedSearch.Filters.TypeLabel": "Type:",
    "AdvancedSearch.Filters.UsernameLabel": "Username:",
    "AdvancedSearch.Pagination.ItemsOf":
      "Items %(startCount)s to %(endCount)s of %(totalCount)s",
    "AdvancedSearch.Pagination.PageSize": "Page size",
    "AdvancedSearch.Pagination.PageXOfY":
      "Page %(currentPage)s of %(totalPages)s",
    "AdvancedSearch.Results.AnonymousColumn": "Anonymous",
    "AdvancedSearch.Results.ArchivedColumn": "Archived",
    "AdvancedSearch.Results.AwardNoCertificate": "Awards no certificates",
    "AdvancedSearch.Results.AwardOneCertificate": "Awards 1 certificate",
    "AdvancedSearch.Results.AwardMultipleCertificates":
      "Awards %(certificateCount)s certificates",
    "AdvancedSearch.Results.CollapseRevisionsTooltip": "Collapse Revisions",
    "AdvancedSearch.Results.CollapseSectionsTooltip": "Collapse Sections",
    "AdvancedSearch.Results.CreditHoursColumn": "Credit Hours",
    "AdvancedSearch.Results.DescriptionColumn": "Description",
    "AdvancedSearch.Results.DisabledStatus": "(Disabled)",
    "AdvancedSearch.Results.DocumentNameColumn": "Document Name",
    "AdvancedSearch.Results.DocumentRootFolder": "(Documents)",
    "AdvancedSearch.Results.DraftRevisionColumn": "Revision Date (Draft)",
    "AdvancedSearch.Results.DueDateColumn": "Due Date",
    "AdvancedSearch.Results.EnabledColumn": "Enabled",
    "AdvancedSearch.Results.EndDateColumn": "End Date",
    "AdvancedSearch.Results.EndDateDefault": "-",
    "AdvancedSearch.Results.ExpiresColumn": "Expires",
    "AdvancedSearch.Results.FilledSeats": "%(filledSeats)s / %(totalSeats)s",
    "AdvancedSearch.Results.FirstNameColumn": "First Name",
    "AdvancedSearch.Results.FolderColumn": "Folder",
    "AdvancedSearch.Results.GenericFilterTooltip":
      "Add this %(objectType)s as a filter",
    "AdvancedSearch.Results.GenericManageTooltip": "Manage %(objectType)s",
    "AdvancedSearch.Results.GenericViewTooltip": "View %(objectType)s",
    "AdvancedSearch.Results.JobTitleColumn": "Job Title",
    "AdvancedSearch.Results.LastNameColumn": "Last Name",
    "AdvancedSearch.Results.LinkedToColumn": "Linked To",
    "AdvancedSearch.Results.LocationColumn": "Location",
    "AdvancedSearch.Results.ManageAssessmentTooltip": "Manage Assessment",
    "AdvancedSearch.Results.ManageCertificateTooltip": "Manage Certificate",
    "AdvancedSearch.Results.ManageCourseTooltip": "Manage Course",
    "AdvancedSearch.Results.ManageDocumentTooltip": "Manage Document",
    "AdvancedSearch.Results.ManageSectionTooltip": "Manage Section",
    "AdvancedSearch.Results.ManualNoRevisions": "This manual has no revisions.",
    "AdvancedSearch.Results.NameColumn": "Name",
    "AdvancedSearch.Results.NoAvailableSections":
      "This course has no available sections at this time.",
    "AdvancedSearch.Results.NoRecords": "No records to display.",
    "AdvancedSearch.Results.PublishedColumn": "Published",
    "AdvancedSearch.Results.PublishedRevisionColumn":
      "Revision Date (Published)",
    "AdvancedSearch.Results.RevisionColumn": "Revision",
    "AdvancedSearch.Results.SeatsColumn": "Seats",
    "AdvancedSearch.Results.SeatCountDefault": "Unlimited",
    "AdvancedSearch.Results.SectionNumberColumn": "Section #",
    "AdvancedSearch.Results.ShowRevisionsTooltip": "Show Revisions",
    "AdvancedSearch.Results.ShowSectionsTooltip": "Show Sections",
    "AdvancedSearch.Results.StandardsManualColumn": "Standards Manual",
    "AdvancedSearch.Results.StartDateColumn": "Start Date",
    "AdvancedSearch.Results.StartDateDefault": "Anytime",
    "AdvancedSearch.Results.TitleColumn": "Title",
    "AdvancedSearch.Results.TypeColumn": "Type",
    "AdvancedSearch.Results.UsernameColumn": "Username",
    "AdvancedSearch.Results.ViewAssessmentTooltip": "View Assessment",
    "AdvancedSearch.Results.ViewCertificateTooltip": "View Certificate",
    "AdvancedSearch.Results.ViewCourseTooltip": "View Course",
    "AdvancedSearch.Results.ViewDocumentTooltip": "View Document",
    "AdvancedSearch.Results.ViewDraftRevisionTooltip": "View Draft Revision",
    "AdvancedSearch.Results.ViewPublishedRevisionTooltip":
      "View Published Revision",
    "AdvancedSearch.Results.ViewRevisionTooltip": "View Revision",
    "AdvancedSearch.Results.ViewSectionTooltip": "View Section",
    "Assessments.DueDateLabel": "Due Date:",
    "Assessments.ManualPublicationLabel": "Manual / Publication:",
    "Assessments.NameLabel": "Assessment Name:",
    "Assessments.ViewAssessment": "View Assessment",
    "Assessments.YourRoleLabel": "Your Role:",
    "Assessments.CopyTasks.AssignToRole": "Assign Tasks to Role",
    "Assessments.CopyTasks.CopyTasks": "Copy Tasks",
    "Assessments.CopyTasks.CopyToAssessment": "Copy to Assessment",
    "Assessments.CopyTasks.CopyToRole": "Copy to Role",
    "Assessments.CopyTasks.DestinationAssessmentLabel":
      "Destination Assessment:",
    "Assessments.CopyTasks.DoesNotRepeat": "Does not repeat",
    "Assessments.CopyTasks.Header": "Copy Tasks",
    "Assessments.CopyTasks.NoStartDate":
      "You must set a Start Date for this Assessment.",
    "Assessments.CopyTasks.NonCopyablePlural":
      "%(numTasks)s tasks cannot be copied because you are not a participant\r\n          in %(roleName)s on %(assessmentName)s for the relevant standard, or the\r\n          relevant standard doesn't exist.",
    "Assessments.CopyTasks.NonCopyableSingular":
      "1 task cannot be copied because you are not a participant\r\n          in %(roleName)s on %(assessmentName)s for the relevant standard, or the\r\n          relevant standard doesn't exist.",
    "Assessments.CopyTasks.NoValidAssessments":
      "There are no valid assessments to copy to.",
    "Assessments.CopyTasks.NoValidRoles":
      "There are no valid roles to copy to.",
    "Assessments.CopyTasks.NumberTasksPlural":
      "%(numTasks)s tasks can be copied.",
    "Assessments.CopyTasks.NumberTasksSingular": "1 task can be copied.",
    "Assessments.CopyTasks.Repeats":
      "Repeats every %(recurrencePeriod)s %(recurrencePeriodType)s until %(endDate)s.",
    "Assessments.CopyTasks.SourceAssessmentLabel": "Source Assessment:",
    "Assessments.CopyTasks.StartDate": "Start Date: %(startDate)s",
    "Assessments.CopyTasks.StepOne":
      "All selected tasks will be copied over to the target assessment.",
    "Assessments.CopyTasks.StepTwo":
      "When copying tasks, the start dates of the source and destination assessments are\r\n          used to set task dates. Please ensure the start date of both assessments are accurate.\r\n          Start dates can be updated from the Manage Assessment page.",
    "Assessments.CopyTasks.StepThree":
      "All copyable tasks will be assigned to the %(roleName)s role in %(assessmentName)s\r\n          and the dates will reflect those shown below. Please review the following:",
    "Assessments.CopyTasks.Success": "Tasks copied successfully!",
    "Assessments.New.ChooseManualAdminRights":
      "\r\n          An assessment is created to help organize your proofs of compliance for a specific set of standards.\r\n          Please select your desired standards manual or\r\n          %(linkStart)s subscribe to other standards %(linkEnd)s\r\n        ",
    "Assessments.New.Error":
      "\r\n          We're sorry, an error has occurred. If you continue to encounter difficulties, please contact\r\n          support by calling %(phoneNumber)s or via e-mail at %(email)s.\r\n        ",
    "Assessments.New.ManualSelection": "Standards Manual Selection",
    "Assessments.New.NewAssessment": "New Assessment",
    "Assessments.New.ChooseManualNoAdminRights":
      "\r\n          An assessment is created to help organize your proofs of compliance for a specific set of standards.\r\n          Please select your desired standards manual or contact a master admin to subscribe to other standards.\r\n        ",
    "Assessments.New.StandardsManualLabel": "Standards Manual:",
    "Assessments.Title.Assessment": "Assessment",
    "Assessments.Title.New": "New Assessment",
    "Assessments.Title.General": "Assessment - General",
    "Assessments.Title.Security": "Assessment - Security",
    "Assessments.Viewer.Attachments.AffectedHighlights":
      "All affected highlights will be removed for the selected attachments.",
    "Assessments.Viewer.Attachments.AttachmentCopiedSuccessfully":
      "Attachment Copied Successfully",
    "Assessments.Viewer.Attachments.AttachmentCopyFailed":
      "Failed to Copy Attachment",
    "Assessments.Viewer.Attachments.AttachmentHasBeenCopiedTo":
      "The selected attachment %(attachmentName)s has been copied to %(count)s of %(totalCount)s standard(s) within %(assessmentName)s for the role: %(roleName)s.",
    "Assessments.Viewer.Attachments.AttachmentFailedToCopyTo":
      "The attachment failed to copy to the following standards:",
    "Assessments.Viewer.Attachments.DocumentHasNoHighlights":
      "This document has no highlights.",
    "Assessments.Viewer.Attachments.DownloadAttachment": "Download Attachment",
    "Assessments.Viewer.Attachments.ChangeAssessment": "Change Assessment",
    "Assessments.Viewer.Attachments.CollapseAllHighlights":
      "Collapse All Highlights",
    "Assessments.Viewer.Attachments.CopyAttachment": "Copy Attachment",
    "Assessments.Viewer.Attachments.CopyAttachmentsTitle": "Copy Attachments",
    "Assessments.Viewer.Attachments.CopyToRole": "Copy to Role:",
    "Assessments.Viewer.Attachments.DeleteAttachment": "Delete Attachment",
    "Assessments.Viewer.Attachments.DragZoneMessage": "Drop files here",
    "Assessments.Viewer.Attachments.DeleteHighlight": "Delete Highlight",
    "Assessments.Viewer.Attachments.EditAttachment": "Edit Attachment",
    "Assessments.Viewer.Attachments.EditHighlights": "Edit Highlights",
    "Assessments.Viewer.Attachments.EditSelection": "Edit Selection",
    "Assessments.Viewer.Attachments.ErrorAssessmentArchived":
      "The destination assessment has been archived.",
    "Assessments.Viewer.Attachments.ErrorAssessmentNotRetrieved":
      "Failed to load assessments. Please close this modal and try again.",
    "Assessments.Viewer.Attachments.ErrorAttachmentDoesNotExist":
      "The attachment you are trying to copy does not exist.",
    "Assessments.Viewer.Attachments.ErrorConversionAgree":
      "You must agree before proceeding with a file conversion.",
    "Assessments.Viewer.Attachments.ErrorFileNameLength":
      "File name may be no longer than %(maxChars)d characters. Please rename the file you wish to upload.",
    "Assessments.Viewer.Attachments.ErrorInsufficientPermissions":
      "You do not have sufficient permissions to perform this action.",
    "Assessments.Viewer.Attachments.ErrorLockedRole":
      "A publisher-locked role cannot be used to attach a PowerDMS Document.",
    "Assessments.Viewer.Attachments.ErrorNoNotes":
      "You must enter notes to create an attachment.",
    "Assessments.Viewer.Attachments.ErrorNoAssignedRoles": "No assigned roles.",
    "Assessments.Viewer.Attachments.ErrorNoStandardsFoundForRole":
      "No standards found for the selected role.",
    "Assessments.Viewer.Attachments.ErrorNodeDoesNotAllowAttachments":
      "This standard node does not allow attachments.",
    "Assessments.Viewer.Attachments.ErrorNodesNotFoundForAssessment":
      "This standard node does not exist on this assessment.",
    "Assessments.Viewer.Attachments.ErrorNoPoints":
      "You must choose at least one standard point to attach to.",
    "Assessments.Viewer.Attachments.ErrorUpload": "Error uploading file.",
    "Assessments.Viewer.Attachments.ExpandAllHighlights":
      "Expand All Highlights",
    "Assessments.Viewer.Attachments.GoToAffectedStandards":
      "Go to affected Standards to edit attachments in a new tab",
    "Assessments.Viewer.Attachments.HighlightDisplayAsPdf":
      "This highlight was made when the attached document was set to display as PDF. Please change the document's display mode back to PDF or contact an administrator.",
    "Assessments.Viewer.Attachments.HighlightPageMissing":
      "The page this highlight was on no longer exists.",
    "Assessments.Viewer.Attachments.HighlightPointError":
      "Please be sure this highlight has a label and is attached to at least one point.",
    "Assessments.Viewer.Attachments.Highlights": "Highlights",
    "Assessments.Viewer.Attachments.HighlightsMayHaveChanged":
      "Note: The text of the highlights could have changed and the highlights should be reviewed.",
    "Assessments.Viewer.Attachments.HighlightsReviewRemaining":
      "The text of the remaining highlights could have changed and the remaining highlights should be reviewed.",
    "Assessments.Viewer.Attachments.HighlightsWillBeRemoved":
      "Note: Updating this attachment will cause the following highlights made on it to be deleted.",
    "Assessments.Viewer.Attachments.HighlightTitle": "Highlight",
    "Assessments.Viewer.Attachments.InformationTitle": "Information",
    "Assessments.Viewer.Attachments.NoAttachmentsSelectedCopy":
      "No attachments have been selected to copy.",
    "Assessments.Viewer.Attachments.NoAttachmentsSelectedUpdate":
      "No attachments have been selected to update.",
    "Assessments.Viewer.Attachments.NoStandardsFound": "No standards found.",
    "Assessments.Viewer.Attachments.NotAssociatedHighlight":
      "This highlight is not associated with the bullet point you are currently filtering by.",
    "Assessments.Viewer.Attachments.OpenInDocumentViewer":
      "Open in Document Viewer",
    "Assessments.Viewer.Attachments.PleaseConfirmTitle": "Please Confirm",
    "Assessments.Viewer.Attachments.PointSelected": "1 point selected",
    "Assessments.Viewer.Attachments.PointSelectedPlural":
      "%(numPoints)d points selected",
    "Assessments.Viewer.Attachments.RoleLabel": "Role:",
    "Assessments.Viewer.Attachments.SearchStandards": "Search Standards",
    "Assessments.Viewer.Attachments.SelectHighlight":
      "Select your highlight on the document to the left.",
    "Assessments.Viewer.Attachments.SelectionSummary":
      "Selection Summary: %(attachmentName)s",
    "Assessments.Viewer.Attachments.SelectedStandardsCount":
      "(%(count)s) Selected",
    "Assessments.Viewer.Attachments.SelectAttachmentDestination":
      "Select a destination for this attachment",
    "Assessments.Viewer.Attachments.ThisAssessment": "(This Assessment)",
    "Assessments.Viewer.Attachments.UpdateAttachmentTitle": "Update Attachment",
    "Assessments.Viewer.Attachments.VerifyHighlight":
      "Please verify that the highlight is where you want it to be.",
    "Assessments.Viewer.Attachments.SelectStandardToCopyAttachmentTo":
      "Select the Standards to copy %(attachmentName)s to:",
    "Assessments.Viewer.Attachments.EmptyStateDescription":
      "Select a Role to view Standards",
    "Assessments.Viewer.Export.Available":
      "The assessment export will be available for 7 days",
    "Assessments.Viewer.Export.CancelModalTitle":
      "Are you sure you want to cancel the export?",
    "Assessments.Viewer.Export.CancelModalHeader": "Cancel Assessment Export",
    "Assessments.Viewer.Export.Cancel": "Cancel Export",
    "Assessments.Viewer.Export.DeleteModalTitle":
      "Are you sure you want to delete the export?",
    "Assessments.Viewer.Export.DeleteModalHeader": "Delete Assessment Export",
    "Assessments.Viewer.Export.ChapterSelectionCount": "Selections (%(count)s)",
    "Assessments.Viewer.Export.CreateExport": "Create a new assessment export",
    "Assessments.Viewer.Export.Download": "Download",
    "Assessments.Viewer.Export.Delete": "Delete",
    "Assessments.Viewer.Export.ExportHistory": "Export History",
    "Assessments.Viewer.Export.ExportType": "Export Type",
    "Assessments.Viewer.Export.Filters": "Filters",
    "Assessments.Viewer.Export.FullAssessment": "Full Assessment",
    "Assessments.Viewer.Export.FullAssessmentExport": "Full Assessment Export",
    "Assessments.Viewer.Export.FullAssessmentDescription":
      "The full assessment details including attachment proofs will be exported for %(assessmentName)s.",
    "Assessments.Viewer.Export.FullAssessmentSummary":
      "Full Assessment Export Selection Summary",
    "Assessments.Viewer.Export.NewExport": "New Export",
    "Assessments.Viewer.Export.NoRolesAssigned":
      "No roles have been assigned for this assessment.",
    "Assessments.Viewer.Export.InProgress":
      "Only one export can be processed at a time. When the existing request completes, another export can be requested. ",
    "Assessments.Viewer.Export.PartialAssessment": "Partial Assessment",
    "Assessments.Viewer.Export.LimitedExportMessage":
      "This manual has copyright restrictions.  The export will only contain the assessment attachments.",
    "Assessments.Viewer.Export.PartialAssessmentExport":
      "Partial Assessment Export",
    "Assessments.Viewer.Export.PartialAssessmentSummary":
      "Partial Export Summary",
    "Assessments.Viewer.Export.Recipients": "Recipients",
    "Assessments.Viewer.Export.RecipientSelectionCount":
      "Recipients (%(count)s)",
    "Assessments.Viewer.Export.SelectAll": "Select All",
    "Assessments.Viewer.Export.DeselectAll": "Deselect All",
    "Assessments.Viewer.Export.Selected": "Selected",
    "Assessments.Viewer.Export.RequestExport": "Request Export",
    "Assessments.Viewer.Export.Retry": "Retry",
    "Assessments.Viewer.Export.Role": "Role",
    "Assessments.Viewer.Export.Roles": "Roles",
    "Assessments.Viewer.Export.RoleSelectionCount": "Role (%(count)s)",
    "Assessments.Viewer.Export.SelectAllChapters": "Select All Chapters",
    "Assessments.Viewer.Export.SelectAllRoles":
      "Select all roles to be included in the assessment export.",
    "Assessments.Viewer.Export.SelectAllChaptersAndRootStandardsTitle":
      "Select what to include in the assessment export.",
    "Assessments.Viewer.Export.StepNumberOfTotal":
      "Step %(stepNumber)s of %(stepTotal)s",
    "Assessments.Viewer.Export.ViewExportStatus": "View Export Status",
    "Assessments.Viewer.Export.RetryExport": "Retry Export",
    "Assessments.Viewer.Export.ExportRequestSent": "Export Request Sent!",
    "Assessments.Viewer.Export.ExportRequestError": "Failed to request export",
    "Assessments.Viewer.Export.Error403":
      "You do not have the right permissions to export the assessment. Please contact your system administrator for more information.",
    "Assessments.Viewer.Export.Error422":
      "A problem has occurred that prevented normal execution. We apologize for this inconvenience. If the problem persists, please contact your system administrator.",
    "Assessments.Viewer.Export.Error504":
      "The request has timed out. Please try again, and contact your system administrator if the issue persists.",
    "Assessments.Viewer.Export.ExportRequestSentSummary":
      "You will be notified via email when the export is complete. You may also view the export status in the Export History tab.",
    "Assessments.Viewer.Export.StatusWaiting": "Waiting",
    "Assessments.Viewer.Export.StatusQueued": "Queued",
    "Assessments.Viewer.Export.StatusRunning": "Running",
    "Assessments.Viewer.Export.FullExportTypeStatus": "Full",
    "Assessments.Viewer.Export.PartialExportTypeStatus": "Partial",
    "Assessments.Viewer.Export.StatusSuccess": "Completed",
    "Assessments.Viewer.Export.StatusCanceled": "Canceled",
    "Assessments.Viewer.Export.StatusFailed": "Failed",
    "Assessments.Viewer.Export.Status": "Status",
    "Assessments.Viewer.Export.User": "User",
    "Assessments.Viewer.Export.Date": "Date",
    "Assessments.Viewer.Export.Revision": "Revision",
    "Assessments.Viewer.Export.Expire": "Expire",
    "Assessments.Viewer.Export.Days": "Days",
    "Assessments.Viewer.Standards.InvalidExpirationDate":
      "An invalid expiration date was detected. Please review your changes.",
    "Assessments.Viewer.Standards.NoStatusSetAlert":
      "A status must be set in order to have an expiration. Please review your changes.",
    "Assessments.Viewer.Standards.RoleChangeConfirmation":
      "Are you sure you want to change roles? Any changes you have made will be lost.",
    "Assessments.Viewer.Standards.StatusNotSet": "Not Set",
    "Assessments.Viewer.Standards.ViewAttachmentsTooltip": "View Attachments",
    "Assessments.Viewer.Standards.ViewStandardTooltip": "View Standard",
    "Assessments.Viewer.Standards.ViewTasksTooltip": "View Tasks",
    "Assessments.Viewer.TaskStatus.Any": "Any",
    "Assessments.Viewer.TaskStatus.CommaListSeparator": ",",
    "Assessments.Viewer.TaskStatus.None": "None",
    "AttributePicker.ShowAll": "Show All %(searchType)s",
    "AttributePicker.GroupMembersCount": "Members (%(numMembers)d)",
    "AttributePicker.UnsavedChanges":
      "You have not saved your pending changes. Are you sure you want to leave?",
    "AttributePicker.Add.UsersAndGroups": "Add Users or Groups",
    "AttributePicker.Add.FoldersAndDocs": "Add Folders or Documents",
    "AttributePicker.Add.ObjectType": "Add %(objectType)s",
    "AttributePicker.Add.Button": "Add",
    "AttributePicker.AutoAdd.Users": "Auto-Add Users",
    "AttributePicker.AutoAdd.ObjectType": "Auto-Add %(searchType)s",
    "AttributePicker.AutoAdd.OnlyMasterAdmin":
      "Only Master Administrators may modify these settings",
    "AttributePicker.AutoAdd.ApplyButton": "Apply",
    "AttributePicker.Checkboxes.Cascading": "Cascading",
    "AttributePicker.Checkboxes.Edit": "Edit",
    "AttributePicker.Checkboxes.Audit": "Audit",
    "AttributePicker.Checkboxes.View": "View",
    "AttributePicker.Checkboxes.Need": "Need",
    "AttributePicker.Checkboxes.Sign": "Sign",
    "AttributePicker.Checkboxes.Take": "Take",
    "AttributePicker.Checkboxes.AutoAddUsers": "Auto-Add All Users",
    "AttributePicker.Checkboxes.Content": "Content",
    "AttributePicker.Checkboxes.Member": "Member",
    "AttributePicker.DocSettings.Header": "Settings",
    "AttributePicker.DocSettings.Enable": "Enable",
    "AttributePicker.Error.GroupMembers":
      "Edit rights are required to modify group membership.",
    "AttributePicker.Error.GroupOverThisGroup":
      "Cascading edit rights are required to modify group security.",
    "AttributePicker.Error.GroupUsers":
      "Edit rights are required to modify security over users.",
    "AttributePicker.Error.GroupGroups":
      "Edit rights are required to modify security over groups.",
    "AttributePicker.Error.UserMembers":
      "Edit rights are required to modify user membership.",
    "AttributePicker.Error.UserOverThisGroup":
      "Edit rights are required to modify user security.",
    "AttributePicker.Error.FolderOverThisGroup":
      "Cascading edit rights are required to modify folder security.",
    "AttributePicker.Error.FolderAssignments":
      "Cascading edit rights are required to modify folder assignments.",
    "AttributePicker.Error.RightsRequired":
      "%(neededRights)s rights are required to modify this.",
    "AttributePicker.Error.Generic": "An error occurred. Try again.",
    "AttributePicker.Filters.AdvancedHeader": "Advanced Filters",
    "AttributePicker.Filters.ApplyFilters": "Apply Filters",
    "AttributePicker.Filters.EnableOnDate": "Enable on date:",
    "AttributePicker.Filters.EnableAtTime": "Enable at time:",
    "AttributePicker.Filters.FilterObjectType": "Filter %(objectType)s",
    "AttributePicker.Filters.FilterHeader": "Filter",
    "AttributePicker.Filters.ShowUsers": "Show Only Users",
    "AttributePicker.Filters.ShowGroups": "Show Only Groups",
    "AttributePicker.Filters.ShowUsersAndGroups": "Show Users & Groups",
    "AttributePicker.Filters.ShowDocuments": "Show Only Documents",
    "AttributePicker.Filters.ShowFolders": "Show Only Folders",
    "AttributePicker.Filters.ShowDocumentsAndGroups":
      "Show Documents & Folders",
    "AttributePicker.Filters.GroupByUser":
      "Show Only Groups User Is A Member Of",
    "AttributePicker.Filters.UsersByGroup": "Show Only Members",
    "AttributePicker.Filters.HaveRights": "Show Only With Rights",
    "AttributePicker.Filters.GroupByNonMemberUser":
      "Show Only Groups User Is NOT A Member Of",
    "AttributePicker.Filters.UsersByNonMemberOfGroup": "Show Only Non-Members",
    "AttributePicker.Filters.WithoutRights": "Show Only Without Rights",
    "AttributePicker.Filters.AllGroups": "Show All Groups",
    "AttributePicker.Filters.ShowActiveUsers": "Show Members & Non-Members",
    "AttributePicker.Filters.WithRights": "Show With & Without Rights",
    "AttributePicker.Help.NeedCertificate":
      " %(openStrong)sNeed%(closeStrong)s assigns a certificate for a user to acquire.",
    "AttributePicker.Help.SignDocument":
      "%(openStrong)sSign%(closeStrong)s distributes a document for electronic signature to the user's inbox.",
    "AttributePicker.Help.AssignedFolder":
      "%(openStrong)sAssigned%(closeStrong)s distributes all published documents within\r\n        this folder and its subfolders for electronic signature to the user's inbox.\r\n        Cascading only applies to the security attributes, and has no effect on Assigned.",
    "AttributePicker.Help.TakeSurvey":
      "%(openStrong)sTake%(closeStrong)s sends a survey to a user's inbox for them to complete.",
    "AttributePicker.Help.TakeTest":
      "%(openStrong)sTake%(closeStrong)s sends a test to a user's inbox for them to complete.",
    "AttributePicker.Help.ViewAssessment":
      "%(openStrong)sView%(closeStrong)s rights allow a user to search for an assessment so that\r\n        they may observe its tasks, assignments and attachments. Users with View rights may also\r\n        participate in an assessment.",
    "AttributePicker.Help.ViewCourse":
      "%(openStrong)sView%(closeStrong)s rights permit a user to search for or enroll in a course.",
    "AttributePicker.Help.ViewDocument":
      "%(openStrong)sView%(closeStrong)s rights allow a user to view or search for a document.",
    "AttributePicker.Help.ViewFolder":
      "%(openStrong)sView%(closeStrong)s rights allow the user or group to see this folder in their\r\n        sidebar. If Cascading is also selected, the user or group also receives view rights to all subfolders\r\n        and documents within this folder.",
    "AttributePicker.Help.ViewGroup":
      "%(openStrong)sView%(closeStrong)s rights allow members of this group to see the target\r\n        group in certain hierarchy views and add a group to a workflow.",
    "AttributePicker.Help.ViewManual":
      "%(openStrong)sView%(closeStrong)s rights allow a user to search for and view a Standards manual.",
    "AttributePicker.Help.ViewUser":
      "%(openStrong)sView%(closeStrong)s rights allow members of this group to search for and view the target\r\n        user's profile; they may also add the user to a workflow.",
    "AttributePicker.Help.AuditAssessment":
      "%(openStrong)sAudit%(closeStrong)s rights, which inherit View rights, allow a user to view the assessment's\r\n        details and run a report on it.",
    "AttributePicker.Help.AuditCertificate":
      "%(openStrong)sAudit%(closeStrong)s rights permit a user to search for a certificate, view the details\r\n        of a certificate, or run a report on one.",
    "AttributePicker.Help.AuditCourse":
      "%(openStrong)sAudit%(closeStrong)s rights, which inherit View rights, give one the ability to see the\r\n        details of a course and run a report on it.",
    "AttributePicker.Help.AuditDocument":
      "%(openStrong)sAudit%(closeStrong)s rights inherit View rights, provide access to the details of\r\n        a document record and allow a user to run reports on a document.",
    "AttributePicker.Help.AuditFolder":
      "%(openStrong)sAudit%(closeStrong)s rights enable the user or group to view, but not modify,\r\n        the metadata, assignments, and security of this folder. If Cascading is also selected, the user or group\r\n        also receives audit rights over all subfolders and documents in this folder.",
    "AttributePicker.Help.AuditGroup":
      "%(openStrong)sAudit%(closeStrong)s rights enable members of this group to search for the target\r\n        group, view its details, and filter reports by it. They may also grant rights and distribute assignments to the group.",
    "AttributePicker.Help.AuditManual":
      "%(openStrong)sAudit%(closeStrong)s rights, which inherit View rights, give users access\r\n        to the details and the ability to run a report on a Standards manual.",
    "AttributePicker.Help.AuditSurvey":
      "%(openStrong)sAudit%(closeStrong)s rights give a user the ability to search for a survey,\r\n        view its details, and add it to a course.",
    "AttributePicker.Help.AuditTest":
      "%(openStrong)sAudit%(closeStrong)s rights give a user the ability to search for a test, view its\r\n         details, and run a report on it. A user with Audit rights may also add a test to a document or a course.",
    "AttributePicker.Help.AuditUser":
      "%(openStrong)sAudit%(closeStrong)s rights give users access to view the details of the user's profile\r\n        and the ability to grant rights, distribute assignments, and run a report on the user.",
    "AttributePicker.Help.EditAssessment":
      "%(openStrong)sEdit%(closeStrong)s rights inherit Audit and View rights, allowing a user to make changes\r\n        to an assessment, including its attachments, assigned tasks, and assessment roles.",
    "AttributePicker.Help.EditCertificate":
      "%(openStrong)sEdit%(closeStrong)s rights, which include Audit rights, allow a user to modify a certificate.",
    "AttributePicker.Help.EditCourse":
      "%(openStrong)sEdit%(closeStrong)s rights, also comprised of Audit and View rights, allow a user\r\n        to modify a course.",
    "AttributePicker.Help.EditDocument":
      "%(openStrong)sEdit%(closeStrong)s rights, which inherit Audit and View rights, enable a user\r\n        to modify a document.",
    "AttributePicker.Help.EditFolder":
      "%(openStrong)sEdit%(closeStrong)s rights allow the user or group to create documents and subfolders\r\n        within this folder, or move existing documents into this folder. If Cascading is also selected, the user or\r\n        group may additionally move, rename, assign, modify security over, and delete this folder. They will also\r\n        receive the same rights over all subfolders, and edit rights to all documents within this folder and its subfolders.",
    "AttributePicker.Help.EditGroup":
      "%(openStrong)sEdit%(closeStrong)s rights allow members of this group to make changes to the target\r\n        group and add or remove membership. Cascading Edit rights (edit rights to the group and all of its members)\r\n        are required to modify the security over a group and to move the group under another.",
    "AttributePicker.Help.EditManual":
      "%(openStrong)sEdit%(closeStrong)s rights, which inherit Audit and View rights, give a\r\n        user the access to modify a Standards manual.",
    "AttributePicker.Help.EditSurvey":
      "%(openStrong)sEdit%(closeStrong)s rights inherit Audit rights and give a user the ability\r\n        to modify a survey.",
    "AttributePicker.Help.EditTest":
      "%(openStrong)sEdit%(closeStrong)s rights inherit Audit rights and give a user the access\r\n        to modify a test.",
    "AttributePicker.Help.EditUser":
      "%(openStrong)sEdit%(closeStrong)s rights give access to modify a user's profile and add\r\n        or remove a user from a group.",
    "AttributePicker.Help.CascadingSecurity":
      "%(openStrong)sCascading%(closeStrong)s rights make the view, audit or edit rights also include\r\n        the members of the target group. %(openLink)sView an interactive example.%(closeLink)s",
    "AttributePicker.ObjectList.ViewPendingChanges": "View Pending Changes",
    "AttributePicker.ObjectList.SingularChangeShown":
      "Showing %(changesNumber)d Pending Change",
    "AttributePicker.ObjectList.PluralChangesShown":
      "Showing %(changesNumber)d Pending Changes",
    "AttributePicker.ObjectList.SingularChange":
      "%(changesNumber)d Pending Change",
    "AttributePicker.ObjectList.PluralChanges":
      "%(changesNumber)d Pending Changes",
    "AttributePicker.ObjectList.SecurityNote":
      "Note: Some records may be hidden due to security restrictions.",
    "AttributePicker.ObjectList.ViaNameType":
      "(via %(objectName)s %(objectType)s)",
    "AttributePicker.ObjectList.ViaNumberType":
      "(via %(objectNumber)s %(objectType)s)",
    "AttributePicker.ObjectList.InheritedFromListLabel":
      "Rights/assignments are inherited from:",
    "AttributePicker.ObjectList.NoResults":
      "No results. Try adding an item, or adjusting the Advanced Filters!",
    "AttributePicker.ObjectList.Pagination":
      "%(startCount)s to %(endCount)s of %(totalCount)s",
    "AttributePicker.SortOptions.Asc": "Asc",
    "AttributePicker.SortOptions.Desc": "Desc",
    "AttributePicker.SortOptions.AssessmentName": "Assessment Name",
    "AttributePicker.SortOptions.CertificateName": "Certificate Name",
    "AttributePicker.SortOptions.CourseNumber": "Course Number",
    "AttributePicker.SortOptions.CourseName": "Course Name",
    "AttributePicker.SortOptions.Document": "Document",
    "AttributePicker.SortOptions.DocumentName": "Document Name",
    "AttributePicker.SortOptions.FirstName": "First Name",
    "AttributePicker.SortOptions.Folder": "Folder",
    "AttributePicker.SortOptions.Group": "Group",
    "AttributePicker.SortOptions.GroupName": "Group Name",
    "AttributePicker.SortOptions.LastName": "Last Name",
    "AttributePicker.SortOptions.ManualName": "Manual Name",
    "AttributePicker.SortOptions.Name": "Name",
    "AttributePicker.SortOptions.SurveyName": "Survey Name",
    "AttributePicker.SortOptions.TestName": "Test Name",
    "AttributePicker.SortOptions.User": "User",
    "AttributePicker.SortOptions.Username": "Username",
    "AssessmentChart.PleaseRefreshError":
      "An error occurred. Please refresh and try again.",
    "AssessmentChart.Warning": "Assessment Warning:",
    "AssessmentChart.NoAssociatedRoles":
      "There are no roles associated with the manual. Roles are required before a status can be set for each standard.",
    "AssessmentChart.NoteLabel": "Note:",
    "AssessmentChart.NoStandardsInManual":
      "There are no standards located within %(assessmentName)s.",
    "AssessmentChart.NoStatusesAvailable":
      "There are no statuses available for this manual. At least one status must\r\n        be created before an assessment can be performed.",
    "AssessmentChart.SingularStandardsCount": "%(standardsCount)s Standard",
    "AssessmentChart.PluralStandardsCount": "%(standardsCount)s Standards",
    "AssessmentChart.RunReport": "Run Report",
    "AssessmentChart.NotSetStatus": "Not Set",
    "Auth0.AdfsMetadataFileLabel": "Metadata File:",
    "Auth0.AdfsMetadataUrlLabel": "Metadata Url:",
    "Auth0.AssertionConsumerServiceUrlLabel": "Assertion Consumer Service URL:",
    "Auth0.AuthenticationUpgrade": "Authentication Upgrade",
    "Auth0.EnabledConfirmation":
      "Single Sign-On settings have been successfully configured.",
    "Auth0.EntityIdLabel": "Entity ID:",
    "Auth0.ImportAuthProviderErrorMessage":
      "There was an error importing the identity provider.",
    "Auth0.RemoveAuthProviderErrorMessage":
      "There was an error removing the identity provider associated with your site.",
    "Auth0.RemovedConfirmation":
      "Single Sign-On settings have been removed for this site.",
    "Auth0.RetrievingAuthProvidersErrorMessage":
      "There was an error retrieving the identity provider associated with your site.",
    "Auth0.SamlSigningCertLabel": "Signing Certificate:",
    "Auth0.SamlSignInEndpointLabel": "Sign-In Endpoint:",
    "Auth0.SamlSsoIssuerLabel": "Issuer:",
    "Auth0.SsoIsConfigured":
      "This site is configured to use Single Sign-On with %(auth0Strategy)s.",
    "Auth0.SsoLoginSettings.Enable":
      "Enable automatic SSO auth redirect (web only)",
    "Auth0.SsoLoginSettings.Info":
      "If disabled, users will be able to optionally use Single Sign-On to login.",
    "Auth0.SsoLoginSettingsErrorLoading": "Enabled Automatic SSO redirect",
    "Auth0.SsoLoginSettingsErrorSaving":
      "There was an error saving the sso login settings for this site.",
    "Auth0.UpdatingAuthProviderErrorMessage":
      "There was an error updating the identity provider associated with your site.",
    "Auth0.SsoSettingsErrorSaving":
      "There was an error saving SSO settings for your site.",
    "Auth0.SSOLoginEmailValidation.Enable":
      "Enable email verification upon first login.",
    "Auth0.SSOLoginEmailValidation.Info":
      "If disabled, users will not receive the request to validate their email address upon their first login.",
    "Bookmark.RemoveBookmark": "Remove Bookmark",
    "BulkOperationJob.NotStarted": "Preparing...",
    "BulkWorkflowAssignment.JobInProgress":
      "Assigning Workflow to %(anchorOpen)s%(documentName)s%(anchorClose)s",
    "BulkWorkflowAssignment.JobCompletedSuccessfully":
      "%(anchorOpen)s%(totalItemsCount)s document(s)%(anchorClose)s have been successfully assigned to the selected workflow.",
    "BulkWorkflowAssignment.JobCompletedWithFailures":
      "Error: %(anchorOpen)s%(failedItemsCount)s document(s)%(anchorClose)s have not been assigned to the selected workflow.",
    "BulkWorkflowAssignment.Failed": "Failed",
    "Modals.BulkOperationJob.StopBulkWorkflowAssignment.BodyHeader":
      "Stop workflow assignment?",
    "Modals.BulkOperationJob.StopBulkWorkflowAssignment.ContinueWorkflowAssignment":
      "Continue Workflow Assignment",
    "Modals.BulkOperationJob.StopBulkWorkflowAssignment.StopWorkflowAssignment":
      "Stop Workflow Assignment",
    "Modals.BulkOperationJob.StopBulkWorkflowAssignment.StopMessage":
      "Your workflow assignment is not complete. Would you like to stop this workflow assignment?",
    "Modals.BulkOperationJob.StopBulkWorkflowAssignment.Title":
      "Workflow assignment in progress",
    "Modals.BulkOperationJob.StopBulkWorkflowAssignment.UnableToStopCompletedWorkflowAssignment":
      "We are unable to stop this workflow assignment as it has already been successfully completed.",
    "Modals.BulkOperationJob.StopBulkWorkflowAssignment.UnableToStopFailedWorkflowAssignment":
      "We are unable to stop this action as the workflow assignment has failed to complete.",
    "Certificates.InvalidFileType":
      "You have selected an unsupported file type.",
    "Certificates.LongDateFormatLabel": "Long (%(DateFormat)s)",
    "Certificates.ManageCertificate": "Manage Certificate",
    "Certificates.MediumDateFormatLabel": "Medium (%(DateFormat)s)",
    "Certificates.NewCertificate": "New Certificate",
    "Certificates.ShortDateFormatLabel": "Short (%(DateFormat)s)",
    "Certificates.ViewCertificate": "View Certificate",
    "Certificates.CourseOfferings.CourseName": "Course Name",
    "Certificates.CourseOfferings.CourseNumber": "Course Number",
    "Certificates.DeleteModal.DeleteCertificate": "Delete Certificate",
    "Certificates.DeleteModal.WarningMessage":
      'You are about to permanently delete certificate "%(certificateName)s".\r\n          This will delete all awarded certificates and all properties.',
    "Certificates.EditImageModal.AddText": "Add Text",
    "Certificates.EditImageModal.AwardedDate": "Awarded Date",
    "Certificates.EditImageModal.AwardedDateShort": "Awarded Date - Short",
    "Certificates.EditImageModal.AwardedDateMedium": "Awarded Date - Medium",
    "Certificates.EditImageModal.AwardedDateLong": "Awarded Date - Long",
    "Certificates.EditImageModal.CertificateName": "Certificate Name",
    "Certificates.EditImageModal.ExpirationDate": "Expiration Date",
    "Certificates.EditImageModal.ExpirationDateShort":
      "Expiration Date - Short",
    "Certificates.EditImageModal.ExpirationDateMedium":
      "Expiration Date - Medium",
    "Certificates.EditImageModal.ExpirationDateLong": "Expiration Date - Long",
    "Certificates.EditImageModal.Heading": "Edit Template",
    "Certificates.EditImageModal.RemoveLabel": "Remove Label",
    "Certificates.EditImageModal.ResetToDefaultImage":
      "Reset to Default Certificate Image",
    "Certificates.EditImageModal.SaveCertificate": "Save Certificate",
    "Certificates.EditImageModal.Title": "Add New Certificate",
    "Certificates.Image.SaveLabelsError":
      "Error: Attempted to save labels and failed.",
    "Certificates.Image.SaveLabelsSuccess": "Your labels have been saved.",
    "Certificates.SiteSettingsPage.AddNewCertificate": "Add New Certificate",
    "Certificates.SiteSettingsPage.CurrentCertificateLabel":
      "Current Certificate:",
    "Certificates.SiteSettingsPage.EditCurrentTemplate":
      "Edit Current Template",
    "Certificates.SiteSettingsPage.NewCertificateLabel": "New Certificate:",
    "Certificates.SiteSettingsPage.RecommendedSize":
      "(Recommended Size: 1024x1024px, 1MB or less)",
    "Certificates.SiteSettingsPage.ResetToDefault": "Reset to Default",
    "Certificates.SiteSettingsPage.Title": "Certificate Settings",
    "Certificates.SiteSettingsPage.DefaultSiteImage":
      "Default site certificate image",
    "ContentHub.AboutContentHub":
      "PowerDMS Content Hub is a digital content distribution platform that helps you find, use and distribute content from industry-leading content providers.",
    "ContentHub.AboutPowerDms":
      "PowerDMS streamlines policy, training, and accreditation lifecycles and helps you keep track of your organization’s important policies.",
    "ContentHub.AcceptAndContinue": "Accept and Continue",
    "ContentHub.AccountNameLabel": "Account Name:",
    "ContentHub.HiddenRecordsWarning":
      "Some records may be hidden due to security restrictions",
    "ContentHub.AllDocumentsImported": "All documents imported.",
    "ContentHub.ArchiveDraftWarning":
      "Notice: This document already has an existing draft revision. Importing as a new draft will archive the existing draft revision and replace it. Changing the status of the revision will automatically close any open workflows on the current revision.",
    "ContentHub.CompareAndUpdate": "Compare and Update",
    "ContentHub.CompareWithPublicationAccount": "Compare with %(accountName)s",
    "ContentHub.DocViewerPageTitle": "Content Hub Document",
    "ContentHub.DocumentImported": "Document imported.",
    "ContentHub.DocumentsLinkedTo":
      "The following documents are currently linked to %(externalDocument)s.",
    "ContentHub.EmailAlertsLabel": "Email Alerts:",
    "ContentHub.EmailAlertsNote":
      "Receive emails notifying you of updated or newly added content ",
    "ContentHub.EnableContentHubDescription":
      "Enable Content Hub for your account to connect to other PowerDMS sites",
    "ContentHub.EnableContentHubTitle": "Enable Content Hub",
    "ContentHub.ExploreContentHub": "Explore Content Hub",
    "ContentHub.ExplorePowerDms": "Explore PowerDMS",
    "ContentHub.IllDoThisLater": "I'll do this later",
    "ContentHub.Import": "Import",
    "ContentHub.ImportAsNewDraft": "Import as a New Draft",
    "ContentHub.ImportDocument": "Import Document",
    "ContentHub.ImportedSuccessfully":
      "%(anchorOpen)s%(folderName)s%(anchorClose)s imported successfully.",
    "ContentHub.ImportFailed": "Import Failed",
    "ContentHub.ImportFolder": "Import Folder",
    "ContentHub.ImportError":
      "We were unable to import %(importedItem)s from %(origin)s at this time. Error: %(error)s",
    "ContentHub.ImportFromPublicationAccount": "Import from %(accountName)s",
    "ContentHub.Importing": "Importing...",
    "ContentHub.ImportInProgress":
      "Importing %(anchorOpen)s%(folderName)s%(anchorClose)s",
    "ContentHub.ImportNotStarted":
      "Preparing %(anchorOpen)s%(folderName)s%(anchorClose)s",
    "ContentHub.ImportSuccess":
      "%(importedItem)s was successfully imported from %(origin)s to %(destination)s.",
    "ContentHub.UpdateSuccess":
      "%(importedItem)s was successfully updated from %(origin)s.",
    "ContentHub.ListOfImportedDocuments": "List of imported documents",
    "ContentHub.ManageProvider": "Manage Provider",
    "ContentHub.NewUpdateAvailable": "%(objectType)s - New update available.",
    "ContentHub.NewUpdatesAndDocumentsAvailable":
      "%(objectType)s - %(newUpdatesCount)s new updates and %(notImportedCount)s documents are available for import. %(importedCount)s/%(totalDocsCount)s imported.",
    "ContentHub.NewUpdatesAndOneDocumentAvailable":
      "%(objectType)s - %(newUpdatesCount)s new updates and 1 document are available for import. %(importedCount)s/%(totalDocsCount)s imported.",
    "ContentHub.NewUpdatesAvailable":
      "%(objectType)s - %(newUpdatesCount)s new updates available for import. %(importedCount)s/%(totalDocsCount)s imported.",
    "ContentHub.OneNewUpdateAndDocumentsAvailable":
      "%(objectType)s - 1 new update and %(notImportedCount)s documents are available for import. %(importedCount)s/%(totalDocsCount)s imported.",
    "ContentHub.OneNewUpdateAndOneDocumentAvailable":
      "%(objectType)s - 1 new update and 1 document are available for import. %(importedCount)s/%(totalDocsCount)s imported.",
    "ContentHub.OneNewUpdateAvailable":
      "%(objectType)s - 1 new update available for import. %(importedCount)s/%(totalDocsCount)s imported.",
    "ContentHub.OpenInContentHub": "Open in Content Hub",
    "ContentHub.NoPermissionsToImport":
      "%(accountName)s has not provided you with the required permissions to import updates on this document.",
    "ContentHub.NoPermissionsToView":
      "%(accountName)s has not provided you with the required permissions to view this document.",
    "ContentHub.NotProvidedRequiredPermissions":
      "Sorry, %(accountName)s has not provided you with the required permissions to view or import updates on this document.",
    "ContentHub.NoUpdateAvailableFromProvider":
      "No updates available from %(accountName)s",
    "ContentHub.RetryButton": "Retry",
    "ContentHub.ReviewChanges": "Review Changes",
    "ContentHub.UpdateFromProviderAccount": "Update from %(accountName)s",
    "ContentHub.ViewAllDocuments": "View all documents",
    "ContentHub.ViewDocumentButton": "View",
    "ContentHub.ViewImported": "View Imported",
    "ContentHub.Invitations.AcceptedInvitations": "Accepted Invitations",
    "ContentHub.Invitations.AddAnother": "Add Another",
    "ContentHub.Invitations.AddInvitees": "Add Invitees",
    "ContentHub.Invitations.AddManyAtOnce": "Add Many At Once",
    "ContentHub.Invitations.AttributePickerHeader":
      "Invite Content Hub Subscribers",
    "ContentHub.Invitations.BulkInputHelper":
      "Please use commas to separate multiple addresses",
    "ContentHub.Invitations.BulkInputError":
      "You have reached the limit for sending invitations",
    "ContentHub.Invitations.Connecting": "Connecting...",
    "ContentHub.Invitations.ConnectToPublisherSite":
      "Connect to %(publisherSite)s",
    "ContentHub.Invitations.ContinueToPowerDms": "Continue to PowerDMS",
    "ContentHub.Invitations.DateInvited": "Date Invited",
    "ContentHub.Invitations.ExportExcel": "Export Invitations",
    "ContentHub.Invitations.EmailLengthInvalid":
      "This email appears to be too long.",
    "ContentHub.Invitations.GroupRightsAndSecurityAssignmentsWarning":
      "You are about to invite users who don't have access to view any content. Provide access by granting view rights under the %(boldOpen)sRights and Assignments%(boldClose)s tab for this group.",
    "ContentHub.Invitations.InvitationMessageLabel":
      "Include a Message (Optional)",
    "ContentHub.Invitations.InvitationMessagePlaceholder":
      "Welcome to PowerDMS Content Hub! To connect the published content, please accept the invitation to access %(publisher)s's content. Once accepted, you can view and import content into your PowerDMS file cabinet.",
    "ContentHub.Invitations.InvalidEmail":
      "That does not look like a valid email address!",
    "ContentHub.Invitations.InvitationPending": "Invitation Pending",
    "ContentHub.Invitations.InviteNewSubscribers": "Invite New Subscribers",
    "ContentHub.Invitations.InviteNewUsers": "Invite New Subscribers",
    "ContentHub.Invitations.InviteNotActiveBody":
      "This invitation has expired or you may not have permission to join. Please check with the person who shared this invite with you if there is a new invite available.",
    "ContentHub.Invitations.InviteNotActiveTitle":
      "This invite is no longer active",
    "ContentHub.Invitations.InviteSent": "Invite Sent",
    "ContentHub.Invitations.InvitesSent": "Invites Sent",
    "ContentHub.Invitations.InvitedBy": "Invited By",
    "ContentHub.Invitations.JoinedOn": "Joined On",
    "ContentHub.Invitations.MailAnimationAltText":
      "Animated illustration of mail being sent",
    "ContentHub.Invitations.MailIllustrationAltText":
      "Illustration of mail in an open envelope",
    "ContentHub.Invitations.MixedSuccessWithFailurePlural":
      "You've invited 1 %(boldOpen)smember,%(boldClose)s but %(failures)s %(boldOpen)sinvitations failed to send.%(boldClose)s Review the errors below.",
    "ContentHub.Invitations.MixedSuccessWithSuccessPlural":
      "You've invited %(successes)s %(boldOpen)smembers,%(boldClose)s but 1 %(boldOpen)sinvitation failed to send.%(boldClose)s Review the error below.",
    "ContentHub.Invitations.MixedSuccessWithOnlyFailureSingular":
      "1 %(boldOpen)sinvitation failed to send.%(boldClose)s Review the error below.",
    "ContentHub.Invitations.MixedSuccessWithOnlyFailurePlural":
      "%(failures)s %(boldOpen)sinvitations failed to send.%(boldClose)s Review the errors below.",
    "ContentHub.Invitations.MixedSuccessWithSuccessAndFailurePlural":
      "You've invited %(successes)s %(boldOpen)smembers,%(boldClose)s but %(failures)s %(boldOpen)sinvitations failed to send.%(boldClose)s Review the errors below.",
    "ContentHub.Invitations.MixedSuccessWithSuccessAndFailureSingular":
      "You've invited 1 %(boldOpen)smember,%(boldClose)s but 1 %(boldOpen)sinvitation failed to send.%(boldClose)s Review the error below.",
    "ContentHub.Invitations.OrganizationName": "Organization Name (Optional)",
    "ContentHub.Invitations.OrganizationHeader": "Organization",
    "ContentHub.Invitations.ProcessingInvitations":
      "Processing your invitations",
    "ContentHub.Invitations.ReachedSiteLimitUnacceptedInvitations":
      "You’ve reached an invitation limit as many invites have been sent, but few have been accepted. Sent invitations will remain valid. Please reach out to %(email)s for assistance.",
    "ContentHub.Invitations.RequiredField": "Email address is required",
    "ContentHub.Invitations.RightsAndSecurity": "Rights And Security",
    "ContentHub.Invitations.RightsAndSecurityContent":
      "New users will automatically get rights assigned to:",
    "ContentHub.Invitations.CancelRowButton": "Cancel Row Button",
    "ContentHub.Invitations.SendInvite": "Send Invite",
    "ContentHub.Invitations.SendingInvitations":
      "We are working hard to send your invitations. This may take several minutes. Closing the window will not cancel the invitations.",
    "ContentHub.Invitations.SentInvitations": "Sent Invitations",
    "ContentHub.Invitations.Status": "Status",
    "ContentHub.Invitations.Statuses.Accepted": "Accepted",
    "ContentHub.Invitations.Statuses.Expired": "Expired",
    "ContentHub.Invitations.Statuses.Failed": "Failed",
    "ContentHub.Invitations.Statuses.Invalid": "Invalid",
    "ContentHub.Invitations.Statuses.Sent": "Sent",
    "ContentHub.Invitations.Statuses.Viewed": "Viewed",
    "ContentHub.Invitations.TryAgain": "Try Again",
    "ContentHub.Invitations.UserAlreadyExists": "This user already exists",
    "ContentHub.Invitations.ViewSentInvitations": "View Sent Invitations",
    "ContentHub.Invitations.YouHaveBeenInvited":
      "You have been invited by %(senderFullName)s to access content through PowerDMS Content Hub. This invitation was sent to",
    "ContentHub.Invitations.YouHaveBeenInvitedNoSenderUser":
      "You have been invited to access content through PowerDMS Content Hub. This invitation was sent to",
    "ContentHub.Invitations.YouHaveInvitedOneUser":
      "You have invited 1 new member to join %(groupName)s.",
    "ContentHub.Invitations.YouHaveInvitedNumUsers":
      "You have invited %(numInvited)s new members to join %(groupName)s.",
    "ContentHub.Invitations.YouHaveInvitedUsers":
      "Invited members have been added to %(groupName)s.",
    "ContentHub.VisitPowerDms": "Visit PowerDMS",
    "ContentHub.WatchThisVideo": "Watch this video",
    "Courses.Begin": "Begin",
    "Courses.Continue": "Continue",
    "Courses.CourseDetails": "Course Details",
    "Courses.CreditHours": "Credit Hours",
    "Courses.DesignCourse": "Design Course",
    "Courses.DisabledEnrollButtonTitle":
      "There are no available seats for this course section.",
    "Courses.Enroll": "Enroll",
    "Courses.Enrolled": "Enrolled",
    "Courses.ManageCourse": "Manage Course",
    "Courses.NewCourse": "New Course",
    "Courses.NotOpen": "Not Open",
    "Courses.Seats": "Seats",
    "Courses.SectionNumber": "Section #",
    "Courses.ViewEnrollmentPage": "View Enrollment Page",
    "Courses.DeleteCourseModal.DeleteCourse": "Delete Course",
    "Courses.DeleteCourseModal.WarningMessage":
      'You are about to permanently delete course "%(courseName)s".\r\n          This will delete all sections and results.',
    "Courses.DeleteSectionModal.DeleteSection": "Delete this Course Section",
    "Courses.DeleteSectionModal.WarningMessage":
      'You are about to permanently delete section "%(sectionNumber)s"\r\n          of course "%(courseName)s". This will delete all seats and results.',
    "Courses.PublishSectionModal.PublishNow": "Publish Now",
    "Courses.PublishSectionModal.PublishOn": "Publish On",
    "Courses.PublishSectionModal.PublishSection": "Publish this Course Section",
    "Courses.PublishSectionModal.WarningMessage":
      "Users will not be able to enroll in or take this course section\r\n          until it is published.",
    "Courses.Status.Enrolled": "Enrolled",
    "Courses.Status.PendingApproval": "Pending Approval",
    "DatePicker.ChangeDate": "Change Date",
    "DatePicker.Error.InvalidDate": "Invalid Date Format",
    "DatePicker.Error.MaxDate": "Date should not be after %(date)s",
    "DatePicker.Error.MinDate": "Date should not be before %(date)s",
    "DeleteModal.PermanentlyDelete":
      "I know what I'm doing: Permanently Delete",
    "DeleteModal.TypeDeleteIntoTheTextBox":
      'Type the word "%(deletePhrase)s" in the box below to confirm deletion:',
    "Documents.AddAndPublishFileAsNewRevision":
      "Add new file and publish as a new revision",
    "Documents.AddIntegration": "Add Integration",
    "Documents.AddManageIntegrations": "Add/Manage Integrations",
    "Documents.AddNewFile": "Add New File",
    "Documents.AddFile": "Add File",
    "Documents.AddGoogleDrive": "Add Google Drive%(trademark)s",
    "Documents.AddNewIntegration": "Add New Integration",
    "Documents.AddNewActiveFileToPublishedRevision":
      "Add new active file to current published revision",
    "Documents.AddOneDrive": "Add Microsoft OneDrive",
    "Documents.AreYouSureUnlockRevision":
      "Are you sure you want to unlock this revision? This will prevent any more changes from the person who is currently editing it.",
    "Documents.ActiveFileWarningShort":
      "Changing the active file on a published revision is not recommended.",
    "Documents.ActiveFileWarningLong":
      "NOTE: Changing the active file on a published revision is not recommended. New signatures will not be required.",
    "Documents.BreadcrumbEllipsisButton": "View the hidden breadcrumbs",
    "Documents.CheckAgainForUpdates": "Check Again for Updates",
    "Documents.CheckProviderForUpdates": "Check %(provider)s for updates",
    "Documents.ConnectGoogleDriveAccount":
      "Connect a Google Drive%(trademark)s Account",
    "Documents.ConnectOneDriveAccount": "Connect a Microsoft OneDrive Account",
    "Documents.ContinueEditInBrowser": "Continue to Edit In Browser",
    "Documents.ContinueEditOnDesktop": "Continue to Edit On Desktop",
    "Documents.ContinueAddNewFile": "Continue to Add New File",
    "Documents.CurrentIntegrations": "Current Integrations",
    "Documents.DisconnectFromProvider": "Disconnect from %(provider)s",
    "Documents.DiscussionPanel.PrintButton": "Print Discussion",
    "Documents.DiscussPublicationLabel": "Discuss Publication:",
    "Documents.DocConnectedToImportedFileFromProvider":
      "This document is connected to a file imported from %(provider)s. Adding a new file will disconnect this connection. Do you wish to continue?",
    "Documents.DocName": "Document Name",
    "Documents.DocStatus": "Status",
    "Documents.DocTiedToImportedFileFromProvider":
      "This document is currently tied to a file imported from %(provider)s. Adding a new revision file will unlink this connection. Do you wish to continue?",
    "Documents.DocumentHasBeenArchived":
      "%(strongOpening)sArchived:%(strongClosing)s This document has been archived.",
    "Documents.DocumentLabel": "Document:",
    "Documents.DocumentNameLabel": "Document Name:",
    "Documents.DocumentType": "Document Type",
    "Documents.DocumentViewer": "Document Viewer",
    "Documents.DuplicateMessageBox":
      "Note: Multiple documents share this name.",
    "Documents.EditInBrowser": "Edit In Browser",
    "Documents.EditOnDesktop": "Edit On Desktop",
    "Documents.EditRevision": "Edit Revision",
    "Documents.EffectiveDateLabel": "Effective Date:",
    "Documents.ErrorProviderNotSupported":
      "Error: The storage provider (%(provider)s) of this file is not supported.",
    "Documents.ImportFromProvider": "Import from %(provider)s",
    "Documents.Importing": "Importing",
    "Documents.ImportLatestChanges": "Import Latest Changes",
    "Documents.Integrations": "Integrations",
    "Documents.InternalLinkLabel": "Internal Link:",
    "Documents.LastSigned": "Last Signed",
    "Documents.LinkedProviderEmail": "%(provider)s - %(email)s",
    "Documents.LinkLabel": "Link:",
    "Documents.ManageDocument": "Manage Document",
    "Documents.ManageIntegrations": "Manage Integrations",
    "Documents.ModifyingDocMetadataWarning":
      "Modifying document metadata will affect all revisions.",
    "Documents.NameTruncatedInfo":
      "This document name has been shortened to meet maximum character limit.",
    "Documents.NewChangesToImport":
      "There are new changes to this file. Click below to import the latest changes.",
    "Documents.NewDocDateStatus": "New (%(docDate)s)",
    "Documents.NewUpdatesAreAvailable": "New updates are available",
    "Documents.NextReviewDateLabel": "Next Review Date:",
    "Documents.NoAccessibleChanges": "No Accessible Changes",
    "Documents.NoAccessToProviderFile":
      "This user does not have rights over the document in %(provider)s to request updates from. Click below to Open the document in %(provider)s and request access.",
    "Documents.NoAccountsConnected": "You haven't connected any accounts yet.",
    "Documents.NoChangesMade": "No changes have been made",
    "Documents.NoNewChanges": "No New Changes",
    "Documents.NoNewChangesFromProvider":
      "There don't seem to be any new changes on this file in your %(provider)s account. Click below to edit the document in %(provider)s.",
    "Documents.NotTaggedStatus": "Not Tagged",
    "Documents.OnlyOneAccountPerProvider":
      "You may only connect one account per integration provider.",
    "Documents.OpenInProvider": "Open in %(provider)s",
    "Documents.ArchivalScheduled": "Archival scheduled for %(date)s",
    "Documents.ProviderSourceLabel": "Provider Source:",
    "Documents.Public": "Public",
    "Documents.PublicDocumentsPage": "Public Documents Page",
    "Documents.PublicLinkLabel": "Public Link:",
    "Documents.PublicLinkWarning":
      "Enabling Public Link will make the published revision available on your public document page. Draft revisions must be published before they can be made public.",
    "Documents.PublicLinkDetail":
      "The published revision of this document is now available on your public document page. Draft revisions must be published before they can be made public.",
    "Documents.PublicSettingLabel": "Enable Public Link:",
    "Documents.PublishedDocDateStatus": "Published (%(docDate)s)",
    "Documents.PublishingRevisionRequiresDocReSign":
      "Publishing a new revision will require assigned users to re-sign this document. The current publication will be archived.",
    "Documents.RelatedStandards": "Related Standards",
    "Documents.RemoveLinkedAccount": "Remove this linked account",
    "Documents.RenameDocument": "Rename Document",
    "Documents.RequestUpdates": "Request Updates",
    "Documents.RevisedDocDateStatus": "Revised (%(docDate)s)",
    "Documents.SignaturesEnableAssignments":
      "Enable Assignments or set an enable date in the settings below.",
    "Documents.SignaturesFurtherAction":
      "This document requires further action before it is sent for signatures:",
    "Documents.SignaturesPublishRevision": "Publish a revision.",
    "Documents.SignaturesViewRevisions": "View Revisions",
    "Documents.SignInToDifferentGoogleDriveAccount":
      "Sign in to a different Google Drive%(trademark)s Account",
    "Documents.SignInToDifferentOneDriveAccount":
      "Sign in to a different Microsoft OneDrive Account",
    "Documents.TaggedWith": "Tagged With",
    "Documents.UpdateRevision": "Update Revision",
    "Documents.UnableToImportChanges": "Unable to Import Changes",
    "Documents.UnlockDocument": "Unlock Document",
    "Documents.UnableToAccessProvider":
      "PowerDMS is unable to access this file through your current integrations. To sign in to a new %(provider)s account, click below.",
    "Documents.UnableToAccessProviderTitle": "Unable to Access %(provider)s",
    "Documents.UploadFrom": "UPLOAD FROM",
    "Documents.UsersCanMakeAndViewComments":
      "Users can make and view all comments",
    "Documents.UsersCanMakeComments": "Users can make comments",
    "Documents.ViewDocument": "View Document",
    "Documents.ViewList": "View List",
    "Documents.Workflow.CompareStartWorkflow": "Compare to Start of Workflow",
    "Documents.Workflow.WorkflowStart": "Workflow Start",
    "Documents.YouHaveLatestVersion":
      "There doesn't seem to be any new changes on this file in Content Hub. Click below to open the document in Content Hub.",
    "Documents.ArchiveModal.ArchiveDocument": "Archive Document",
    "Documents.ArchiveModal.ArchiveNow": "Archive Now",
    "Documents.ArchiveModal.RemoveScheduledArchive": "Remove Scheduled Archive",
    "Documents.ArchiveModal.ArchiveOn": "Archive On",
    "Documents.ArchiveModal.ArchiveThisDocument": "Archive this Document",
    "Documents.ArchiveModal.ByArchivingDocument":
      'By archiving "%(documentName)s":',
    "Documents.ArchiveModal.DocumentCanBeRestored":
      "Once archived, the document can be restored by publishing an archived revision or a new draft.",
    "Documents.ArchiveModal.DocumentHasBeenArchived":
      "%(documentName)s has been successfully archived.",
    "Documents.ArchiveModal.RevisionsWillBeArchived":
      "Any published or draft revisions will be archived",
    "Documents.ArchiveModal.SaveAndArchive": "Save and Archive",
    "Documents.ArchiveModal.UsersWontViewOrSign":
      "Users will no longer be able to view or sign the document",
    "Documents.ArchiveModal.WorkflowsWillBeCancelled":
      "Active workflows will be canceled",
    "Documents.FromTemplateDialog.HeaderText":
      "Create using another document as a template",
    "Documents.DeleteModal.CoursesWarningPlural":
      "%(courseElementsCount)s courses will be missing this document as an element",
    "Documents.DeleteModal.CoursesWarningSingular":
      "1 course will be missing this document as an element",
    "Documents.DeleteModal.DeleteDocument": "Delete Document",
    "Documents.DeleteModal.PermanentlyDeleteDocument":
      "Permanently Delete this Document",
    "Documents.DeleteModal.RelatedStandardsWarningPlural":
      "%(relatedStandardsCount)s related standards will be missing this document as an attachment",
    "Documents.DeleteModal.RelatedStandardsWarningSingular":
      "1 related standard will be missing this document as an attachment",
    "Documents.DeleteModal.RevisionsWarningPlural":
      "%(revisionsCount)s document revisions",
    "Documents.DeleteModal.RevisionsWarningSingular": "1 document revision",
    "Documents.DeleteModal.SignaturesWarningPlural":
      "%(signaturesCount)s signature records",
    "Documents.DeleteModal.SignaturesWarningSingular": "1 signature record",
    "Documents.DeleteModal.SuccessfullyDeleted":
      '"%(documentName)s" has been successfully deleted.',
    "Documents.DeleteModal.TypeDeleteIntoTheTextBox":
      'In order to proceed with the permanent deletion of this item,\r\n          type the word "%(deletePhrase)s" into the text box:',
    "Documents.DeleteModal.WarningMessage":
      'You have chosen to delete "%(documentName)s".\r\n          By deleting this document, you will be permanently deleting the following:',
    "Documents.DeleteModal.WorkflowsWarningPlural":
      "Workflow history on %(workflowsCount)s revisions",
    "Documents.DeleteModal.WorkflowsWarningSingular":
      "Workflow history on 1 revision",
    "Documents.Aux.AssociatedImageFiles": "Associated Image Files",
    "Documents.Aux.HtmlSuccessfullyUploaded":
      "Your HTML file has been successfully uploaded",
    "Documents.Aux.NonReferencedFiles":
      "The following files are referenced in your HTML file and will not display properly unless linked. Please click the Browse button to select the corresponding image files for upload.",
    "Documents.Aux.FilesToLinkHeader": "Files to Link",
    "Documents.Aux.AuxFileReadyForLinking":
      "All aux files are ready to be linked",
    "Documents.Aux.SelectedFilesHeader": "Selected Files",
    "Documents.Aux.FilesToDiscard":
      "These files do not match any file names to the left, and will not be uploaded",
    "Documents.Aux.FinishWithoutLinking": "Finish without linking",
    "Documents.Aux.FilesRestritedToMaximumFileSize":
      'Files are restricted to a maximum filesize of %(maxFileSizeMb)sMB, and must have the same filenames as shown on "Files to Link"',
    "Documents.DisconnectExternalFileDialog.DisconnectThisDocument":
      "Disconnect this Document",
    "Documents.DisconnectExternalFileDialog.Heading":
      "Disconnect Content Source",
    "Documents.DisconnectExternalFileDialog.Description":
      "You are about to disconnect %(documentName)s from the %(providerName)s,\r\n        removing the connection between this document and its content source.\r\n        Once disconnected, you will no longer be notified of future updates on the source document.",
    "Documents.DocViewer.AutoSavetime": "Auto saved at %(time)s",
    "Documents.DocViewer.ConfirmModify":
      "Modifying the active file on a published revision is not recommended. Are you sure you wish to continue?",
    "Documents.DocViewer.DiscardChanges":
      "Discard changes? %(lineBreak)s Press Ok discard changes or Cancel to continue editing.",
    "Documents.DocViewer.EditedByText":
      "This revision is locked because it is currently being edited by %(firstName)s %(lastName)s (%(userName)s).",
    "Documents.DocViewer.PageTitle": "Document Viewer",
    "Documents.DocViewer.UnsavedChanges":
      "Are you sure you want to continue? If you do, changes to this document will be lost. Press OK to continue, or Cancel to stay.",
    "Documents.DocViewer.Collaboration.AddComment": "Add Comment",
    "Documents.DocViewer.Collaboration.ReplyTo": "Reply To: %(userName)s",
    "Documents.DocViewer.EditInBrowser.FinishEditing": "Save and Close",
    "Documents.DocViewer.CreateNewDraft.Heading": "Change Revision Status",
    "Documents.DocViewer.CreateNewDraft.Title": "Create New Draft",
    "Documents.DocViewer.CreateNewDraft.Copy":
      "Changing the status of any revision will automatically close any open workflow on that revision.",
    "Documents.DocViewer.CreateNewDraft.SaveCreateNewDraft":
      "Save and Create New Draft",
    "Documents.DocViewer.CreateNewDraft.CreatingYourDraft":
      "Creating your Draft...",
    "Documents.DocViewer.EditOnDesktop.LooksGoodTitle": "Looks Good",
    "Documents.DocViewer.EditOnDesktop.LooksGoodText":
      "We just got your latest save. If you're all done, choose the option below to continue.",
    "Documents.DocViewer.EditOnDesktop.SavedDocIsStaleTitle":
      "Finish Editing on Desktop",
    "Documents.DocViewer.EditOnDesktop.SavedDocIsStaleText":
      "It's been a while since you've last saved your work. Please confirm you have no unsaved changes before continuing.",
    "Documents.DocViewer.EditOnDesktop.NoSavedChangesTitle": "No Saved Changes",
    "Documents.DocViewer.EditOnDesktop.NoSavedChangesText":
      "It doesn't look like there are any saved changes since you began editing on desktop. Please confirm that any changes you may have made are saved before continuing.",
    "Documents.DocViewer.EditOnDesktop.ConfirmFinishEditingTitle":
      "Confirm Finish Editing",
    "Documents.DocViewer.EditOnDesktop.ConfirmFinishEditingText":
      "After confirming this action, all un-synced changes from your document will not be synced to PowerDMS and may be lost. Do you want to proceed?",
    "Documents.DocViewer.EditOnDesktop.FinishEditing": "Finish Editing",
    "Documents.DocViewer.EditOnDesktop.FinishEditingNewAuthor":
      "Finish Editing",
    "Documents.DocViewer.EditOnDesktop.ConfirmFinishEditing": "Confirm",
    "Documents.DocViewer.EditOnDesktop.ContinueEditing": "Continue Editing",
    "Documents.DocViewer.LastSaved.Hour": "Last saved: an hour ago.",
    "Documents.DocViewer.LastSaved.Hours": "Last saved: %(hours)s hours ago.",
    "Documents.DocViewer.LastSaved.Minute": "Last saved: a minute ago.",
    "Documents.DocViewer.LastSaved.Minutes":
      "Last saved: %(minutes)s minutes ago.",
    "Documents.DocViewer.LastSaved.Now": "Last saved: just now.",
    "Documents.DocViewer.RequestUpdates.Heading": "Request Updates",
    "Documents.DocViewer.RequestUpdates.Copy":
      "Drive integration has security limitations that limit updates on this document to the original uploader.\r\n          Please reach out to %(strongOpen)s%(originalUploaderName)s%(strongClose)s to import the changes or disconnect and connect the file yourself.",
    "Documents.DocViewer.RequestUpdates.DisconnectAndAddNewFile":
      "Disconnect and Add New File",
    "Documents.DocViewer.Search.CountStatus":
      "%(currentNumber)s of %(totalNumber)s",
    "Documents.DocViewer.Search.NoResults": "No results found.",
    "Documents.DocViewer.Status.Failed": "Last save failed",
    "Documents.DocViewer.Status.InstructionWhenOpened":
      "Once you're done, save your work and select Finish Editing above\r\n          to view your changes here.",
    "Documents.DocViewer.Status.Opened": "Editing on Desktop",
    "Documents.DocViewer.Status.Saving": "Saving...",
    "Documents.DocViewer.Workflow.AdvanceToNext": "Advance to the next step",
    "Documents.DocViewer.Workflow.CommentForAction":
      "Commentary for this action:",
    "Documents.DocViewer.Workflow.RequestReevaluation": "Request Re-evaluation",
    "Documents.DocViewer.Workflow.ReevaluateInstructions.AllResponseMultipleDays":
      "%(fullName)s denied this workflow %(dayCount)d days ago. Would you like to request reevaluation from this user? Any others who haven't responded in the denied All Response step will also be included in the new step.",
    "Documents.DocViewer.Workflow.ReevaluateInstructions.AllResponseOneDay":
      "%(fullName)s denied this workflow 1 day ago. Would you like to request reevaluation from this user? Any others who haven't responded in the denied All Response step will also be included in the new step.",
    "Documents.DocViewer.Workflow.ReevaluateInstructions.FirstOrDynamicFirstResponseMultipleDays":
      "%(fullName)s denied this workflow %(dayCount)d days ago. Would you like to request reevaluation from this user?",
    "Documents.DocViewer.Workflow.ReevaluateInstructions.FirstOrDynamicFirstResponseOneDay":
      "%(fullName)s denied this workflow 1 day ago. Would you like to request reevaluation from this user?",
    "Documents.DocViewer.Workflow.SkipInstructions.MultipleDaysMultipleUsers":
      "This step has been active for %(waitingDayCount)d days with responses from %(currentResponseCount)d of %(expectedResponseCount)d assigned users. Would you like to close this step, notify the users who have not participated, and advance to the next step?",
    "Documents.DocViewer.Workflow.SkipInstructions.MultipleDaysOneUser":
      "This step has been active for %(waitingDayCount)d days with no response from the assigned user. Would you like to close this step, notify the user, and advance to the next step?",
    "Documents.DocViewer.Workflow.SkipInstructions.OneDayMultipleUsers":
      "This step has been active for a day with responses from %(currentResponseCount)d of %(expectedResponseCount)d assigned users. Would you like to close this step, notify the users who have not participated, and advance to the next step?",
    "Documents.DocViewer.Workflow.SkipInstructions.OneDayOneUser":
      "This step has been active for a day with no response from the assigned user. Would you like to close this step, notify the user, and advance to the next step?",
    "Documents.DocViewer.Workflow.TryAgainOrRefresh":
      'This action cannot be completed due to an unexpected error. It\'s possible this is because someone else has acted in the workflow. You may try again or click "Cancel" to refresh the page.',
    "Documents.Duplicate.ModalTitle": "Document Name In Use",
    "Documents.Duplicate.ModalCopy":
      "You are about to name this document %(duplicateDocumentName)s, which is already in use. Would you like to continue with this name?",
    "Documents.Duplicate.ModalAdvance": "Yes, use the same name",
    "Documents.Duplicate.ModalTryAgainOrRefresh":
      'This action cannot be completed due to an unexpected error. You may try again or click "Cancel" to refresh the page.',
    "Documents.DuplicateList.ModalTitle": "Multiple Documents Share This Name",
    "Documents.DuplicateList.ModalCopy":
      "The following documents are currently using the name %(duplicateDocumentName)s.",
    "Documents.DuplicateList.SecurityNote":
      "Note: Some records may be hidden due to security restrictions.",
    "Documents.Revisions.UnlockRevisionConfirm": "Confirm Unlock",
    "Documents.Revisions.UnlockRevisionPrompt":
      "Are you sure you want to unlock this revision? This will prevent any more changes from the person who is currently editing it.",
    "Documents.New.CreateUsingDocAsTemplate":
      "Create using another document as a template",
    "Documents.New.CreatingYourDocument": "Creating your document...",
    "Documents.New.DragDropFile":
      "Drag and Drop a File %(lineBreak)s or Click to Browse Your Computer",
    "Documents.New.DropFileToUpload": "Ok! Now, drop the file to upload!",
    "Documents.New.FilterByFolderLabel": "Filter by Folder:",
    "Documents.New.FromDocumentTemplate": "From Document Template",
    "Documents.New.FromPublishedRevision": "From Published Revision",
    "Documents.New.LooksLikeBigOne":
      "Looks like this is a big one. We're still working on it.",
    "Documents.New.NewDraftFromDocTemplate": "New Draft from Document Template",
    "Documents.New.NewActiveDraftRevision": "New Active Draft",
    "Documents.New.NewActivePublishedRevision": "New Active Published Revision",
    "Documents.New.NewDoc": "New Document",
    "Documents.New.NewDraftRevision": "New Draft",
    "Documents.New.NewPublishedRevision": "New Published Revision",
    "Documents.New.NewWorkflow": "New Workflow",
    "Documents.Workflow.StartNewWorkflow": "Start new Workflow",
    "Documents.Workflow.ViewActiveWorkflow": "View Active Workflow",
    "Documents.New.OnlyPublishedRevsAsTemplates":
      "Only published revisions can be used as templates",
    "Documents.New.CreateNew": "CREATE NEW",
    "Documents.New.SelectDestination": "Select a Destination",
    "Documents.New.UploadContent": "UPLOAD CONTENT",
    "Documents.New.UploadNewActiveFile": "UPLOAD A NEW ACTIVE FILE",
    "Documents.New.UploadNewDraft": "UPLOAD A NEW DRAFT",
    "Documents.New.UploadNewPublishedRevision":
      "UPLOAD A NEW PUBLISHED REVISION",
    "Documents.New.UploadingYourLinkedFiles": "Uploading your linked files...",
    "Documents.New.WhereToCreateNewDoc":
      "Where would you like to create this new document?",
    "Documents.OneTimePasswordModal.AVerificationCodeHasBeenSent":
      "A verification code has been sent to %(email)s. Enter the code below to confirm your identity. It may take a few minutes to receive your code.",
    "Documents.OneTimePasswordModal.NewCodeSent": "New Code Sent!",
    "Documents.OneTimePasswordModal.ResendCode": "Resend code to my email",
    "Documents.OneTimePasswordModal.VerificationCode": "Verification Code",
    "Documents.OneTimePasswordModal.VerificationRequired":
      "Verification Required",
    "Documents.OneTimePasswordModal.InvalidVerificationCode":
      "Invalid Verification Code. Please try again.",
    "Documents.OneTimePasswordModal.EmailMissing":
      "To confirm your identity, a verification code needs to be sent to your email. You currently do not have a verified email address on file. Please update your email address in your profile and try again.\r\n        ",
    "Documents.OneTimePasswordModal.EmailMissingLineTwo":
      "If you are unable to edit your profile, contact your administrator.",
    "Documents.OneTimePasswordModal.UnverifiedEmailWarning":
      "Please verify your email address in your profile to confirm your identity. Unverified email addresses might not be delivered to successfully.",
    "Documents.OneTimePasswordModal.UpdateEmailAddress": "Update Email Address",
    "Documents.OverflowMenu.CompareOptionsAvailable":
      "Compare options are now available",
    "Documents.OverflowMenu.CompareToCurrentPublication":
      "Compare to Current Publication",
    "Documents.OverflowMenu.CompareToLastRevisionISigned":
      "Compare to Last Revision I Signed",
    "Documents.OverflowMenu.CopyLink": "Copy Link",
    "Documents.OverflowMenu.CreateDraftRevision": "Create Draft Revision",
    "Documents.OverflowMenu.OpenInNewWindow": "Open in New Window",
    "Documents.OverflowMenu.PublishDocument": "Publish this Revision",
    "Documents.OverflowMenu.SearchDocument": "Search this Document",
    "Documents.OverflowMenu.ViewOriginalFormat": "View Original Format",
    "Documents.OverflowMenu.ViewAsPdf": "View as PDF",
    "Documents.Tabs.Activity": "Activity",
    "Documents.Tabs.Assignments": "Assignments",
    "Documents.Tabs.General": "General",
    "Documents.Tabs.Review": "Review",
    "Documents.Tabs.Revisions": "Revisions",
    "Documents.Tabs.Security": "Security",
    "Documents.Tabs.Signatures": "Signatures",
    "Documents.Tabs.Standards": "Standards",
    "PowError.CommandError":
      "We're sorry, an internal server error has occurred.",
    "PowError.InsufficientPrivileges":
      "You don't have enough privileges to perform this operation.",
    "PowError.InsufficientPermissions":
      "Sorry, you don't have rights over this object.",
    "PowError.FolderDoesNotExist": "This folder does not exist.",
    "PowError.FolderNameInUse": "This folder name is already in use.",
    "PowError.FolderNameInvalid": "This folder name is invalid.",
    "PowError.FoundationGroupSyncError":
      '\r\n          The group membership was updated for Policy, but changes were not successfully syncrhonized with Foundation.\r\n          Please click "Save" to try again. If you continue to experience issues, please contact support.\r\n        ',
    "PowError.UserNotAssignedSubmission":
      "Sorry, you don't have rights over this object.",
    "PowError.Error500": "We're sorry, an internal server error has occurred.",
    "PowError.Error404": "404 - Object not found. It may have been removed.",
    "PowError.NoInternet":
      "We are unable to connect to PowerDMS. Are you connected to the internet?",
    "PowError.DefaultError": "We're sorry, an error has occurred.",
    "DownloadApp.Connected":
      "Connected to %(siteName)s through PowerDMS Content Hub.",
    "DownloadApp.GetTheApp": "Get the app for a better experience.",
    "DownloadApp.PendingInvite":
      "You have a pending invitation from %(siteName)s through PowerDMS Content Hub.",
    "DownloadApp.YouCanAcceptInvite":
      "You can accept your invitation from your email or under the Content Hub tab in your PowerDMS site.",
    "ErrorPage.SecurityException.SecurityTitle": "Insufficient Privileges",
    "ErrorPage.SecurityException.StandardsManualView":
      "You need “view” privileges for the standards manual that this asssessment is on in order to access this page.",
    "ErrorPage.SecurityException.InsufficientPrivileges":
      "The resource you requested requires a higher privilege set to access.",
    "ErrorPage.SecurityException.InsufficientPrivilegesOverObject":
      "Sorry, you don't have permissions over this object.",
    "ErrorPage.SecurityException.AdminPrivileges":
      "You need “administrator” privileges to access this content.",
    "ErrorPage.SecurityException.AssessmentAuditStandardsView":
      "You need both “audit” privileges to this assessment and “view” privileges to the associated standards manual to view this page.",
    "ErrorPage.SecurityException.AssessmentAudit":
      "You need “audit” privileges to view this assessment.",
    "ErrorPage.GeneralException.AssessmentRecurringTaskSaveErrorTitle":
      "Error Saving Recurring Assessment Task",
    "ErrorPage.GeneralException.AssessmentRecurringTaskSaveMessage":
      "A recurrence interval and end date are required in order to create or save a recurring assessment task.",
    "ErrorPage.GeneralException.NoConnectionTitle": "No Connection",
    "ErrorPage.GeneralException.NoConnectionMessage":
      "We are unable to connect to PowerDMS.",
    "ErrorPage.GeneralException.ProductNotPurchasedTitle":
      "You Would Love This",
    "ErrorPage.GeneralException.ProductNotPurchasedMessage":
      "Unfortunately, you haven't purchased this product yet. Please email %(emailAddressLink)s to activate this part of PowerDMS.",
    "ErrorPage.GeneralException.RequiredParametersNotFoundTitle":
      "Parameters Not Found",
    "ErrorPage.GeneralException.RequiredParametersNotFoundMessage":
      "The required parameters were not found.",
    "ErrorPage.GeneralException.ObjectDoesNotExistTitle": "Object Not Found",
    "ErrorPage.GeneralException.ObjectDoesNotExistMessage":
      "\r\n          The object you are attempting to view does not exist.\r\n          It could have been removed and is no longer available.\r\n        ",
    "ErrorPage.GeneralException.FileNotFoundTitle": "File Not Found",
    "ErrorPage.GeneralException.FileNotFoundMessage":
      "\r\n          The file you are attempting to view does not exist.\r\n          It could have been removed and is no longer available.\r\n        ",
    "ErrorPage.GeneralException.TimezoneNotFoundTitle": "Timezone Not Found",
    "ErrorPage.GeneralException.TimezoneNotFoundMessage":
      "The timezone selected for your site could not be found.",
    "ErrorPage.TrainingException.NoCertificateTypesTitle":
      "No Certificate Types Exist",
    "ErrorPage.TrainingException.NoCertificateTypesMessage":
      "You currently do not have any certificate types configured for your site.",
    "ErrorPage.TrainingException.NoCourseTypesTitle": "No Course Types Exist",
    "ErrorPage.TrainingException.NoCourseTypesMessage":
      "You currently do not have any course types configured for your site.",
    "ErrorPage.WorkflowException.CannotBindUserToDefaultTitle":
      "No Matching Workflow Configuration",
    "ErrorPage.WorkflowException.CannotBindUserToDefaultMessage":
      "There is a workflow associated with your request. Unfortunately, you are not in the correct position of the workflow to accomplish your request.",
    "ErrorPage.QAException.BankResultTimeExpiredTitle": "Time Is Up",
    "ErrorPage.QAException.BankResultTimeExpiredMessage":
      "You have exceeded the set time limit.",
    "ErrorPage.QAException.BankResultNotStartedTitle": "Test Not Started",
    "ErrorPage.QAException.BankResultNotStartedMessage":
      "The specified test has not started yet.",
    "ErrorPage.QAException.BankResultNotFinishedTitle": "Test Not Completed",
    "ErrorPage.QAException.BankResultNotFinishedMessage":
      "This test has not been completed yet.",
    "ErrorPage.HttpException.PageNotFoundTitle": "Invalid Page",
    "ErrorPage.HttpException.PageNotFoundMessage":
      "The page you are trying to access does not exist.",
    "ErrorPage.HttpException.FileSizeExceededTitle": "File Too Large",
    "ErrorPage.HttpException.FileSizeExceededMessage":
      "\r\n          The file you are trying to upload is too large. Files must be less than 100MB in size.\r\n        ",
    "ErrorPage.ServerException.ServerTimeoutTitle": "Server Connection Timeout",
    "ErrorPage.ServerException.ServerTimeoutMessage":
      "The server has taken too long to respond. Please try again.",
    "ErrorPage.DefaultException.Title": "An Unknown Error Has Occurred",
    "ErrorPage.DefaultException.ContactSupport":
      "\r\n          Please contact support by calling %(phoneNumber)s or via e-mail at %(emailAddress)s.\r\n        ",
    "ErrorPage.ContactAdmin":
      "Please contact %(anAdministrator)s for assistance.",
    "ErrorPage.CreateCertificateTypes":
      "Please contact %(anAdministrator)s to create certificate types.",
    "ErrorPage.CreateCourseTypes":
      "Please contact %(anAdministrator)s to create course types.",
    "ErrorPage.RequestPrivileges":
      "Please contact %(anAdministrator)s to request the appropriate privileges.",
    "ErrorPage.TryAgain":
      "Please try again or contact %(anAdministrator)s for assistance.",
    "ErrorPage.Common.ContactSupport":
      "\r\n          Need more help? Contact support by calling %(phoneNumber)s or via e-mail at %(emailAddress)s.\r\n        ",
    "ErrorPage.Common.AnAdministrator": "an administrator",
    "ErrorPage.Common.Title": "Error Page",
    "ErrorPage.Common.YourAdministrator":
      "your administrator, %(adminGivenName)s %(adminFamilyName)s at %(adminEmailAddress)s,",
    "ErrorPage.Common.AdminTooltip":
      "\r\n          %(adminGivenName)s %(adminFamilyName)s: (%(adminEmailAddress)s)\r\n        ",
    "Folders.AbleToMoveSingular": "1 item able to move",
    "Folders.AbleToMovePlural": "%(numItems)s items able to move",
    "Folders.AndXMore": "and %(linkStart)s%(numItems)s more%(linkEnd)s",
    "Folders.ConfirmDelete": "Confirm Delete",
    "Folders.ConfirmDeleteHasChildren":
      "The documents and folders currently inside of it will not be deleted\r\n          but instead will be moved to the folder specified.",
    "Folders.ConfirmDeleteMessage":
      "You are about to delete the folder %(folderName)s.",
    "Folders.Content": "Content",
    "Folders.DeleteAndMoveContents": "Delete and Move Contents",
    "Folders.DeleteThisFolder": "Delete this folder",
    "Folders.FolderNameLabel": "Folder Name:",
    "Folders.FolderDoesntExistError":
      "Error: No folder exists with this ID. It may have been deleted.",
    "Folders.ManageFolder": "Manage Folder",
    "Folders.MoveRenameWarning":
      "Please %(boldStart)sEITHER%(boldEnd)s rename the following folders\r\n          %(boldStart)sOR%(boldEnd)s choose a different folder to move this content.",
    "Folders.NoCancelDelete": "No, Cancel Delete",
    "Folders.NoDontDelete": "No, don't delete this folder",
    "Folders.PrivilegeLossWarning":
      "By moving the contents to the selected folder, you may lose some security rights over some of them.\r\n          Do you want to continue?",
    "Folders.SameNameError":
      'Error: A folder with the name "%(folderName)s" already exists. Please use a different name.',
    "Folders.SameNameMoveError":
      "%(boldStart)sError:%(boldEnd)s One or more folders with the same name already exist\r\n          within the folder %(folderName)s.",
    "Folders.UnableToMoveSingular": "1 item unable to move",
    "Folders.UnableToMovePlural": "%(numItems)s items unable to move",
    "Folders.YesContinueDelete": "Yes, Continue with the Delete",
    "Folders.YesDeleteLabel":
      "Yes, delete the folder and move its contents to:",
    "ErrorPhrases.Hmmm": "Hmmm...",
    "ErrorPhrases.PleaseContactAdmin":
      "Please contact your system administrator.",
    "ErrorPhrases.TryRefreshing":
      "Try %(refreshLinkStart)s refreshing %(refreshLinkEnd)s and see if that helps. If the problem persists, please contact your system administrator.",
    "Errors.CertificateLabelsTooLong": "Attempted to save labels and failed.",
    "Errors.ContentHubUserAlreadyLinked":
      "The entered credentials are already in use. To connect to this account, you must first disconnect the currently subscribed user.",
    "Errors.CreateDocumentPrivileges":
      "User does not have privileges to create documents.",
    "Errors.CreateDocumentTypePrivileges":
      "You do not have the required permissions to create new document types.",
    "Errors.DefaultError": "Error Code: %(status)s %(statusText)s",
    "Errors.DocumentDescriptionTooLong": "Document description is too long.",
    "Errors.DocumentNameExists":
      'A document with the name "%(documentName)s" already exists.',
    "Errors.DocumentNameExistsInFolder":
      'A document with the name "%(documentName)s" already exists in this folder.',
    "Errors.DocumentNameTooLong": "The name of this document is too long.",
    "Errors.DocumentNotFound": "Document not found.",
    "Errors.DocumentRevisionIsLocked":
      "This file is locked because it is currently being edited. ",
    "Errors.DraftAlreadyExists":
      "This document already has an unpublished draft.",
    "Errors.FileIsRequired":
      "A file is required in order to create a document.",
    "Errors.FileNoExtension": "This file type is not supported.",
    "Errors.FileSizeUnsupported":
      "Files larger than %(maxFileSizeMb)sMB cannot be uploaded.",
    "Errors.IdentityProviderAccountAlreadyExists":
      "Your site is already connected to %(siteKey)s.",
    "Errors.IntegrityMismatch": "File integrity mismatch.",
    "Errors.InsufficientEditPermissionsOverDocument":
      "You do not have the required permissions to edit this document.",
    "Errors.InsufficientPermissionsOverSiteFileUpload":
      "You do not have the required permissions to upload this file.",
    "Errors.InvalidDocumentCreationSource": "The document source is invalid.",
    "Errors.MultipleFilesUnsupported":
      "Cannot upload multiple files to a document.",
    "Errors.MultipleHtmlFilesFound":
      "You may only upload one HTML file at a time.",
    "Errors.NoAccessTokenFound":
      "You do not have a %(provider)s account that is linked to this file.",
    "Errors.NoContentHubPublisherLicense":
      "%(siteKey)s is not an authorized Content Hub Publisher.",
    "Errors.NoInternet":
      "We are unable to connect to PowerDMS. Are you connected to the internet?",
    "Errors.NoInternetConnection": "Error: No internet connection",
    "Errors.NoInternetTryAgain":
      "We're sorry, an error has occurred. Please check your internet connection and try again.",
    "Errors.NoNameSpecified":
      "The file name appears to be empty. Please be sure that the file name has at least one letter or number.",
    "Errors.NoResponse": "No Response",
    "Errors.PublicationDoesNotExist": "The published revision does not exist.",
    "Errors.RefreshPage": "Please refresh the page and try again.",
    "Errors.RevisionNotFound": "Revision not found.",
    "Errors.SiteAuthenticationFailed":
      "Unable to connect: Please ensure your Content Hub site key, username, and password are correct. If you continue to receive this message, contact your Content Hub Publisher for assistance.",
    "Errors.SourceDocumentIdUnspecified":
      "The source document ID is unspecified.",
    "Errors.TagAlreadyExists":
      "The following tags already exist: %(duplicateTags)s",
    "Errors.UnableToCalculateFileHash":
      "Unable to verify that the file uploaded successfully. Please try again.",
    "Errors.UnifiedPasswordResetFailed":
      "Unable to reset password. If you continue to see this error, please contact an administrator and verify this account is active in Foundation.",
    "Errors.UnknownUnsupportedFileType": "File type is not supported.",
    "Errors.UnsupportedFileType":
      "File type '%(fileExtension)s' is not supported.",
    "Errors.SubscriberUserIsAlreadyConnectedToPublisherSite":
      "Connection already exists.",
    "Errors.UnknownError": "We're sorry, an unknown error has occurred.",
    "Groups.ErrorLoadingGroupsParents": "Error loading group's parents",
    "Groups.GroupIsMemberOf": "%(groupName)s is a member of:",
    "Groups.MemberOf": "Member of",
    "Groups.NumberOfGroups": "%(numberOfGroups)s groups",
    "Groups.NewGroup": "New Group",
    "Home.EditHomepageMessage": "Edit Homepage Message",
    "Home.ImportantMessage": "Important Message",
    "Home.NewFeaturesAvailable": "New Features Available",
    "Home.OldSiteKeyMessage.Singular":
      'Your administrator has changed your Site Key to "%(newSiteKey)s".\r\n          You have 1 day before your current site key expires. Please update all links and bookmarks.',
    "Home.OldSiteKeyMessage.Plural":
      'Your administrator has changed your Site Key to "%(newSiteKey)s".\r\n          You have %(daysLeft)s days before your current site key expires. Please update all links and bookmarks.',
    "Home.SiteLogoAltText": "Site Logo",
    "Home.Welcome": "Welcome",
    "Home.ContactSalesRep.HaveQuestions": "Have Questions?",
    "Home.ContactSalesRep.IfYouWouldLikeToContinue":
      "If you would like to continue using the full features of PowerDMS, just give us a call!",
    "Home.ContactSalesRep.TrialHasEnded": "Your PowerDMS trial has ended.",
    "Home.ContactSalesRep.TrialHasExpired": "Your trial has Expired",
    "Home.ContactSalesRep.WeAreHappyToHelp":
      "We are always happy to help, just give us a call!",
    "Home.Dashboard.Dashboard": "Dashboard",
    "Home.Dashboard.HelpText":
      "As an administrative user, your dashboard will provide a clear snapshot of how your organization is doing with managing critical content,\r\n          what users or groups may need your attention and what may be holding up your progress to maintain compliance.",
    "Home.Dashboard.MaximumOfResultsMayBeViewed":
      "A maximum of %(maxNumberOfItemsShown)s results may be viewed here.\r\n          Try running a report to view more.",
    "Home.Dashboard.RecordsMayBeHidden":
      "* Some records may be hidden due to security restrictions",
    "Home.Dashboard.CommonTerms.DueOn": "Due %(dueOnDate)s",
    "Home.Dashboard.CommonTerms.FilterBy": "Filter By",
    "Home.Dashboard.CommonTerms.InboxSummaryReports": "Inbox Summary Reports",
    "Home.Dashboard.CommonTerms.UsersAndGroupsReports":
      "Users And Groups Reports",
    "Home.Dashboard.CommonTerms.NumberOfToDosPlural": "%(totalTasks)s To Dos",
    "Home.Dashboard.CommonTerms.NumberOfToDosSingular": "1 To Do",
    "Home.Dashboard.CommonTerms.NumberOverdue": "(%(overdue)s Overdue)",
    "Home.Dashboard.CommonTerms.NumberDueSoon": "(%(dueSoon)s Due Soon)",
    "Home.Dashboard.CommonTerms.NumberOverdueAndDueSoon":
      "(%(overdue)s Overdue, %(dueSoon)s Due Soon)",
    "Home.Dashboard.CommonTerms.OnTopOfToDoList":
      "Looks like everyone is on top of their To Do list.",
    "Home.Dashboard.CommonTerms.OrderBy": "Order By",
    "Home.Dashboard.CommonTerms.RunReport": "Run Report",
    "Home.Dashboard.CommonTerms.TryBeta": "Try Beta",
    "Home.Dashboard.CommonTerms.SortByDueDate": "Due Date",
    "Home.Dashboard.Assessments.AssessmentReports": "Assessment Reports",
    "Home.Dashboard.Assessments.CurrentTasksPlural":
      "%(totalTasks)s Current Tasks",
    "Home.Dashboard.Assessments.CurrentTasksSingular": "1 Current Task",
    "Home.Dashboard.Assessments.LastWorkingOn":
      "%(spanOpening)sLast working on%(spanClosing)s %(subjectNameLink)s,",
    "Home.Dashboard.Assessments.NoAssessments":
      "Looks like there are no assessments running at this time.",
    "Home.Dashboard.Assessments.PercentEvaluated":
      "%(percentageEvaluated)s%(percentSign)s Evaluated",
    "Home.Dashboard.Assessments.TasksNotSetPlural":
      "And %(tasksNotSet)s have not been set",
    "Home.Dashboard.Assessments.TasksNotSetSingular": "And 1 has not been set",
    "Home.Dashboard.Assessments.UnassignedTasksPlural":
      "%(unassignedTasks)s have not been assigned",
    "Home.Dashboard.Assessments.UnassignedTasksSingular":
      "1 has not been assigned",
    "Home.Dashboard.Documents.DocumentReports": "Document Reports",
    "Home.Dashboard.Documents.MostRecentlyPublished": "Most Recently Published",
    "Home.Dashboard.Documents.MostOutstandingSignatures":
      "Most Outstanding Signatures",
    "Home.Dashboard.Documents.NoOutstandingDocuments":
      "There are no outstanding documents that require your attention.\r\n          Please keep in mind that you may not see some records due to security restrictions and audit rights.",
    "Home.Dashboard.Documents.NumberOfSignatures":
      "%(signedDocuments)s / %(totalDocuments)s Signatures",
    "Home.Dashboard.Documents.PublishedOn": "Published %(publishedOn)s",
    "Home.Dashboard.Documents.SignaturesCollectedPlural":
      "%(signedCount)s Signatures collected",
    "Home.Dashboard.Documents.SignaturesCollectedSingular":
      "1 Signature collected",
    "Home.Dashboard.Documents.SignaturesNeededPlural":
      "%(unsignedCount)s Signatures still needed",
    "Home.Dashboard.Documents.SignaturesNeededSingular":
      "1 Signature still needed",
    "Home.Dashboard.Groups.NoOutstandingItems":
      "There are no outstanding items for any members of %(parentGroupName)s",
    "Home.Dashboard.Groups.NumberOfUsersPlural": "%(membersCount)s Users",
    "Home.Dashboard.Groups.NumberOfUsersSingular": "1 User",
    "Home.Dashboard.Groups.NumberOfUsersZero": "No Users",
    "Home.Dashboard.Groups.ThisGroupHas": "This Group has:",
    "Home.Dashboard.Groups.ThisUserHas": "This User has:",
    "Home.Dashboard.Training.AwardsLabel": "Awards:",
    "Home.Dashboard.Training.CertificateReports": "Certificate Reports",
    "Home.Dashboard.Training.CourseReports": "Course Reports",
    "Home.Dashboard.Training.CreditHours": "%(creditHours)s Credit Hours",
    "Home.Dashboard.Training.DueSoon": "(%(dueSoon)s Due Soon)",
    "Home.Dashboard.Training.NoOutstandingTrainingItems":
      "There are no outstanding training items.",
    "Home.Dashboard.Training.SortByMostIncomplete": "Most Incomplete",
    "Home.Dashboard.Training.SortByMostRecentlyCompleted":
      "Most Recently Completed",
    "Home.Dashboard.Training.TasksAwarded":
      "%(totalCompleted)s / %(totalTasks)s Awarded",
    "Home.Dashboard.Training.TasksCompleted":
      "%(totalCompleted)s / %(totalTasks)s Completed",
    "Home.Dashboard.Training.TestReports": "Test Reports",
    "Home.Dashboard.Workflows.ApprovalLabel": "Approval:",
    "Home.Dashboard.Workflows.CourseUserNameFormat":
      "%(courseName)s (%(userName)s)",
    "Home.Dashboard.Workflows.GoToWorkflow": "Go To Workflow",
    "Home.Dashboard.Workflows.NoWorkflowsInProgress":
      "Looks like you don't have any workflows in progress.",
    "Home.Dashboard.Workflows.NumberOfComments": "%(commentCount)s comments",
    "Home.Dashboard.Workflows.ReviewLabel": "Review:",
    "Home.Dashboard.Workflows.ReviewExceptionLabel": "Review Exception:",
    "Home.Dashboard.Workflows.SortByDurationOfCurrentStep":
      "Duration of Current Step",
    "Home.Dashboard.Workflows.SortByMostRecentlyStarted":
      "Most Recently Started",
    "Home.Dashboard.Workflows.StepNumberOfTotal":
      "Step %(stepNumber)s of %(stepTotal)s",
    "Home.Dashboard.Workflows.StepCompletedByUserOnDate":
      "Previous step completed by %(user)s on %(date)s.",
    "Home.Dashboard.Workflows.StepCompletedByUserAndOneOtherOnDate":
      "Previous step completed by %(linkOpening)s%(user)s and 1 other%(linkClosing)s on %(date)s",
    "Home.Dashboard.Workflows.StepCompletedByUserAndManyOthersOnDate":
      "Previous step completed by %(linkOpening)s%(user)s and %(participatingUsers)s others%(linkClosing)s on %(date)s",
    "Home.Dashboard.Workflows.ViewAllWorkflows": "View All Workflows",
    "Home.Dashboard.Workflows.WaitingOnStep":
      "Waiting on step %(stepNumber)s for %(numberOfDays)s",
    "Home.Dashboard.Workflows.WaitingOnUserForTimePassed":
      "Waiting on %(user)s for %(timePassed)s.",
    "Home.Dashboard.Workflows.WaitingOnUserAndOneOtherForTimePassed":
      "Waiting on %(user)s and 1 other for %(timePassed)s.",
    "Home.Dashboard.Workflows.WaitingOnUserAndManyOthersForTimePassed":
      "Waiting on %(user)s and %(nonParticipatingUsers)s others for %(timePassed)s.",
    "Home.Dashboard.Workflows.WorkflowInitiatedBy":
      "Workflow initiated by %(user)s on %(date)s.",
    "Home.Error.HmmmEllipsis": "Hmmm...",
    "Home.Error.SomethingIsNotQuiteRight":
      "It appears something is not quite right.",
    "Home.Error.TryRefreshing":
      "Try %(linkOpening)srefreshing%(linkClosing)s and see if that helps.\r\n          If the problem persists, please contact your system administrator.",
    "Home.QuickStart.Header": "Quick Start",
    "Home.QuickStart.HaveQuestions": "Have Questions?",
    "Home.ToDo.AndNumberOtherTasks": "and %(tasksNumber)s other %(tasksType)s",
    "Home.ToDo.AndNumberOtherTasksLink":
      "%(linkOpening)sand %(tasksNumber)s other %(tasksType)s%(linkClosing)s",
    "Home.ToDo.ClickHereToSeeDetails": "Click here to see details",
    "Home.ToDo.CloseToMaxPurchasedLicenses":
      "You are close to or at your maximum number of purchased licenses.",
    "Home.ToDo.CompletedToday": "Completed Today",
    "Home.ToDo.GetStarted": "Get started.",
    "Home.ToDo.NoThanks": "No thanks.",
    "Home.ToDo.NumberTasksAsRoleInAssessmentSingular":
      "1 task as %(role)s in %(assessment)s",
    "Home.ToDo.NumberTasksAsRoleInAssessmentPlural":
      "%(tasksNumber)s tasks as %(role)s in %(assessment)s",
    "Home.ToDo.NumberTasksLinkAsRoleInAssessmentSingular":
      "%(linkOpening)s1 task%(linkClosing)s as %(role)s in %(assessment)s",
    "Home.ToDo.NumberTasksLinkAsRoleInAssessmentPlural":
      "%(linkOpening)s%(tasksNumber)s tasks%(linkClosing)s as %(role)s in %(assessment)s",
    "Home.ToDo.ToDo": "To Do",
    "Home.ToDo.YourToDoListIsPrioritized":
      "Your To Do list is prioritized by due date and will help outline exactly what you need to accomplish in PowerDMS.",
    "Home.ToDo.YouveCompletedEverything": "You've completed everything!",
    "Home.ToDo.RecallCards": "Recall Cards",
    "Home.ToDo.AvailableCardsInfo":
      "You have completed the required cards for today. There are additional cards available.",
    "Home.ToDo.CardsTakenToday": "cards taken today",
    "Home.ToDo.CardsCompletedThisWeek": "cards completed this week",
    "Home.ToDo.RecallDeckIconAltText": "Recall task deck icon",
    "Home.ToDo.ReviewRecallCards": "Review Recall Cards",
    "Home.TreesSaved.AverageSiteNumber": "Average Site: %(treesSaved)s",
    "Home.TreesSaved.CostPerReamPaper": "Cost per ream paper",
    "Home.TreesSaved.CostToPrintEachPage": "Cost to print each page",
    "Home.TreesSaved.JustGettingStarted": "Just getting started",
    "Home.TreesSaved.LearnMore": "Learn More",
    "Home.TreesSaved.PagesSaved": "Pages saved",
    "Home.TreesSaved.PagesSavedIsEstimatedBy":
      "Pages saved is estimated by taking the total number of document signatures, standards attachments,\r\n          test results and survey results and multiplying each by the average number of pages it would take to print each.",
    "Home.TreesSaved.PowerDMSSavingsCalculator": "PowerDMS Savings Calculator",
    "Home.TreesSaved.TheDefaultCostPerPage":
      "The default Cost Per Page (CPP) is 2.5¢US,\r\n          which is estimated by taking the average CPP of Xerox® office printers using 5% Total Black Ink coverage.",
    "Home.TreesSaved.TheDefaultCostPerReamOfPaper":
      "The default cost per ream of paper is estimated at US$2.50.\r\n          This number may be adjusted to reflect your company's personal spending amount.",
    "Home.TreesSaved.TogetherPowerDMSUsersHaveSaved":
      "Together, PowerDMS Users have saved %(strongOpening)s%(treesSaved)s trees%(strongClosing)s.",
    "Home.TreesSaved.TotalDollarsSaved": "Total dollars saved",
    "Home.TreesSaved.TreeImgAlt": "A tree",
    "Home.TreesSaved.TreesSaved": "Trees Saved",
    "Home.TreesSaved.TreeSproutImgAlt": "A tree sprout",
    "Home.TreesSaved.TryRefreshing":
      "Try refreshing and see if that helps.\r\n          If the problem persists, please contact your system administrator.",
    "Home.TreesSaved.UnableToSaveChanges":
      "We were unable to save your changes.",
    "Home.TreesSaved.YouHaveSavedNumberTrees":
      "You have saved %(spanOpening)s%(treesSaved)s trees %(spanClosing)s by using PowerDMS",
    "Home.TreesSaved.YourSiteNumber": "Your Site: %(treesSaved)s",
    "IdsValidatableButton.SendValidationEmailTooltip":
      "Please correct the invalid fields (indicated with a red *) before saving.",
    "Inbox.CertificateHistory": "Certificate History",
    "Inbox.Certificates": "Inbox - Certificates",
    "Inbox.CourseHistory": "Course History",
    "Inbox.Courses": "Inbox - Courses",
    "Inbox.DiscussionHistory": "Discussion History",
    "Inbox.DocumentHistory": "Document History",
    "Inbox.Documents": "Inbox - Documents",
    "Inbox.Manuals": "Inbox - Standards",
    "Inbox.ManualsHistory": "Standards History",
    "Inbox.MessageDrafts": "Message Drafts",
    "Inbox.MessagesReceived": "Messages Received",
    "Inbox.MessagesSent": "Messages Sent",
    "Inbox.SurveyHistory": "Survey History",
    "Inbox.Surveys": "Inbox - Surveys",
    "Inbox.TestHistory": "Test History",
    "Inbox.Tests": "Inbox - Tests",
    "Inbox.Recall": "Inbox - Recall",
    "Languages.SiteDefault": "Use Site's Language (%(language)s)",
    "LegacyContentToolbar.GeneratedBy":
      "This report has been generated using %(platform)s",
    "LegacyContentToolbar.InstructionsFor":
      "Instructions for %(instructionType)s: %(instruction)s",
    "LegacyContentToolbar.NoRecords": "There are no records to print",
    "LegacyContentToolbar.Origin":
      "These instructions have been printed from within %(platform)s",
    "LegacyContentToolbar.PrintedBy": "Printed by %(name)s",
    "LegacyWorkflow.Abbreviations.Group": "G:",
    "LegacyWorkflow.Abbreviations.JobTitle": "JT:",
    "LegacyWorkflow.Abbreviations.Location": "L:",
    "LegacyWorkflow.Abbreviations.User": "U:",
    "LegacyWorkflow.AddPosition": "Add Position",
    "LegacyWorkflow.ClickToCancel": "Click to Cancel changes",
    "LegacyWorkflow.ModifyPositionConfirmation":
      "This position has been modified, would you like to save changes or cancel changes and continue?",
    "LegacyWorkflow.NewStep": "New Step",
    "LegacyWorkflow.Options.AllResponse": "All Response",
    "LegacyWorkflow.Options.FirstResponse": "First Response",
    "LegacyWorkflow.Options.Flex": "Dynamic First Response",
    "LegacyWorkflow.Options.SimpleVote": "Simple Vote",
    "LegacyWorkflow.PleaseAssignGeneric":
      "Please assign this position to Any User, a specific User or a Group before saving.",
    "LegacyWorkflow.PleaseAssignMultiple":
      "Please assign this position a Group, Job Title, or Location before saving.",
    "LegacyWorkflow.PleaseAssignUser":
      "Please assign this position a User before saving.",
    "LegacyWorkflow.PositionExists":
      "There is already a position that accepts anyone. You must reassign that position first.",
    "LegacyWorkflow.RemoveNodeConfirm":
      "Are you sure you want to remove this node?",
    "LegacyWorkflow.SaveSuccess": "Save was successful.",
    "LegacyWorkflow.SaveFailure": "There was an error saving: %(error)s",
    "LegacyWorkflow.StartPositionExists":
      "There is already a start position. You must reassign that position first.",
    "Licensing.Message.CloseToMax":
      "Your site is close to its maximum number of purchased licenses.",
    "Licensing.Message.CloseToOrAtMax":
      "Your site is close to or at its maximum number of purchased licenses.",
    "Licensing.Message.CloseToRecommendationUsers":
      "Consider archiving inactive users or purchasing additional licenses to avoid overages.",
    "Licensing.Message.CloseToRecommendationAssessments":
      "Consider archiving inactive assessments or purchasing additional licenses to avoid overages.",
    "Licensing.Message.ExceedingMax":
      "Your site is exceeding its maximum number of purchased licenses.",
    "Licensing.Message.ExceedingMaxContinue":
      "You may continue with this action, but your site is currently at or exceeding its maximum number of purchased licenses.",
    "Licensing.Message.ExceedingRecommendationUsers":
      "Please archive inactive users or purchase additional licenses to avoid overages.",
    "Licensing.Message.ExceedingRecommendationAssessments":
      "Please archive inactive assessments or purchase additional licenses to avoid overages.",
    "Licensing.Message.LicensingLink":
      "%(linkOpening)sView current license usage.%(linkClosing)s",
    "LiveSearch.Clear": "Clear",
    "LiveSearch.Close": "Close",
    "LiveSearch.Create": 'Create "%(label)s"',
    "LiveSearch.Edit": "Edit %(label)s",
    "LiveSearch.Open": "Open",
    "LiveSearch.Remove": "Remove %(label)s",
    "LoadingPage.NewSection": "New Section",
    "LoadingPage.ObjectType": "Loading %(boldedObjectName)s",
    "LoadingPage.PageTitle": "Loading %(boldedPageTitle)s Page",
    "LoadingPage.User":
      "Loading Profile of %(boldHTMLTag)s%(surname)s, %(givenName)s%(endBoldHTMLTag)s",
    "Login.EmailValidation": "Email Validation",
    "Login.SiteKeyInfo":
      "Site Key is a unique identifier for your organization's PowerDMS site. If you're unsure of your Site Key, you can contact your PowerDMS administrator for assistance.",
    "Login.SiteKeyInfoTitle": "Learn more about site keys",
    "Manuals.NewManualTitle": "New Standards Manual",
    "Manuals.ManualTitle": "Standards Manual",
    "Manuals.Tabs.Assessments": "Assessments",
    "Manuals.Tabs.AssessmentsRoles": "Roles",
    "Manuals.Tabs.AssessmentsRoleKeys": "Role Keys",
    "Manuals.Tabs.AssessmentsSearch": "Assessment Search",
    "Manuals.Tabs.AssessmentsStatuses": "Statuses",
    "Manuals.Tabs.General": "General",
    "Manuals.Tabs.Revisions": "Revisions",
    "Manuals.Tabs.Security": "Security",
    "Manuals.Tabs.Style": "Style",
    "Manuals.Tabs.StyleStandard": "Standard",
    "Manuals.Tabs.StyleInformation": "Information",
    "Manuals.ViewManual": "View Manual",
    "Modals.ContentHub.AuthenicateAnotherAccount":
      "Login with your Content Hub credentials to import content from a Publisher to your PowerDMS site.",
    "Modals.ContentHub.ConnectAccountHeader": "Connect to Publisher",
    "Modals.ContentHub.Connecting": "Connecting...",
    "Modals.ContentHub.Disconnecting": "Disconnecting...",
    "Modals.ContentHub.DisconnectAccountHeader":
      "Disconnect from Content Publisher",
    "Modals.ContentHub.DisconnectAccountText":
      "By disconnecting from %(publisherName)s,\r\n           you will no longer receive updates to imported documents or have access to their content.\r\n            Are you sure you want to disconnect?\r\n        ",
    "Modals.ContentHub.DisconnectContentSource": "Disconnect Content Source",
    "Modals.ContentHub.DisconnectFromContentHub": "Disconnect from Content Hub",
    "Modals.ContentHub.DisconnectFromContentHubDescription":
      "You are about to disconnect %(docName)s from the Content Hub,\r\n        removing the connection between this document and its content source.\r\n        Once disconnected, you will no longer be notified of future updates on the source document.",
    "Modals.ContentHub.DisconnectingDocument": "Disconnecting Document...",
    "Modals.ContentHub.ForgotPassword": "Forgot Password?",
    "Modals.ContentHub.Import.ChooseDifferentDestinationFolder":
      "You must choose a different destination folder to proceed. ",
    "Modals.ContentHub.Import.FolderLabel": "Folder:",
    "Modals.ContentHub.Import.ImportDocumentAsDraft":
      "A Draft Revision on this Document",
    "Modals.ContentHub.Import.ImportDocumentAsPublished":
      "A Published Revision on this Document",
    "Modals.ContentHub.Import.ImportDocumentToFolderAsDraft":
      "New Draft Document",
    "Modals.ContentHub.Import.ImportDocumentToFolderAsPublished":
      "New Published Document",
    "Modals.ContentHub.Import.ImportDocumentsAsDraft": "New Draft Document(s)",
    "Modals.ContentHub.Import.ImportDocumentsAsPublished":
      "New Published Document(s)",
    "Modals.ContentHub.Import.ImportAsDraftToDoc":
      "Import as a draft to an existing document",
    "Modals.ContentHub.Import.ImportAsNewDocument": "Import as a New Document",
    "Modals.ContentHub.Import.ImportEverything": "Import everything",
    "Modals.ContentHub.Import.ImportUnimported": "Import unimported items only",
    "Modals.ContentHub.Import.NotifyContentUpdates":
      "Notify me when content updates are available",
    "Modals.ContentHub.Import.OptionsLabel": "Options:",
    "Modals.ContentHub.Import.PreviouslyImported.Singular":
      "%(boldOpen)s%(importedCount)s of %(total)s item%(boldClose)s in this folder has already been imported into your file cabinet. You may skip previously imported items or import all items in this folder. Where would you like to import this folder?",
    "Modals.ContentHub.Import.PreviouslyImported.SingularPlural":
      "%(boldOpen)s%(importedCount)s of %(total)s items%(boldClose)s in this folder has already been imported into your file cabinet. You may skip previously imported items or import all items in this folder. Where would you like to import this folder?",
    "Modals.ContentHub.Import.PreviouslyImported.Plural":
      "%(boldOpen)s%(importedCount)s of %(total)s items%(boldClose)s in this folder have already been imported into your file cabinet. You may skip previously imported items or import all items in this folder. Where would you like to import this folder?",
    "Modals.ContentHub.Import.PreviouslyImportedIntoDestination.Singular":
      "%(boldOpen)s%(importedCount)s of %(total)s item%(boldClose)s in this folder has already been imported into the destination %(boldOpen)s%(destination)s%(boldClose)s in your file cabinet. You may skip previously imported items or import all items in this folder. Where would you like to import this folder?",
    "Modals.ContentHub.Import.PreviouslyImportedIntoDestination.SingularPlural":
      "%(boldOpen)s%(importedCount)s of %(total)s items%(boldClose)s in this folder has already been imported into the destination %(boldOpen)s%(destination)s%(boldClose)s in your file cabinet. You may skip previously imported items or import all items in this folder. Where would you like to import this folder?",
    "Modals.ContentHub.Import.PreviouslyImportedIntoDestination.Plural":
      "%(boldOpen)s%(importedCount)s of %(total)s items%(boldClose)s in this folder have already been imported into the destination %(boldOpen)s%(destination)s%(boldClose)s in your file cabinet. You may skip previously imported items or import all items in this folder. Where would you like to import this folder?",
    "Modals.ContentHub.Import.SelectDestination": "Select a Destination",
    "Modals.ContentHub.Password": "Publisher Password",
    "Modals.ContentHub.SiteKey": "Publisher Site Key",
    "Modals.ContentHub.Username": "Publisher Username",
    "Modals.ContentHub.Import.skipPreviouslyImported":
      "Skip items previously imported anywhere in my file cabinet",
    "Modals.ContentHub.Import.Title": "Import Content",
    "Modals.ContentHub.Import.HiddenImportedFoldersNote":
      "%(boldOpen)sNote:%(boldClose)s Items in this folder have already been imported. By proceeding, you will create duplicate items in your file cabinet. You may not be able to view previously imported items due to security restrictions.",
    "Modals.ContentHub.Import.WarningInsufficientDestinationPermission":
      "%(boldOpen)sWarning:%(boldClose)s This folder has already been imported. However, you do not have edit rights to the previous destination. If you wish to continue importing this folder, you must first select a new destination.",
    "Modals.ContentHub.Import.WarningNewDraftFromPublishedRevision":
      "%(boldOpen)sWarning:%(boldClose)s  A new revision will be created from this published revision. Changing the status of any revision will automatically close any open workflows.",
    "Modals.ContentHub.Import.WarningDisconnectLinkedDocument":
      "%(boldOpen)sWarning:%(boldClose)s This document is currently linked to a file imported from %(providerName)s. Creating a new revision will unlink this connection. Changing the status of any revision will automatically close any open workflows.",
    "Modals.ContentHub.Import.WhereToImport":
      "You can select a Folder or Document as the destination for this imported file.",
    "Modals.ContentHub.Import.WhereToImportFolder":
      "You can select a Folder as the destination for this imported folder.",
    "Modals.ContentHub.Import.DuplicateFileWarning":
      "You are about to import %(boldOpen)s %(total)s item%(boldClose)s in folder %(boldOpen)s%(folder)s%(boldClose)s into the selected destination. This will create a duplicate item in your file cabinet.",
    "Modals.ContentHub.Import.DuplicateFilesWarning":
      "You are about to import %(boldOpen)s all %(total)s items%(boldClose)s in folder %(boldOpen)s%(folder)s%(boldClose)s into the selected destination. This will create duplicate items in your file cabinet.",
    "Modals.ContentHub.Import.StopBulkImport.BodyHeader": "Stop folder import?",
    "Modals.ContentHub.Import.StopBulkImport.ContinueImport": "Continue Import",
    "Modals.ContentHub.Import.StopBulkImport.StopImport": "Stop Import",
    "Modals.ContentHub.Import.StopBulkImport.StopMessage":
      "Your folder import of %(boldOpen)s%(importingFolderName)s%(boldClose)s is not complete. Would you like to stop this import?",
    "Modals.ContentHub.Import.StopBulkImport.Title": "Import in progress",
    "Modals.ContentHub.Import.StopBulkImport.UnableToStopCompletedImport":
      "We are unable to stop this folder import as it has already been successfully imported.",
    "Modals.ContentHub.Import.StopBulkImport.UnableToStopFailedImport":
      "We are unable to stop this action as the import has failed to complete.",
    "Modals.LanguageSettings.ChooseLanguage":
      "Choose the language you're most comfortable using on this site. You can change it back at any time",
    "Modals.LanguageSettings.LanguageLabel": "Language:",
    "Modals.LanguageSettings.LanguageSettingsHeader": "Language Settings",
    "Modals.MoveContent.CannotMoveToOriginalFolder":
      "Content can not be moved to the folder you are trying to remove it from.",
    "Modals.MoveContent.DecideNewLocation":
      "When you remove items from a folder, you need to decide their new location. Where would you like to move these items?",
    "Modals.MoveContent.MoveContentButton": "Move Content",
    "Modals.MoveContent.SelectDestinationHeader": "Select a Destination",
    "Modals.OutdatedBrowser.ContactWithQuestions":
      "We know that this change will enable you to use all that's available to you in\r\n        PowerDMS. Please don't hesitate to contact our Service Desk at %(supportEmail)s with any\r\n        questions you may have.",
    "Modals.OutdatedBrowser.GoogleChromeIcon": "Google Chrome Icon",
    "Modals.OutdatedBrowser.InternetExplorerIcon": "Internet Explorer Icon",
    "Modals.OutdatedBrowser.MozillaFirefoxIcon": "Mozilla Firefox Icon",
    "Modals.OutdatedBrowser.NotModernBrowser":
      "We noticed you're not running on a modern browser at this time. %(boldOpen)sPowerDMS\r\n         is no longer functional on the browser you're running.%(boldClose)s",
    "Modals.OutdatedBrowser.OutdatedBrowserDetected":
      "Outdated Browser Detected",
    "Modals.OutdatedBrowser.PowerDMSTeam": "The PowerDMS Team",
    "Modals.OutdatedBrowser.ThankYou": "Thank you,",
    "Modals.OutdatedBrowser.UpdateYourBrowser":
      "We want you and your team to have the best experience possible in our application.\r\n        To proceed with using PowerDMS, you will need to update your web browser. %(boldOpen)sPlease\r\n         update to the latest version of one of these great web browsers%(boldClose)s or contact\r\n         your IT department for support.",
    "Modals.OutdatedBrowser.ValuedCustomer": "Valued PowerDMS Customer,",
    "Modals.PartialMove.CancelMoveButton": "Cancel and choose another folder",
    "Modals.PartialMove.ConfirmMoveTitle": "Confirm Move",
    "Modals.PartialMove.ContinuePartialHeader": "Continue with Partial Move",
    "Modals.PartialMove.MoveContentsBelow":
      "Would you like to move all but the items listed below?",
    "Modals.PartialMove.MoveRemainingButton": "Move Remaining Items",
    "Modals.PartialMove.MoveRemainingCheckbox":
      "Yes, exclude items with the same name and move the remaining items to %(targetFolder)s",
    "Modals.PrivilegeLoss.AttributePickerChange":
      "By saving these objects, you may lose some security rights over the ones you wish to remove. Do you want to continue?",
    "Modals.PrivilegeLoss.CancelMove": "No, Cancel Move",
    "Modals.PrivilegeLoss.CancelSave": "No, Cancel Save",
    "Modals.PrivilegeLoss.ContinueMove": "Yes, Continue with the Move",
    "Modals.PrivilegeLoss.ContinueSave": "Yes, Continue with the Save",
    "Modals.PrivilegeLoss.DragDropChange":
      "By moving this %(objectType)s, you may lose some security rights over it. Do you want to continue?",
    "Modals.PrivilegeLoss.FolderChange":
      "By changing the folder of this %(objectType)s, you may lose some security rights over it. Do you want to continue?",
    "Modals.PrivilegeLoss.WarningPrivLossHeader":
      "Warning: Potential Privilege Loss",
    "Modals.WarnPubDocs.InformationLink": "Public Documents Information Guide",
    "Modals.WarnPubDocs.PubDocSettingsTitle": "Public Document Settings",
    "Modals.WarnPubDocs.PubFacingDocsHeader": "Public Facing Documents",
    "Modals.WarnPubDocs.ShowNotificationAgainCheckbox":
      "Never show this notification again",
    "Modals.WarnPubDocs.WarnPublicMeaning":
      'Making this document %(openBoldHTMLTag)sPublic%(closeBoldHTMLTag)s will post a link to the published revision of this document\r\n          on your public facing webpage. If you wish to remove that document link, just change the Public Setting\r\n          back to "Not Public". For more information, please view the article below.',
    "Modals.RenameFolder.FolderNameLabel": "Folder Name:",
    "Modals.RenameFolder.NameCollisionError":
      'A folder with the name "%(enteredName)s" exists in this folder\'s current location (%(parentFolder)s). Please choose a different name.',
    "Modals.RenameFolder.RenamePrompt":
      "A folder with this name already exists. Would you like to rename this folder?",
    "Modals.RenameFolder.RenameThisFolder": "Rename this folder",
    "Modals.RenameFolder.UpdatingButton": "Renaming",
    "PowMultiselect.NumberSelected": "%(number)s selected",
    "PowMultiselect.SelectOptions": "Select options",
    "PowNotification.Refresh": "Refresh",
    "PowNotification.Retry": "Retry",
    "PowNotification.ConnectedToPowerDMS": "Connected to PowerDMS",
    "PowNotification.DocumentBookmarked": "Document Bookmarked.",
    "PowNotification.BookmarkRemoved": "Bookmark Removed.",
    "PowNotification.DocumentLinkCopySuccess":
      "Document Link Copied to Clipboard.",
    "PowNotification.DocumentLinkCopyFail":
      "It looks like your security settings are blocking this feature. Check your settings and try again.",
    "PowNotification.FeatureActivationError":
      "Error occurred enabling this feature. Try again.",
    "PowNotification.FeatureActived":
      "%(feature)s has been successfully activated.",
    "PowNotification.FeatureAlreadyEnabled": "%(feature)s is already enabled.",
    "PowNotification.NetworkConfigMayBeBlockingPowerDMSFeatures":
      "Looks like your network configuration may be blocking\r\n          some of the features of PowerDMS.",
    "PowNotification.PleaseContactSupport":
      "Please contact support for assistance by calling %(phoneNumber)s\r\n          or via e-mail at %(email)s.",
    "PowNotification.PowerDMSFailedToLoadProperly":
      "PowerDMS failed to load properly.\r\n          Some parts of the app will not function correctly.",
    "PowNotification.PowerDMSUpdateIsAvailable":
      "A new update for PowerDMS is available.",
    "PowNotification.PowerDMSUpdateIsAvailableForced":
      "A new update for PowerDMS is available.\r\n          Your page will be automatically refreshed the next time\r\n          you navigate to another PowerDMS page.",
    "PowNotification.SiteConnected": "Connected to %(siteName)s successfully.",
    "PowNotification.TryingToReconnect": "Trying to reconnect...",
    "PowNotification.YouAppearToBeOffline":
      "You appear to be offline. PowerDMS is trying to reconnect.",
    "PowNotification.SiteModified":
      "\r\n          Your site has been modified and some features may not work properly. Please log\r\n          back in to avoid any interruption.\r\n        ",
    "PowNotification.LogOut": "\r\n          Log Out\r\n        ",
    "PowValidation.InvalidDate": "Please enter a valid date.",
    "PowValidation.InvalidEmail": "Please enter a valid email address.",
    "PowValidation.NetworkError":
      "%(strong)sError%(endStrong)s, couldn't communicate with the server.",
    "PowValidation.RequiredField": "Field is required.",
    "PowValidation.TooLong": "Input is too long.",
    "PowValidation.TooShort": "Input is too short.",
    "PowValidation.PasswordRequirements.CriteriaHeader": "Password Criteria:",
    "PowValidation.PasswordRequirements.Length":
      "At least %(minimumLength)s characters",
    "PowValidation.PasswordRequirements.Lowercase": "Lowercase letters",
    "PowValidation.PasswordRequirements.OneNumber": "At least one number",
    "PowValidation.PasswordRequirements.PersonalInfo":
      "Doesn't contain personal info",
    "PowValidation.PasswordRequirements.Symbol": "At least one symbol",
    "PowValidation.PasswordRequirements.Uppercase": "Uppercase letters",
    "PowValidation.PasswordRequirements.UppercaseLowercase":
      "Upper and lowercase letters",
    "PowValidation.SiteKey.CheckingForAvailability":
      "%(strong)sChecking%(endStrong)s if this site key is available...",
    "PowValidation.SiteKey.NonUniqueError":
      "%(strong)sSorry%(endStrong)s, this site key is already taken.",
    "PowValidation.SiteKey.UniqueSuccess":
      "%(strong)sSuccess!%(endStrong)s This site key is available.",
    "Navigation.SideMenu.Admin": "Admin",
    "Navigation.SideMenu.AddFilterSuggestion": "Add Filter?",
    "Navigation.SideMenu.AlsoFoundIn": "Also found in:",
    "Navigation.SideMenu.AndOneMoreGroup": "and 1 more group.",
    "Navigation.SideMenu.AndXMoreGroups": "and %(count)s more groups.",
    "Navigation.SideMenu.Bookmark": "Bookmark",
    "Navigation.SideMenu.BookmarkHelpText":
      "When searching, you can click the %(iconMarkup)s icon to bookmark items here. Bookmarking items won't affect other users.",
    "Navigation.SideMenu.BookmarkItemTooltip": "Bookmark this item",
    "Navigation.SideMenu.BookmarksPanel": "Bookmarks",
    "Navigation.SideMenu.BookmarksSaveError":
      "There seems to be a problem updating your bookmarks. Please refresh the page and try again.",
    "Navigation.SideMenu.BookmarksTitle": "Your Bookmarks",
    "Navigation.SideMenu.Errors.CantUndoPermissions":
      "Couldn't undo, item permissions have changed.",
    "Navigation.SideMenu.ChangesMayHaveBeenMade":
      "Changes may have been made to your site that aren't visible in your side menu yet.",
    "Navigation.SideMenu.ClosedItem": "Closed %(itemName)s.",
    "Navigation.SideMenu.ConnectContentProvider":
      "Connect to a content provider using the option above",
    "Navigation.SideMenu.ContentHubSummarizedResultsPanel":
      "Summarized Results",
    "Navigation.SideMenu.ContentHubProviderResults":
      "%(numResults)s results found in",
    "Navigation.SideMenu.CopyNotification":
      "%(item)s was copied from %(oldLocation)s to %(newLocation)s",
    "Navigation.SideMenu.CreateFolder": "Create",
    "Navigation.SideMenu.CreatingFolder": "Creating",
    "Navigation.SideMenu.Errors.Cycle":
      "Cycle Error: Cannot place an item under itself or any of its children.",
    "Navigation.SideMenu.Errors.DeepLink":
      "We were unable to reveal the desired item. Please try again.",
    "Navigation.SideMenu.DidYouMean":
      "%(startWrapper)sDid you mean%(endWrapper)s %(suggestion)s%(startWrapper)s?%(endWrapper)s",
    "Navigation.SideMenu.DidYouMeanTooltip": "Did you mean %(suggestion)s",
    "Navigation.SideMenu.Drafted": "Draft: %(date)s",
    "Navigation.SideMenu.Errors.FolderSameName":
      "A folder with this name already exists.",
    "Navigation.SideMenu.Errors.NoInternet":
      "Please check your internet connection.",
    "Navigation.SideMenu.FilesPanel": "Files",
    "Navigation.SideMenu.FindAnything": "Find Anything",
    "Navigation.SideMenu.FolderName": "Folder Name",
    "Navigation.SideMenu.HomePage": "Home Page",
    "Navigation.SideMenu.JobTitleLocation": "%(jobTitle)s - %(location)s",
    "Navigation.SideMenu.LoadFailed":
      "Unable to load. Please check your internet connection.",
    "Navigation.SideMenu.LoadingAriaLabel": "Loading indicator",
    "Navigation.SideMenu.ManualAndRevision": "%(manual)s - %(revision)s",
    "Navigation.SideMenu.ManualCurrentDraft": "Current Draft",
    "Navigation.SideMenu.ManualRevisions": "Revisions",
    "Navigation.SideMenu.AddChapter": "Add Chapter",
    "Navigation.SideMenu.AddInformation": "Add Information",
    "Navigation.SideMenu.AddStandard": "Add Standard",
    "Navigation.SideMenu.DeleteList": "Delete List",
    "Navigation.SideMenu.MoveItemUp": "Move Item Up",
    "Navigation.SideMenu.MoveItemDown": "Move Item Down",
    "Navigation.SideMenu.MasterAdmin": "Master Admin",
    "Navigation.SideMenu.MemberOfXGroups": "Member of %(count)s groups",
    "Navigation.SideMenu.MembersOfGroup": "Members of %(groupName)s",
    "Navigation.SideMenu.MoveNotification":
      "%(item)s was moved from %(oldLocation)s to %(newLocation)s",
    "Navigation.SideMenu.NavigationAccessibilityName": "Side Menu",
    "Navigation.SideMenu.NavigatedToFirstItem": "Navigated to first item.",
    "Navigation.SideMenu.NavigatedToLastItem": "Navigated to last item.",
    "Navigation.SideMenu.OpenedItem": "Opened %(itemName)s.",
    "Navigation.SideMenu.OpenedSiblingFolders": "Opened all sibling folders.",
    "Navigation.SideMenu.OneOpenAssessment": "1 Open Assessment:",
    "Navigation.SideMenu.OneOpenSections": "1 open section",
    "Navigation.SideMenu.NewDocument": "New Document",
    "Navigation.SideMenu.NewDocumentPlaceholder": "New Document",
    "Navigation.SideMenu.NewFolder": "New Folder",
    "Navigation.SideMenu.NoBookmarks": "You don't have any bookmarks yet.",
    "Navigation.SideMenu.NoItemsToDisplay": "No %(itemTypes)s to display",
    "Navigation.SideMenu.NoOpenSections": "No open sections",
    "Navigation.SideMenu.NoResults": "No results found.",
    "Navigation.SideMenu.NumberOfResults":
      "Results %(boldOpen)s%(rangeStart)s - %(rangeEnd)s%(boldClose)s of %(totalCount)s",
    "Navigation.SideMenu.Pin": "Pin",
    "Navigation.SideMenu.PowerRecall": "Power Recall",
    "Navigation.SideMenu.Published": "Published: %(date)s",
    "Navigation.SideMenu.ProblemConnecting":
      "Hmmmm, there's a problem connecting. %(lineBreak)s Trying again in %(secondsToWait)s seconds.",
    "Navigation.SideMenu.ProblemConnecting1":
      "Hmmmm, there's a problem connecting.",
    "Navigation.SideMenu.ProblemConnecting2":
      "Trying again in %(secondsToWait)s seconds.",
    "Navigation.SideMenu.ReloadButton": "Reload",
    "Navigation.SideMenu.RemoveFilter": "Remove filter",
    "Navigation.SideMenu.RemoveBookmarkTooltip": "Remove this bookmark",
    "Navigation.SideMenu.SearchPanel": "Search",
    "Navigation.SideMenu.SearchBoxAriaLabel":
      "Search - Use the arrow keys to navigate results.",
    "Navigation.SideMenu.TryAgain": "Click here to try again.",
    "Navigation.SideMenu.Unbookmark": "Unbookmark",
    "Navigation.SideMenu.UndoButton": "Undo",
    "Navigation.SideMenu.Unpin": "Unpin",
    "Navigation.SideMenu.UsersAndGroupsPanel": "Users and Groups",
    "Navigation.SideMenu.ReportsPanel": "Reports",
    "Navigation.SideMenu.XOpenAssessments": "%(count)s Open Assessments:",
    "Navigation.SideMenu.XOpenSections": "%(count)s open sections",
    "Navigation.SideMenu.CollapseAll": "Collapse All",
    "Navigation.SideMenu.ExpandAll": "Expand All",
    "Navigation.TopMenu.AdministrationMenu": "Administration Menu",
    "Navigation.TopMenu.AgentsUnavailable": "All Agents unavailable",
    "Navigation.TopMenu.CertificatesCount": "Certificates (%(count)s)",
    "Navigation.TopMenu.Champions": "Champions",
    "Navigation.TopMenu.AccountSettings": "Account Settings",
    "Navigation.TopMenu.Chat": "Chat with an Agent",
    "Navigation.TopMenu.CoursesCount": "Courses (%(count)s)",
    "Navigation.TopMenu.DocumentsCount": "Documents (%(count)s)",
    "Navigation.TopMenu.InboxCount": "Inbox %(count)s",
    "Navigation.TopMenu.LanguageLabel": "Language:",
    "Navigation.TopMenu.MessagesCount": "Messages (%(count)s)",
    "Navigation.TopMenu.MultipleNewCertificates":
      "You have %(numItems)d new certificates to acquire.",
    "Navigation.TopMenu.MultipleNewCourses":
      "You have %(numItems)d new courses.",
    "Navigation.TopMenu.MultipleNewDocuments":
      "You have %(numItems)d new documents to sign.",
    "Navigation.TopMenu.MultipleNewDocumentActions":
      "Your action is required on %(numItems)d documents.",
    "Navigation.TopMenu.MultipleNewMessages":
      "You have %(numItems)d new messages.",
    "Navigation.TopMenu.MultipleNewStandards":
      "You have %(numItems)d new standards.",
    "Navigation.TopMenu.MultipleNewSurveys":
      "You have %(numItems)d new surveys.",
    "Navigation.TopMenu.MultipleNewTests": "You have %(numItems)d new tests.",
    "Navigation.TopMenu.MyProfile": "My Profile",
    "Navigation.TopMenu.NavigationAccessibilityName": "Top Menu",
    "Navigation.TopMenu.NewMessageTitle": "New Message",
    "Navigation.TopMenu.OneNewCertificate":
      "You have 1 new certificate to acquire.",
    "Navigation.TopMenu.OneNewCourse": "You have 1 new course.",
    "Navigation.TopMenu.OneNewDocument": "You have 1 new document to sign.",
    "Navigation.TopMenu.OneNewDocumentAction":
      "Your action is required on 1 document.",
    "Navigation.TopMenu.OneNewMessage": "You have 1 new message.",
    "Navigation.TopMenu.OneNewStandard": "You have 1 new standard.",
    "Navigation.TopMenu.OneNewSurvey": "You have 1 new survey.",
    "Navigation.TopMenu.OneNewTest": "You have 1 new test",
    "Navigation.TopMenu.Reports": "Reports",
    "Navigation.TopMenu.Analytics": "Analytics",
    "Navigation.TopMenu.ReportsBetaLabel": "beta",
    "Navigation.TopMenu.ShowMeHow": "Show Me How To...",
    "Navigation.TopMenu.SiteKeyLabel": "Site Key:",
    "Navigation.TopMenu.StandardsCount": "Standards (%(count)s)",
    "Navigation.TopMenu.SuccessCommunity": "Success Community",
    "Navigation.TopMenu.University": "PowerDMS University",
    "Navigation.TopMenu.SurveysCount": "Surveys (%(count)s)",
    "Navigation.TopMenu.TestsCount": "Tests (%(count)s)",
    "Navigation.TopMenu.ViewInbox": "View",
    "Navigation.TopMenu.WelcomeUser": "Welcome, %(userName)s",
    "Navigation.TopMenu.RecallCount": "Recall (%(count)s)",
    "Notifications.Title": "Notifications",
    "Notifications.NoNotifications": "No notifications to display",
    "Notifications.DismissAll": "Dismiss All",
    "ProductDetails.Title": "Product Details",
    "Providers.ContentHub": "Content Hub",
    "Providers.ContentHubAbbreviation": "Hub",
    "Providers.GoogleDrive": "Google Drive",
    "Providers.OneDrive": "OneDrive",
    "PublicDocs.ContactSales":
      "If you are a member of this PowerDMS site, please email %(email)s to activate this part of PowerDMS.",
    "PublicDocs.PageHeader": "Documents",
    "PublicDocs.LoadErrorMessage":
      "You can click %(linkStart)shere%(linkEnd)s to try again.",
    "PublicDocs.DocumentMissingMessage":
      "The requested Document was not found.",
    "PublicDocs.EmptyMessage": "This site doesn't have any public documents.",
    "PublicDocs.Menu": "Menu",
    "PublicDocs.NoSiteFound": "No Site Found",
    "PublicDocs.OrgDoesntHavePubDocs":
      "This organization does not have a public documents page.",
    "PublicDocs.PageDoesNotExist": "Page Does Not Exist",
    "PublicDocs.PoweredBy": "Powered by",
    "PublicDocs.PublicDocsDirectory": "Public Documents Directory",
    "RadTools.LinkManager.TypeCustomTarget": "Type Custom Target Here",
    "RadTools.MergeFields.ChangesNeeded":
      "%(count)s merge fields have changed.",
    "RadTools.MergeFields.UpdateConfirmation":
      "Would you like to update these merge fields now?",
    "Reports.ReportBuilder": "Report Builder",
    "Search.TaggedWith": "Tagged with: %(tag)s",
    "Search.ErrorSearching": "Error searching. Please try again.",
    "Search.EnterCreation": "Hit Enter to create a new one.",
    "Search.NoResults": "No results found.",
    "Search.CourseNumberName": "%(courseNumber)s - %(courseName)s",
    "Search.StartTyping": "Start typing to search.",
    "Search.DisabledReason.LinkedGroup":
      "Update this user's job title or location to add it to this linked group.",
    "Search.DisabledReason.CycleError":
      "This object is a parent of the current object (or is the object itself) and can't be added as a member.",
    "Search.DisabledReason.RequireEditCascading":
      "You need Edit Cascading over this object to add it.",
    "Search.ShowingResultsRange":
      "Showing results 1 - %(numberShown)s of %(totalResults)s",
    "Ssa.AppleBadgeAlt": "Download on the App Store",
    "Ssa.ContinueInBrowser": "Continue in browser",
    "Ssa.Copyright":
      "© Copyright %(currentYear)s PowerDMS, Inc. All rights reserved.",
    "Ssa.CreateAccount": "Create Account",
    "Ssa.CreateYourAccount": "Create your PowerDMS account",
    "Ssa.GenericHeader": "404: Page Not Found",
    "Ssa.GenericMessage":
      "The destination you are trying to reach no longer exists.",
    "Ssa.GoogleBadgeAlt": "Get it on Google Play",
    "Ssa.HidePassword": "Hide password",
    "Ssa.NeedHelpHeader": "Contact Support",
    "Ssa.NeedHelpMessage":
      "Please contact us via phone %(phoneNumber)s or e-mail at %(email)s. We're always happy to help!",
    "Ssa.NotYouLink": "Not you?",
    "Ssa.NotYouHeader": "Not you?",
    "Ssa.NotYouBody":
      "Please email us at %(email)s or call %(phoneNumber)s to help you get setup.",
    "Ssa.InvalidTokenHeader": "Access Restricted",
    "Ssa.InvalidTokenMessage":
      "The page you are trying to reach has restricted access. Please contact your administrator if you are facing login issues.",
    "Ssa.ReturnToLogin": "Return to Login",
    "Ssa.ShowPassword": "Show password",
    "Ssa.TokenExpiredHeader": "Access Expired",
    "Ssa.TokenExpiredMessage":
      "Your access code to this PowerDMS site has expired. Please contact our support team to access this site.",
    "Ssa.VisitPowerDms": "Visit PowerDMS.com for more information.",
    "Ssa.YouHaveJoinedOthers":
      "\r\n          Congratulations! You've joined over 3000 organizations just like yours that manage and\r\n          share their crucial content using PowerDMS.\r\n        ",
    "Ssa.WelcomeToPowerDms": "Welcome to PowerDMS!",
    "Ssa.WorkEmailAddressLabel": "Work Email Address",
    "Ssa.EightCharsOrMore": "At least 8 characters",
    "Ssa.UpperAndLowercase": "Upper and lowercase letters",
    "Ssa.OneNumberOrMore": "At least one number",
    "Ssa.NoPersonalInfo": "Doesn't contain personal info",
    "Ssa.UsernameConstraints":
      "Username is required and cannot contain ', \", #, &, or ?.",
    "Signup.AdhereToStandards":
      "Do you adhere to any Accreditation Standards or Regulations? %(optionalStart)s(optional)%(optionalEnd)s",
    "Signup.Copyright":
      "%(copyrightSymbol)s Copyright %(currentYear)s PowerDMS, Inc. All rights reserved.",
    "Signup.DropdownPlaceholder": "- Select One -",
    "Signup.FirstNamePlaceholder": "First",
    "Signup.IndustryLabel": "What's your industry?",
    "Signup.IndustryError": "Please enter your industry",
    "Signup.InvalidLocation":
      "We've detected that your location appears to be outside North America.\r\n          We regret to inform you that for legal reasons we're currently only able to sell\r\n          PowerDMS in the United States, Canada, and Mexico. If you believe this message\r\n          is in error, please contact us at %(phoneNumber)s and we'll be happy to get your free trial set up.\r\n          ",
    "Signup.LastNamePlaceholder": "Last",
    "Signup.LetsGetStarted": "Let's get started!",
    "Signup.LoggingIn": "Logging in...",
    "Signup.LogInToYourTrial": "Log in to your 14 Day Trial!",
    "Signup.NameLabel": "Name:",
    "Signup.OneMoreToGo": "One more to go!",
    "Signup.OrganizationNameLabel": "Organization Name:",
    "Signup.OrganizationNamePlaceholder": "The name of your organization",
    "Signup.OrganizationSizeLabel": "What's your organization's size?",
    "Signup.PasswordExplanation":
      "We like strong passwords with at least 8 characters including numbers and upper and lowercase letters.",
    "Signup.PasswordLabel": "Password:",
    "Signup.SitekeyError":
      "Please enter an alphanumeric site key that contains at least 1 letter.",
    "Signup.SitekeyExplanation":
      "This is a short abbreviation that will identify your site.",
    "Signup.SitekeyLabel": "Site Key:",
    "Signup.StartYourTrial": "Start your trial in 3 easy steps",
    "Signup.Step1Title": "User Info",
    "Signup.Step2Title": "Company Info",
    "Signup.Step3Title": "Site Info",
    "Signup.TimezoneExplanation":
      "If your organization spans multiple timezones, pick the primary one.",
    "Signup.TermsOfService":
      "I agree to the %(linkStart)sTerms of Service%(linkEnd)s",
    "Signup.TimezoneLabel": "Timezone:",
    "Signup.UsernameExplanation":
      "Pick something memorable. You'll use this to log in.",
    "Signup.UsernameLabel": "Username:",
    "Signup.ViewErrorDetails":
      "You can %(linkStart)sclick here%(linkEnd)s to view the technical details:",
    "Signup.ViewPrivacyPolicy": "View Privacy Policy",
    "Signup.WhatCanIDo": "What can I do in my 14-day, full-access trial?",
    "Signup.WhatCanIDoBullet1": "Upload and create documents",
    "Signup.WhatCanIDoBullet2": "Securely share and collaborate on documents",
    "Signup.WhatCanIDoBullet3": "Create online training courses",
    "Signup.WhatCanIDoBullet4": "Manage your compliance process electronically",
    "Signup.WhatCanIDoBullet5": "Why not start today? It's free for 14 days!",
    "Signup.WhatProblem":
      "What problem would you like to start solving with PowerDMS? %(optionalStart)s(optional)%(optionalEnd)s",
    "Signup.WorkEmailLabel": "Work Email:",
    "Signup.WorkEmailPlaceholder": "Your work email account",
    "Signup.Industries.Banking": "Banking/Financial",
    "Signup.Industries.Corrections": "Corrections",
    "Signup.Industries.Education": "Education",
    "Signup.Industries.Energy": "Energy/Utilities",
    "Signup.Industries.Entertainment": "Entertainment/Media",
    "Signup.Industries.Fire": "Fire/EMS",
    "Signup.Industries.Government": "Government",
    "Signup.Industries.Healthcare": "Healthcare",
    "Signup.Industries.Hospitality": "Hospitality/Gaming",
    "Signup.Industries.Insurance": "Insurance",
    "Signup.Industries.LawEnforcement": "Law Enforcement",
    "Signup.Industries.Legal": "Legal",
    "Signup.Industries.Manufacturing": "Manufacturing",
    "Signup.Industries.Marketing": "Marketing",
    "Signup.Industries.Municipalities": "Municipalities",
    "Signup.Industries.NotForProfit": "Not For Profit",
    "Signup.Industries.Retail": "Retail",
    "Signup.Industries.Technology": "Technology",
    "Signup.Industries.Transportation": "Transportation",
    "Signup.MarketoProblems.Compliance": "Compliance/Accreditation",
    "Signup.MarketoProblems.EmployeeTraining": "Employee Training",
    "Signup.MarketoProblems.PolicyManagement": "Policy Management",
    "Signup.OrgSizes.FiftyOneToOneHundred": "51-100 employees",
    "Signup.OrgSizes.FiveHundredOneToOneThousand": "501-1000 employees",
    "Signup.OrgSizes.OneHundredOneToFiveHundred": "101-500 employees",
    "Signup.OrgSizes.OneThousandPlus": "1000+ employees",
    "Signup.OrgSizes.OneToTwentyFive": "1-25 employees",
    "Signup.OrgSizes.TwentySixToFifty": "26-50 employees",
    "Signup.Problems.DocumentManagement": "Document Management",
    "Signup.Problems.OnlineAccreditation": "Online Accreditation",
    "Signup.Problems.OnlineTraining": "Online Training",
    "Signup.Problems.Other": "Other",
    "Standards.FeatureModal.HeaderTitle":
      "Introducing Standards in the Sidebar",
    "Standards.FeatureModal.Title":
      "We've updated how you navigate Assessments & Standards Manuals",
    "Standards.FeatureModal.ParagraphOne":
      'You can now browse and search for standards manual content from the\r\n          blue "Find Anything" sidebar. To keep the bar open while navigating your assessment,\r\n          just click the "%(pinIcon)s Pin" link at the top of the bar.',
    "Standards.FeatureModal.ParagraphTwo":
      "\r\n          All of the existing manuals in your site will be excluded by default\r\n          in your site-wide search but you can include them at any time.\r\n          All new manuals your site subscribes to will be included by default.\r\n          Don't want to see content from a specific manual in search?\r\n          Site admins can exclude manuals from search results. %(linkStart)sLearn More%(linkEnd)s\r\n        ",
    "Standards.FeatureModal.ConfirmButton": "Got it!",
    "StatusText.Fail": "Fail",
    "StatusText.InProgress": "In Progress",
    "StatusText.NotStarted": "Not Started",
    "StatusText.Pass": "Pass",
    "StatusText.PendingApproval": "Pending Approval",
    "StatusText.PendingGrade": "Pending Grade",
    "Surveys.NewSurvey": "New Survey",
    "Surveys.TakeSurvey": "Take Survey",
    "Table.ChangeButton": "Change",
    "Table.GoToFirstPage": "Go to first page",
    "Table.GoToLastPage": "Go to last page",
    "Table.ItemsPerPage": "Items per page",
    "Table.ItemsOnPage":
      "Showing items %(openRange)s to %(closeRange)s of %(totalItems)s",
    "Table.NextPage": "Go to next page",
    "Table.NoRecordsToDisplay": "No records to display.",
    "Table.Page": "Page",
    "Table.PageOfPages": "of %(totalPages)s",
    "Table.Pagination": "Table Pagination",
    "Table.PreviousPage": "Go to previous page",
    "Table.Selected": "Selected",
    "Table.TableIsSortedAscending":
      "The table is now sorted by %(columnName)s, ascending.",
    "Table.TableIsSortedDescending":
      "The table is now sorted by %(columnName)s, descending.",
    "Table.TableIsUnsorted": "The table is now unsorted.",
    "Table.ToggleSorting": "Click to toggle column sorting.",
    "Tags.NameAlreadyExists": "A tag by this name already exists.",
    "Tags.NameLabel": "Tag",
    "Tags.SeeTagged": "See Tagged",
    "Tags.URLLabel": "URL",
    "TaskStatuses.Acquired": "Acquired",
    "TaskStatuses.Approved": "Approved",
    "TaskStatuses.Awarded": "Awarded",
    "TaskStatuses.Completed": "Completed",
    "TaskStatuses.Denied": "Denied",
    "TaskStatuses.Fail": "Fail",
    "TaskStatuses.Failed": "Failed",
    "TaskStatuses.InProgress": "In Progress",
    "TaskStatuses.Pass": "Pass",
    "TaskStatuses.Passed": "Passed",
    "TaskStatuses.Reviewed": "Reviewed",
    "TaskStatuses.Renewed": "Renewed",
    "TaskStatuses.NeedToAcquire": "Need To Acquire",
    "TaskStatuses.NeedToRenew": "Need To Renew",
    "TaskStatuses.NotStarted": "Not Started",
    "TaskStatuses.PendingGrade": "Pending Grade",
    "TaskStatuses.PendingApproval": "Pending Approval",
    "TaskStatuses.Signed": "Signed",
    "TaskStatuses.VotedOn": "Voted on",
    "Tests.NewTest": "New Test",
    "Tests.TestReview": "Test Review",
    "Tests.TakeTest": "Take Test",
    "Text.Align.Center": "Center",
    "Text.Align.Left": "Left",
    "Text.Align.Right": "Right",
    "Text.FontSize.Large": "large",
    "Text.FontSize.Medium": "medium",
    "Text.FontSize.Small": "small",
    "Text.FontSize.xlarge": "x-large",
    "Text.FontSize.xsmall": "x-small",
    "Text.FontSize.xxlarge": "xx-large",
    "Text.FontSize.xxsmall": "xx-small",
    "Text.FontWeight.Bold": "bold",
    "Text.FontWeight.Bolder": "bolder",
    "Text.FontWeight.Lighter": "lighter",
    "Text.FontWeight.Normal": "normal",
    "Text.Label.Align": "Align:",
    "Text.Label.Color": "Color:",
    "Text.Label.Font": "Font:",
    "Text.Label.Format": "Format:",
    "Text.Label.Size": "Size:",
    "Text.Label.Transform": "Transform:",
    "Text.Label.Type": "Type:",
    "Text.Label.Weight": "Weight:",
    "Text.NameFormat1": "{first} {last}",
    "Text.NameFormat2": "{username} - {first} {last}",
    "Text.NameFormat3": "{last}, {first}",
    "Text.NameFormat4": "{Username}",
    "Text.NameFormat5": "{First} {Last}",
    "Text.NameFormat6": "{Last} {First}",
    "Text.Transform.Capitalize": "Capitalize",
    "Text.Transform.Lowercase": "Lowercase",
    "Text.Transform.Uppercase": "Uppercase",
    "TrainingRequests.NewTrainingRequest": "New Training Request",
    "Thickbox.CloseWindow": "Close Window (or Esc Key)",
    "UserAdmin.ArchiveButton.Default": "Archive",
    "UserAdmin.ArchiveButton.InProgress": "Archiving",
    "UserAdmin.ArchivedWarning":
      "%(openStrong)sArchived:%(closeStrong)s This user has been archived and cannot log in.",
    "UserAdmin.Archived.EmailWarning":
      "%(openStrong)sEmail Required:%(closeStrong)s A unique email is required to restore this user.",
    "UserAdmin.ChoosePasswordOnLogin":
      "User will choose a new password upon login.",
    "UserAdmin.DeactivateAndArchiveButton": "Deactivate %(ampersand)s Archive",
    "UserAdmin.Header": "%(username)s - %(fullName)s",
    "UserAdmin.InboxSignAll.Label": "Inbox Sign All:",
    "UserAdmin.InboxSignAll.HelpText":
      "This will automatically be unchecked after %(username)s has used this\r\n          feature.",
    "UserAdmin.InboxSignAll.HelpTextNoUser":
      "This will automatically be unchecked after the user has used this feature.",
    "UserAdmin.PasswordExpirationDate.Label": "Password Exp Date:",
    "UserAdmin.PasswordExpirationDay.Label": "Password Exp Days:",
    "UserAdmin.PasswordExpirationDay.ErrorMessage":
      "Please enter a value from %(minValue)d-%(maxValue)d.",
    "UserAdmin.PasswordSetViaEmail": "Set by user via Welcome Email",
    "UserAdmin.RestoreButton.Default": "Restore",
    "UserAdmin.RestoreButton.InProgress": "Restoring",
    "UserAdmin.SaveAndNew": "Save %(ampersand)s New",
    "UserAdmin.SendWelcomeEmail.HelpText":
      "Sends user an initial login link to set their own password.",
    "UserAdmin.Username.ErrorMessage":
      "Username is required and cannot contain %(quote)s, %(pound)s,\r\n          %(ampersand)s, or %(questionMark)s.",
    "UserAdmin.FirstName.Foundation.ErrorMessage":
      "First Name is required and cannot contain %(greaterthan)s, %(lessthan)s, %(ampersand)s,\r\n          %(percent)s, %(quote)s, or %(semicolan)s.",
    "UserAdmin.LastName.Foundation.ErrorMessage":
      "Last Name is required and cannot contain %(greaterthan)s, %(lessthan)s, %(ampersand)s,\r\n          %(percent)s, %(quote)s, or %(semicolan)s.",
    "UserAdmin.MiddleName.Foundation.ErrorMessage":
      "Middle Name cannot contain %(greaterthan)s, %(lessthan)s, %(ampersand)s,\r\n          %(percent)s, %(quote)s, or %(semicolan)s.",
    "UserAdmin.Username.Foundation.ErrorMessage":
      "Username is required and cannot contain %(greaterthan)s, %(lessthan)s, %(ampersand)s,\r\n          %(percent)s, %(quote)s, or %(semicolan)s.",
    "UserAdmin.Email.Foundation.ErrorMessage":
      "Email is required and should be in a valid format.",
    "UserAdmin.CreateDocPrivModal.ConfirmButton": "Got it!",
    "UserAdmin.CreateDocPrivModal.EditAllowCreation":
      "Edit rights allow a User or Group to create documents and folders within that folder.",
    "UserAdmin.CreateDocPrivModal.GrantEdit": "Grant Edit over the folder.",
    "UserAdmin.CreateDocPrivModal.GrantPrivilege":
      "Grant Create Document Privilege",
    "UserAdmin.CreateDocPrivModal.NavigateToFolderOrGroup":
      "Navigate to a Folder or a Group.",
    "UserAdmin.CreateDocPrivModal.RemoveEdit": "Remove Edit over the folder.",
    "UserAdmin.CreateDocPrivModal.RevokePrivilege":
      "Revoke Create Document Privilege",
    "UserAdmin.CreateDocPrivModal.RevokeToRemove":
      "The Create Document privilege is now tied to having edit rights at a folder. To\r\n          remove Create Document rights, revoke this user's Administrative Privileges and/or remove\r\n          the edit rights they have over folders.",
    "UserAdmin.CreateDocPrivModal.SelectSecurityFromFolder":
      "From a Folder select Security Over This Folder.",
    "UserAdmin.CreateDocPrivModal.SelectFolderFromGroup":
      "From a Group select Folders under Rights and Assignments.",
    "UserAdmin.CreateDocPrivModal.SpecifyTheFolders":
      "You may now specify the folders in which a user or group has create document rights.",
    "UserAdmin.CreateDocPrivModal.Title": "Privilege Change",
    "UserAdmin.ArchiveUserModal.ArchiveNow": "Archive Now",
    "UserAdmin.ArchiveUserModal.ArchiveOn": "Archive On",
    "UserAdmin.ArchiveUserModal.Header": "Archive this user",
    "UserAdmin.ArchiveUserModal.Message":
      "Once archived, this user will no longer be able to log into PowerDMS. You will\r\n          still retain all records of their actions in the system, but they will no longer take up\r\n          a user license.",
    "UserAdmin.DeleteUserModal.AgreeToDeletePermanently":
      "I understand the risk and wish to permanently delete user %(username)s and ALL\r\n          related records from the database.",
    "UserAdmin.DeleteUserModal.AlreadyArchived":
      "%(username)s is currently archived, is unable to log in to this PowerDMS site,\r\n          and is not consuming a license. All of their user records are retained at no\r\n          cost.",
    "UserAdmin.DeleteUserModal.CancelDeletion": "Cancel Deletion",
    "UserAdmin.DeleteUserModal.ConfirmButton.Idle":
      "I know what I'm doing: Permanently Delete",
    "UserAdmin.DeleteUserModal.FollowTheSteps":
      "If you still want to delete all records of this user, follow the steps\r\n          below.",
    "UserAdmin.DeleteUserModal.Header": "Confirm Delete",
    "UserAdmin.DeleteUserModal.PermanentlyDeleteUser":
      "You have selected to completely erase user %(username)s. By choosing this option,\r\n          you understand that PowerDMS will permanently delete user %(username)s and ALL related\r\n          records from the database.",
    "UserAdmin.DeleteUserModal.TypeToDelete":
      'In order to proceed with the permanent deletion of this item, type the word\r\n          "%(keyword)s" into the text box:\r\n        ',
    "UserAdmin.RestoreUserModal.Header": "Restore this user",
    "UserAdmin.RestoreUserModal.Message":
      "You're about to create and restore a user in Foundation, visible across all foundation products with the provided unique email. If a non-Foundation product user shares this email, they risk being updated with this information.\r\n        ",
    "Users.AddressLabel": "Address:",
    "Users.AddPhoneLabel": "Add Phone:",
    "Users.CityLabel": "City:",
    "Users.ConfirmationEmailSent":
      "Upon saving, a confirmation email will be sent to this account to verify that this information is correct.",
    "Users.EditMyProfile": "Edit My Profile",
    "Users.EmailAddressVerified": "This email address has been verified",
    "Users.EmailLabel": "Email:",
    "Users.ExtensionLabel": "Extension:",
    "Users.FirstNameLabel": "First Name:",
    "Users.FullName": "%(givenName)s %(surname)s",
    "Users.JobTitleLabel": "Job Title:",
    "Users.LastNameLabel": "Last Name:",
    "Users.ManageUser": "Manage User",
    "Users.MiddleName": "Middle Name",
    "Users.NewUser": "New User",
    "Users.Notes": "Notes",
    "Users.RemoveField": "Remove field",
    "Users.SendMessage": "Send a message to this user.",
    "Users.StateLabel": "State:",
    "Users.SurnameCommaGivenName": "%(surname)s, %(givenName)s",
    "Users.SurnameGivenNameAndUsername":
      "%(username)s - %(surname)s, %(givenName)s",
    "Users.UserProfile": "User Profile",
    "Users.ZipCodeLabel": "Zip Code:",
    "UsersAdmin.UsernameValidationError":
      "Username is required and cannot contain ', \", #, &, or ?.",
    "UserSync.Title": "User Sync",
    "AccountSettings.Header": "Account Settings",
    "AccountSettings.Tabs.password": "Password",
    "AccountSettings.Tabs.email": "Email",
    "AccountSettings.Tabs.securityQuestions": "Security Questions",
    "AccountSettings.Tabs.contentHub": "Content Hub",
    "AccountSettings.Tabs.thirdPartyAccess": "Third Party Access",
    "ThirdPartyAccessTab.ConnectionAccessInfo":
      "The following are third parties that you have given access to some of your PowerDMS data, including information that may be sensitive.",
    "ThirdPartyAccessTab.ThirdParties": "Third Parties",
    "ThirdPartyAccessTab.Revoke.RevokeAccess": "Revoke Access",
    "ThirdPartyAccessTab.Revoke.RevokeThirdPartyAccess":
      "Revoke %(thirdParty)s Access",
    "ThirdPartyAccessTab.Revoke.AccessWarning":
      "%(thirdParty)s will no longer have access to any of your new PowerDMS content.",
    "ThirdPartyAccessTab.Revoke.ShareWarning":
      "Any content that you have already shared with them will remain shared until you remove it from their system.",
    "UserEmailTab.UpdateError.InvalidEmail":
      "You have entered an invalid email address. Please confirm your email address is correct and try again.",
    "UserEmailTab.UpdateError.InvalidPassword":
      "You have entered an incorrect password. Please confirm your password is correct and try again.",
    "UserEmailTab.CurrentPassword": "Current Password",
    "UserEmailTab.CurrentEmail.EmailHasBeenVerified":
      "This email address has been verified.",
    "UserEmailTab.VerifyIdentity":
      "Please enter your current password to verify your identity.",
    "UserEmailTab.ChangeEmail.NoEmailMessage":
      "Please enter an email address. Your email address will be used if you ever need to reset your password.",
    "UserEmailTab.ChangeEmail.HasEmailMessage":
      "Please ensure your email address is correct below. Your email address will be used if you ever need to reset your password.",
    "UserEmailTab.ChangeEmail.ConfirmationMessageInfo":
      "Upon saving, a confirmation email will be sent to this account to verify that this information is correct. If you do not receive a verification email from us shortly, please check your spam folder or contact your IT department for assistance.",
    "UserEmailTab.ChangeEmail.EmailLabel": "Your Email:",
    "Foundation.SingleSignOnContactSupport":
      "SSO will now be handled in Foundation, please contact Customer support for more details.",
    "IntegratedContent.YourHomeForPolicyManagement":
      "Your home for policy management",
    "IntegratedContent.WelcomeToPowerDms": "Welcome to PowerDMS",
    "IntegratedContent.SecurePlatform":
      "PowerDMS is your secure platform to access the CALEA® Core Standards Resource Library and accompanying sample policies. As updates to the standards or sample policies are made, you will be notified via email to login and access the updates. Watch this video to learn how to get started with accessing your free content.",
    "IntegratedContent.SeeHowItWorks": "See how it works",
    "IntegratedContent.WhatsIncluded": "What's included?",
    "IntegratedContent.CaleaCoreStandardsResourceLibrary":
      "CALEA® Core Standards Resource Library",
    "IntegratedContent.StandardsThatHelpYou":
      "Standards that help you assess and evaluate your liability and compliance efforts.",
    "IntegratedContent.AddressDuties":
      "Address high-liability and critical duties",
    "IntegratedContent.SelfAssess":
      "Self-assess your agency's policies and procedures against best practices",
    "IntegratedContent.EvaluateAccreditationReadiness":
      "Evaluate CALEA accreditation readiness",
    "IntegratedContent.StandardsImageAltText": "CALEA Standards File Tree",
    "IntegratedContent.SamplePolicies": "Sample Policies",
    "IntegratedContent.ContentProvided":
      "Content provided by high-performing CALEA accredited agencies.",
    "IntegratedContent.WrittenAgainst":
      "Written against internationally recognized standards",
    "IntegratedContent.AccessToUpdates": "Access to future policy updates",
    "IntegratedContent.AutomaticEmailNotifications":
      "Automatic email notifications of when policies have been updated",
    "IntegratedContent.UseOfForcePolicyImageAltText":
      "Use of force policy document overlayed with image of a person accessing documents on their mobile device",
    "IntegratedContent.YouAreDoingYourBest":
      "You are doing your best to deliver transparent, safe, and accountable services to your community. The CALEA® Core Standards Resource Library was created to help you prove, to your community and credentialing bodies, you are doing the right things.",
    "IntegratedContent.WhyPowerDms": "Why PowerDMS?",
    "IntegratedContent.ExcellenceMadeEasy": "Excellence Made Easy",
    "IntegratedContent.PowerDmsIsAlreadyUtilized":
      "PowerDMS is already being utilized by over 3,500 public safety agencies to improve transparency, accountability, and efficiency.",
    "IntegratedContent.LearnMore": "Learn more about PowerDMS",
    "IntegratedContent.FirstRespondersImageAltText": "First responders on duty",
    "IntegratedContent.ShieldIconAltText": "Icon depicting an officer's shield",
    "IntegratedContent.KeepOfficersSafe": "Keep Officers Safe",
    "IntegratedContent.CollaborationTools":
      "Collaboration tools to regularly review policies to ensure their effectiveness.",
    "IntegratedContent.SignatureIconAltText":
      "Icon depicting a pen signing a document",
    "IntegratedContent.HoldOfficersAccountable": "Hold Officers Accountable",
    "IntegratedContent.PowerDmsEnsures":
      "PowerDMS ensures officers acknowledge all critical policy and training updates.",
    "IntegratedContent.WebIconAltText": "Icon depicting the world wide web",
    "IntegratedContent.BePubliclyTransparent": "Be Publicly Transparent",
    "IntegratedContent.SpecifySelectPolicies":
      "Specify select policies stored in PowerDMS to be made publicly available.",
    "IntegratedContent.ExplorePowerDms": "Explore PowerDMS",
    "IntegratedContent.WatchVideoTutorial": "Watch Video Tutorial",
    "IntegratedContent.ContactPowerDmsSales": "Contact PowerDMS Sales",
    "IntegratedContent.GettingStartedVideo":
      "Video: Getting Started Accessing CALEA Core Standards Resource Library and Sample Policies",
    "IntegratedContent.WelcomeVideo": "Video: Message from CALEA",
    "ProviderFeaturePage.ByPowerDMS": "By PowerDMS",
    "ProviderFeaturePage.Info":
      "Make use of %(providerName)s online editing tools you love inside PowerDMS.",
    "ProviderFeaturePage.GetFeature": "Get Feature",
    "ProviderFeaturePage.FreeWithPowerDMS": "Free with PowerDMS",
    "ProviderFeaturePage.Overview": "Overview",
    "ProviderFeaturePage.Support": "Support",
    "ProviderFeaturePage.SupportDocumentation": "Support Documentation",
    "ProviderFeaturePage.PrivacyPolicy": "Privacy Policy",
    "ProviderFeaturePage.Enabling": "Enabling...",
    "GoogleDriveFeaturePage.Description":
      "Google Drive™ is a cloud-based document storage with online editing capabilities. With this integration, you can use the Google Drive online editing tools you love with the accountability features of PowerDMS. Key Features:",
    "GoogleDriveFeaturePage.InstructionOne":
      "Import files directly from Google Drive to continue using familiar online editors.",
    "GoogleDriveFeaturePage.InstructionTwo":
      "Connect to external content providers who utilize Google Drive to share their documents.",
    "GoogleDriveFeaturePage.InstructionThree":
      "Limit who has access to import an update from a Google Drive document into PowerDMS.",
    "GoogleDriveFeaturePage.AltTextForImageNewDocument":
      "This is an image of the new document page with the Google Drive and OneDrive icons visible.",
    "GoogleDriveFeaturePage.AltTextForImageAccountSelection":
      "An image showing the authentication screen of Google Drive",
    "OneDriveFeaturePage.Description":
      "Microsoft OneDrive™ is a cloud storage and file editing service. With this integration, you can use the Microsoft tools you love with the accountability features of PowerDMS. Some key features are:",
    "OneDriveFeaturePage.InstructionOne":
      "Importing files directly from OneDrive to continue using familiar online editors.",
    "OneDriveFeaturePage.InstructionTwo":
      "Connect to external content providers who utilize OneDrive to share their documents.",
    "OneDriveFeaturePage.InstructionThree":
      "Limit who has access to import an update from a OneDrive document into PowerDMS.",
    "OneDriveFeaturePage.AltTextForImageNewDocument":
      "An image of the new document page",
    "OneDriveFeaturePage.AltTextForImageAccountSelection":
      "An image showing the authentication screen of OneDrive",
  };
  if (!window.localStrings) {
    window.localStrings = [];
  }

  for (var key in translations) {
    window.localStrings[key] = translations[key];
  }
})();
