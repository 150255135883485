import * as PropTypes from 'prop-types';
import * as React from 'react';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import * as _ from 'lodash';

import { requestInterval, clearRequestInterval } from 'lib/request-interval';
import { IFlatListItem } from 'modules/presentation/services/dms-tree-service';
import { isNil, isNonEmptyString } from 'modules/main/services/lodash-extended';
import { IDefaultDmsObjectTreeItem } from 'modules/dms-object/models/IDmsObjectTreeItem';
import { isIDmsObjectTreeContainerItem } from 'modules/dms-object/models/IDmsObjectTreeRoot';
import { Logger } from 'modules/main/services/js-logger-extended';

type TRenderIndices = {
  overscanStartIndex: number;
  overscanStopIndex: number;
  startIndex: number;
  stopIndex: number;
};

export type TTreeVirtualizedProps<T> = {
  getChildren: (treeItem: T) => T[];
  isCollapsed?: (treeItem: T) => boolean;
  getItemHeight: (treeItemVirtualized: IFlatListItem<T>) => number;
  listClassName?: string;
  onItemsRendered?: (renderedItemList: IFlatListItem<T>[]) => void;
  onScroll?: (scrollProps: {
    clientHeight: number;
    scrollHeight: number;
    scrollTop: number;
  }) => void;
  onScrollEnd?: () => void;
  overscanRowCount: number;
  renderTreeItem: (
    treeItemVirtualized: IFlatListItem<T>,
    shouldRenderPlaceholder: boolean,
  ) => JSX.Element;
  scrollToAlignment: string;
  scrollToKey: string;
  flatTree: IFlatListItem<T>[];
  isVisible?: boolean;
};

export class TreeVirtualized<T> extends React.Component<TTreeVirtualizedProps<T>, {}> {
  static propTypes = {
    getChildren: PropTypes.func.isRequired,
    isCollapsed: PropTypes.func,
    getItemHeight: PropTypes.func.isRequired,
    listClassName: PropTypes.string,
    onItemsRendered: PropTypes.func,
    onScroll: PropTypes.func,
    onScrollEnd: PropTypes.func,
    onStartIndexChange: PropTypes.func,
    overscanRowCount: PropTypes.number,
    renderTreeItem: PropTypes.func.isRequired,
    scrollToKey: PropTypes.string,
    flatTree: PropTypes.array.isRequired,
    isVisible: PropTypes.bool,
  };

  private forcePlaceholderRender: boolean = false;
  private scrollPosition: number = 0;
  private renderIndices: TRenderIndices = {
    overscanStartIndex: 0,
    overscanStopIndex: 0,
    startIndex: 0,
    stopIndex: 0,
  };
  private scrollStartIndex: number;
  private scrollStopIndex: number;
  private currentRenderedItemList: IFlatListItem<T>[] = [];
  private listRef: React.RefObject<List>;

  constructor(props: TTreeVirtualizedProps<T>) {
    super(props);

    this.onScroll = this.onScroll.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.rowHeight = this.rowHeight.bind(this);
    this.onRowsRendered = this.onRowsRendered.bind(this);

    this.listRef = React.createRef();
  }

  private calculateMinimumTreeHeight(maxHeight: number) {
    let height = 0;

    for (const treeItem of this.props.flatTree) {
      height += this.props.getItemHeight(treeItem);

      if (height > maxHeight) return maxHeight;
    }

    return height;
  }

  public componentDidUpdate(
    prevProps: Readonly<TTreeVirtualizedProps<T>>,
    prevState: Readonly<{}>,
    snapshot,
  ): void {
    const visibilityChanged = this.props.isVisible !== prevProps.isVisible;

    /**
     * Everything below this check requires the listRef to be defined,
     * so if it's undefined, do not continue.
     */
    if (isNil(this.listRef.current)) {
      Logger.error('Ref was undefined in "componentDidUpdate()"');

      return;
    }

    /**
     * We're setting "scrollToIndex" immediately after the tree becomes visible
     * in order to maintain scroll position, then immediately unsetting it on the next update
     * so we aren't constantly scrolling to an index on every render.
     */
    if (visibilityChanged && this.props.isVisible) {
      this.listRef.current.scrollToRow(this.scrollPosition);
    }

    const scrollToKeyHasChanged = prevProps.scrollToKey !== this.props.scrollToKey;

    if (this.props.scrollToKey !== undefined && scrollToKeyHasChanged) {
      this.scrollToTreeItem(this.props.scrollToKey);
    }

    /**
     * We need to recompute the row heights if the tree reference has changed.
     * We can't rely on "onRowsRendered", because if "rowcount" hasn't changed,
     * the List component won't re-render.
     */
    if (prevProps.flatTree !== this.props.flatTree) {
      this.listRef.current.recomputeRowHeights();
    }
  }

  private onRowsRendered(params: {
    overscanStartIndex: number;
    overscanStopIndex: number;
    startIndex: number;
    stopIndex: number;
  }) {
    this.currentRenderedItemList = this.props.flatTree.slice(
      params.startIndex,
      params.stopIndex + 1,
    );

    if (_.isFunction(this.props.onItemsRendered)) {
      this.props.onItemsRendered(this.currentRenderedItemList);
    }

    this.renderIndices = params;
    this.scrollPosition = this.renderIndices.startIndex;
  }

  private onScroll(params: {
    clientHeight: number;
    scrollHeight: number;
    scrollTop: number;
  }): void {
    if (this.scrollStartIndex === undefined) {
      this.scrollStartIndex = this.renderIndices.overscanStartIndex - this.props.overscanRowCount;
    }

    if (this.scrollStopIndex === undefined) {
      this.scrollStopIndex = this.renderIndices.overscanStopIndex + this.props.overscanRowCount;
    }

    if (this.props.onScroll !== undefined) {
      this.props.onScroll(params);
    }
  }

  private rowHeight(params: ListRowProps): number {
    return this.props.getItemHeight(this.props.flatTree[params.index]);
  }

  private rowRenderer(params: ListRowProps): JSX.Element {
    const shouldRenderPlaceholder = this.shouldRenderPlaceholder(params);
    const flatListItem = this.props.flatTree[params.index];
    const key = `${params.key}|${flatListItem.data.key}`;

    return (
      <li
        id={flatListItem.data.key}
        key={key}
        style={{
          ...params.style,
          listStyle: 'none',
          padding: 0,
          // @ts-ignore for some reason, ts doesn't support this value
          scrollSnapAlign: 'start',
        }}
        {...getTreeItemAriaOverrides(params.index, flatListItem)}
      >
        {this.props.renderTreeItem(flatListItem, shouldRenderPlaceholder)}
      </li>
    );
  }

  private shouldRenderPlaceholder({ index, isScrolling }: ListRowProps): boolean {
    if (this.forcePlaceholderRender) {
      return true;
    }

    let shouldRenderPlaceholder = false;

    if (isScrolling) {
      if (index < this.scrollStartIndex || index > this.scrollStopIndex) {
        shouldRenderPlaceholder = true;
      }
    } else {
      /**
       * We set these in "onScroll", but since we don't have an "onScrollStop",
       * we need to unset them here. This is a little side-effecty,
       * but I can't think of a better way.
       */
      this.scrollStartIndex = undefined;
      this.scrollStopIndex = undefined;
    }

    return shouldRenderPlaceholder;
  }

  /**
   * Note: Keys that are passed into this method do not contain tree index data,
   * hence why we chop the beginning of the key for each flatList item.
   */
  private scrollToTreeItem(key: string) {
    const onScrollEnd = () => {
      if (this.forcePlaceholderRender) {
        this.forcePlaceholderRender = false;
      }

      if (this.props.onScrollEnd !== undefined) {
        this.props.onScrollEnd();
      }
    };
    const targetIndex = _.findIndex(this.props.flatTree, (listItem) => {
      const listItemKey = listItem.data.key;
      const objectKey = listItemKey.replace(/^(\d\-*)*\|/, '');

      return objectKey === key;
    });

    if (targetIndex === -1) {
      onScrollEnd();
      return;
    }

    const easeOutCubic = (t) => (t - 1) * (t - 1) * (t - 1) + 1;
    const { startIndex, stopIndex } = this.renderIndices;
    const indexDelta = targetIndex - startIndex;
    const startTime = Date.now();

    // The 1 item offset on the stopIndex is to account for partially-visible item
    if (targetIndex >= startIndex && targetIndex < stopIndex) {
      onScrollEnd();
      return;
    }

    if (Math.abs(indexDelta) > this.currentRenderedItemList.length) {
      this.forcePlaceholderRender = true;
    }

    const endScrolling = () => {
      clearRequestInterval(scrollInterval);
      scrollInterval = undefined;
      onScrollEnd();
    };
    let scrollInterval = requestInterval(() => {
      const timeDelta = Date.now() - startTime;
      const timeRatio = timeDelta / 1000;
      const indexRatio = easeOutCubic(timeRatio);
      const currentIndex = startIndex + Math.round(indexDelta * indexRatio);
      const doneScrollingDown = targetIndex > startIndex && currentIndex >= targetIndex;
      const doneScrollingUp = targetIndex < startIndex && currentIndex <= targetIndex;

      /**
       * It may be possible for the ref to become undefined during this loop,
       * in which case, we'll exit out of it to prevent flooding New Relic with errors.
       */
      if (isNil(this.listRef.current)) {
        endScrolling();

        Logger.error('Ref was undefined in "scrollInterval()"');

        return;
      }

      this.listRef.current.scrollToRow(currentIndex);

      if (doneScrollingDown || doneScrollingUp) {
        endScrolling();
      }
    }, 1000 / 60);
  }

  public render() {
    const { listClassName, overscanRowCount, flatTree } = this.props;

    return (
      <AutoSizer>
        {({ height, width }) => {
          /* When the tree is small enough to not take up the full height, we want
                    to set the height to exactly how big it is and no more. This is so we can
                    capture dnd dragenter/dragleave events within the empty space below the tree.*/
          const minTreeHeight = this.calculateMinimumTreeHeight(height);

          return (
            <List
              className={listClassName}
              ref={this.listRef}
              height={minTreeHeight}
              onRowsRendered={this.onRowsRendered}
              onScroll={this.onScroll}
              overscanRowCount={overscanRowCount}
              rowCount={flatTree.length}
              rowHeight={this.rowHeight}
              rowRenderer={this.rowRenderer}
              scrollToAlignment="start"
              width={width}
              {...getTreeAriaOverrides()}
            />
          );
        }}
      </AutoSizer>
    );
  }
}

export function getAriaOwnsGroup(treeItem: IFlatListItem<IDefaultDmsObjectTreeItem>): string {
  let ariaOwnsGroup = '';

  if (isIDmsObjectTreeContainerItem(treeItem.data)) {
    ariaOwnsGroup = treeItem.data.content.map((childItem) => childItem.key).join(' ');
  }

  return ariaOwnsGroup;
}

type TTreeItemAriaOverrides = {
  role: string;
  tabIndex: number;
  'aria-selected'?: boolean;
  'aria-owns'?: string;
  'aria-level'?: number;
  'aria-setsize'?: number;
  'aria-posinset'?: number;
  'aria-expanded'?: boolean;
};

export function getTreeItemAriaOverrides(index: number, treeItem): TTreeItemAriaOverrides {
  const ariaAttributes: TTreeItemAriaOverrides = {
    role: 'treeitem',
    // We handle tabIndex on the buttons and links in the treeItems, since they receive the focus
    tabIndex: -1,
    'aria-selected': treeItem.data.isItemSelected,
    ...treeItem.ariaAttributes,
  };

  const ariaOwnsGroup = getAriaOwnsGroup(treeItem);
  if (isNonEmptyString(ariaOwnsGroup)) {
    ariaAttributes['aria-owns'] = ariaOwnsGroup;
  }

  if (isIDmsObjectTreeContainerItem(treeItem.data)) {
    ariaAttributes['aria-expanded'] = !treeItem.data.isCollapsed;
  }

  return ariaAttributes;
}

export function getTreeAriaOverrides() {
  /**
   * The following are overrides to the default roles
   * and aria attributes set by the <List/> component.
   *
   * The three null values WILL throw a propTypes error when
   * building react in dev mode. This is an expected error
   * and represents the only way to successfully remove those
   * from the DOM to achieve accessibility goals.
   *
   * The three null values WILL NOT throw a propTypes error when
   * building react in prod mode.
   *
   * TODO: Reorganize how tree is structured using another
   * tool besides react-virtualized to more naturally achieve
   * accessbility goals
   */
  return {
    'aria-label': null,
    role: null,
    containerRole: null,
  };
}
