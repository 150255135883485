import * as _ from 'lodash';

import { DocumentsContainer } from 'modules/document/models';
import { Folder } from 'modules/folder/models';
import { Group, GroupsContainer } from 'modules/group/models';
import { IVariablesFromServer } from 'modules/main/models/variables-from-server';
import { getDataFromResponse } from 'modules/main/utilities/utils';
import { ISearchResult } from 'modules/search/managers/search-manager-factory';

import { ObjectStatus, ObjectType, Permission } from 'modules/dms-object/enums';
import { DmsObjectRepository } from 'modules/dms-object/managers';
import { DmsObjectResource } from 'modules/dms-object/resources/dms-object-resource';
import { UpdateResource } from 'modules/dms-object/resources/update-resource';
import { ObjectStatusService, DmsObjectService } from 'modules/dms-object/services';
import { IModifiedEventPayload } from 'modules/event/models/i-modified-event-payload';

const serializable: string[] = [
  'bookmarked',
  'breadcrumbs',
  'cascadingRights',
  'childObjectTypes',
  'enabled',
  'hasAdminPage',
  'hasClientPage',
  'id',
  'isSystem',
  'name',
  'objectType',
  'rawDmsObject',
  'rights',
];

// TODO: Once we have created concrete objects for all dms objects, this should then be turned into
// an abstract class
export class DmsObject {
  public static EVENTS = {
    Modified: 'dmsObjectModifiedEvent',
  };

  /**
   * NOTE: Private members (prepended with _) have a setter that broadcast an event with payload
   * whenever their values changed. If you need to do the same thing to the other public members,
   * follow the pattern.
   */
  private _bookmarked: boolean;
  public breadcrumbs: DmsObject[][] = [];
  public cascadingRights: Permission;
  public childObjectTypes: Array<ObjectType> = [];
  public enabled: boolean;
  public hasAdminPage = false;
  public hasClientPage = false;
  public id: string;
  public isSystem: boolean;
  public isRead: boolean;
  private _name: string;
  private _objectStatus: ObjectStatus;
  public objectType: ObjectType;
  public rawDmsObject: ISearchResult;
  private _rights: Permission;
  private _nodeOrder: number;

  protected serializableProperties: string[] = serializable;

  protected $rootScope: ng.IRootScopeService;
  protected dmsObjectRepository: DmsObjectRepository;
  protected dmsObjectResource: DmsObjectResource;
  private dmsObjectService: DmsObjectService;
  private getObjectResource: UpdateResource<DmsObject>;
  protected objectStatusService: ObjectStatusService;
  protected vars: IVariablesFromServer;

  public static $inject = ['$injector'];

  public constructor(protected $injector: ng.auto.IInjectorService) {
    // We're injecting this way so child classes don't have to pass all of these.
    this.$rootScope = $injector.get('$rootScope');
    this.dmsObjectRepository = $injector.get('DmsObjectRepository');
    this.dmsObjectResource = $injector.get('DmsObjectResource');
    this.dmsObjectService = $injector.get('DmsObjectService');
    this.objectStatusService = $injector.get('ObjectStatusService');
    this.vars = $injector.get('variablesFromServer');

    this.getObjectResource = $injector.instantiate<UpdateResource<DmsObject>>(UpdateResource);
    this.getObjectResource.setGetRequest(() => {
      return this.dmsObjectResource
        .getRawDmsObject(this.objectType, parseInt(this.id), true)
        .then((response) => {
          response = getDataFromResponse(response);
          var rawDmsObject = this.dmsObjectResource.convertDmsObjectFromServerToClient(
            response,
            true,
          );
          // sync will call refreshModel on this object to update it
          this.dmsObjectRepository.sync(rawDmsObject);
          return this;
        });
    });
  }

  public canBookmark(): boolean {
    return false;
  }

  public destroy() {}

  public getAdminPath(): string {
    return;
  }

  public getClientPath(): string {
    return;
  }

  public getObject(): ng.IPromise<DmsObject> {
    return this.getObjectResource.get();
  }

  public getObjectIfStale(maxSecondsStale: number = 60): ng.IPromise<DmsObject> {
    return this.getObjectResource.getIfStale(maxSecondsStale);
  }

  public getObjectStatus(): ObjectStatus {
    return this.objectStatusService.getObjectStatus(this.rawDmsObject);
  }

  public getParent(): DmsObject {
    return this.getParents()[0];
  }

  public getParents(): DmsObject[] {
    if (this.hasBreadcrumbs()) {
      return _.uniq(_.map(this.breadcrumbs, (trail) => trail[trail.length - 1]));
    }

    let root = this.dmsObjectRepository.getRootContainerOfObjectType(this.objectType);
    if (root && root != this) return [root];

    return [];
  }

  public getPath(checkPermissions = false): string {
    if (this.hasClientPage) return this.getClientPath();
    if (this.hasAdminPage) {
      if (checkPermissions && !this.hasMinimumRights(Permission.Audit)) {
        return;
      }
      return this.getAdminPath();
    }
    return;
  }

  public hasBreadcrumbs(): boolean {
    return this.breadcrumbs && this.breadcrumbs.length > 0;
  }

  public hasMinimumCascadingRights(minimumRights: Permission): boolean {
    return (
      Permission.convertToNumber(this.cascadingRights) >= Permission.convertToNumber(minimumRights)
    );
  }

  public hasMinimumRights(minimumRights: Permission): boolean {
    return Permission.convertToNumber(this.rights) >= Permission.convertToNumber(minimumRights);
  }

  public isNestedContentSupported(): boolean {
    switch (this.objectType) {
      case ObjectType.Document:
      case ObjectType.Folder:
      case ObjectType.Group:
      case ObjectType.User:
        return true;
      default:
        return false;
    }
  }

  public isNestedSecuritySupported(): boolean {
    return this.dmsObjectResource.isNestedSecuritySupportedFor(this.rawDmsObject);
  }

  public refreshModel(rawDmsObject: ISearchResult) {
    this.rawDmsObject = rawDmsObject;

    this.id = rawDmsObject.id;
    this.isSystem = rawDmsObject.isSystem;
    this.name = rawDmsObject.name;
    this.nodeOrder = rawDmsObject.nodeOrder;
    this.objectStatus = rawDmsObject.objectStatus;
    this.objectType = rawDmsObject.objectType;
    this.isRead = rawDmsObject.isRead;

    if (rawDmsObject.callingContext) {
      this.rights = rawDmsObject.callingContext.userPermissions;
      this.cascadingRights = rawDmsObject.callingContext.userCascadingPermissions;
    } else {
      this.rights = rawDmsObject.permission;
    }

    // Search objects don't come back with calling context,
    //     so we use a separate prop for cascading rights
    if (rawDmsObject.isEditableWithCascade) {
      this.rights = this.cascadingRights = Permission.Edit;
    }

    if (this.hasMinimumRights(Permission.Audit) && !this.vars.priv_ADMIN) {
      this.rights = Permission.View;
    }
    if (this.hasMinimumCascadingRights(Permission.Audit) && !this.vars.priv_ADMIN) {
      this.cascadingRights = Permission.View;
    }

    // Convert breadcrumbs to dmsObjects, but only if we fetched breadcrumbs. Search
    //    doesn't return breadcrumbs for tree items because we don't need it.
    if (rawDmsObject.breadcrumbs) {
      this.breadcrumbs = _.map(rawDmsObject.breadcrumbs, (breadcrumbItem) => {
        return _.map(breadcrumbItem.trail, (trailItem) => {
          let trailDmsObject = this.dmsObjectRepository.getDmsObjectModel(trailItem);
          // IMPORTANT! Check for existing object first to prevent O(n!) recursive recalculating
          //    of all breadcrumbs and all their breadcrumbs, because sync calls addObject for new objects,
          //    which calls createDmsObject, which calls refreshModel.
          if (!trailDmsObject) {
            trailDmsObject = this.dmsObjectRepository.syncDmsObjectModel(trailItem);
          }
          return trailDmsObject;
        });
      });
    }
  }

  public rename(name: string): ng.IPromise<DmsObject> {
    throw 'function not implemented';
  }

  public sync(): void {
    this.dmsObjectRepository.sync(this.generateRawDmsObject());
  }

  private generateRawDmsObject(): ISearchResult {
    const object = {};

    for (const property of this.serializableProperties) {
      object[property] = this[property];
    }

    return <ISearchResult>object;
  }

  public getDisplayName(): string {
    return this.dmsObjectService.getDisplayName(this);
  }

  protected setAndBroadcast<K extends keyof this>(publicProp: K, value: this[K]) {
    const privateProp = `_${String(publicProp)}`;

    if (value === this[privateProp]) return;

    const payload: IModifiedEventPayload<K, this[K]> = {
      objectName: 'DmsObject',
      prop: publicProp,
      oldValue: this[publicProp],
      newValue: value,
    };

    this[privateProp] = value;
    this.$rootScope.$broadcast(DmsObject.EVENTS.Modified, payload);
  }

  public get bookmarked(): boolean {
    return this._bookmarked;
  }
  public set bookmarked(value: boolean) {
    this.setAndBroadcast('bookmarked', value);
  }

  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this.setAndBroadcast('name', value);
  }

  public get nodeOrder(): number {
    return this._nodeOrder;
  }
  public set nodeOrder(value: number) {
    this.setAndBroadcast('nodeOrder', value);
  }

  public get rights(): Permission {
    return this._rights;
  }
  public set rights(value: Permission) {
    this.setAndBroadcast('rights', value);
  }

  public get objectStatus(): ObjectStatus {
    return this._objectStatus;
  }
  public set objectStatus(value: ObjectStatus) {
    this.setAndBroadcast('objectStatus', value);
  }
}

export class IHaveFolderParent extends DmsObject {
  parent: Folder | DocumentsContainer;
}

export class IHaveGroupParents extends DmsObject {
  parents: Array<Group | GroupsContainer>;
}
