import { Keys } from 'locales/keys';
import { NodeType, ObjectType } from 'modules/dms-object/enums';
import { Icon } from 'modules/presentation/enums';

const blueColorHex: string = '#00A0DA';
const greenColorHex: string = '#43BB4F';
const manilaColorHex: string = '#DBB586';
const whiteColorHex: string = '#FFFFFF';

export class DmsIconMappings {
  static iconClasses: { [key: string]: string } = {
    [Icon.AccredAccount]: 'pow-accred-account',
    [Icon.Add]: 'fa fa-plus-circle',
    [Icon.Assessment]: 'pow-assessment',
    [Icon.Bookmark]: 'fa fa-bookmark',
    [Icon.TraverseOut]: 'fa fa-chevron-left',
    [Icon.TraverseIn]: 'fa fa-chevron-right',
    [Icon.Cancel]: 'fa fa-minus-circle',
    [Icon.Certificate]: 'pow-certificate',
    [Icon.Chapter]: 'pow-manual-chapter',
    [Icon.Clear]: 'fa fa-times-circle',
    [Icon.Cloud]: 'fa fa-cloud',
    [Icon.Compare]: 'fa fa-copy',
    [Icon.Copy]: 'fa fa-link',
    [Icon.Course]: 'pow-course',
    [Icon.Delete]: 'fa fa-times',
    [Icon.Discussion]: 'pow-discussion',
    [Icon.Document]: 'pow-document',
    [Icon.DocumentBlank]: 'pow-document-blank',
    [Icon.DocumentHtml]: 'pow-document-html',
    [Icon.DocumentExcel]: 'pow-document-xlsx',
    [Icon.DocumentPDF]: 'fa fa-file-pdf-o',
    [Icon.DocumentPowerPoint]: 'pow-document-ppt',
    [Icon.DocumentPublished]: 'pow-document-published',
    [Icon.DocumentTemplate]: 'pow-document-template',
    [Icon.DocumentWord]: 'pow-document-word',
    [Icon.Download]: 'fa fa-download',
    [Icon.DropDownEllipsis]: 'fa fa-ellipsis-v',
    [Icon.Edit]: 'fa fa-pencil',
    [Icon.EditRevision]: 'fa fa-pencil-square-o',
    [Icon.Enabled]: 'fa fa-check-circle',
    [Icon.Error]: 'fa fa-exclamation-circle',
    [Icon.EventLog]: 'pow-event-log',
    [Icon.Excel]: 'fa fa-file-excel-o',
    [Icon.Exit]: 'fa fa-times',
    [Icon.ExternalLink]: 'fa fa-external-link-square',
    [Icon.Files]: 'fa fa-files-o',
    [Icon.Folder]: 'pow-folder',
    [Icon.Gift]: 'fa fa-gift',
    [Icon.Google]: 'pow pow-google-drive',
    [Icon.GoogleColor]: 'pow pow-google-drive-color',
    [Icon.Group]: 'pow-group',
    [Icon.Image]: 'fa fa-file-image-o',
    [Icon.ImportDocument]: 'fa fa-cloud-download',
    [Icon.Imported]: 'fa fa-check-circle',
    [Icon.ImportedSuccess]: 'fa fa-check-circle success-color',
    [Icon.Inbox]: 'fa fa-inbox',
    [Icon.InfoCircle]: 'fa fa-info-circle',
    [Icon.Information]: 'pow-manual-info',
    [Icon.LinkedGroup]: 'pow-linked-group',
    [Icon.Manual]: 'pow-manual',
    [Icon.Message]: 'fa fa-envelope-o',
    [Icon.MoreInformation]: 'fa fa-info-circle',
    [Icon.NewWindow]: 'fa fa-external-link',
    [Icon.Notification]: 'fa fa-bell',
    [Icon.PasswordHide]: 'fa fa-eye-slash',
    [Icon.PasswordShow]: 'fa fa-eye',
    [Icon.Print]: 'fa fa-print',
    [Icon.Plug]: 'fa fa-plug',
    [Icon.PowerDMS]: 'pow-logo',
    [Icon.OneDrive]: 'pow pow-microsoft-onedrive',
    [Icon.OneDriveColor]: 'pow pow-one-drive-color',
    [Icon.Refresh]: 'fa fa-refresh',
    [Icon.Reply]: 'fa fa-mail-reply',
    [Icon.ReviewChanges]: 'fa fa-arrow-circle-up',
    [Icon.Revisions]: 'fa fa-history',
    [Icon.Save]: 'fa fa-save',
    [Icon.Search]: 'fa fa-search',
    [Icon.SearchSuggestion]: 'fa fa-search',
    [Icon.SignOut]: 'fa fa-sign-out',
    [Icon.SpinnerLoading]: 'loadingIcon_spinner',
    [Icon.Standard]: 'pow-manual-standard',
    [Icon.Stop]: 'fa fa-stop-circle',
    [Icon.StorageProvider]: 'pow-file-cabinet pow-content-sharing-provider',
    [Icon.StorageProvidersRoot]: 'fa fa-cloud-upload',
    [Icon.Success]: 'fa fa-check',
    [Icon.Survey]: 'pow-survey',
    [Icon.Tag]: 'fa fa-tags',
    [Icon.Test]: 'pow-test',
    [Icon.Text]: 'fa fa-file-text-o',
    [Icon.Trash]: 'fa fa-trash',
    [Icon.TreeItemCollapsed]: 'fa fa-caret-right',
    [Icon.TreeItemExpanded]: 'fa fa-caret-down',
    [Icon.TripleDotLoading]: 'loadingIcon_tripleDot',
    [Icon.Unbookmark]: 'fa fa-bookmark-o',
    [Icon.UpdateAvailable]: 'fa fa-flag',
    [Icon.Upload]: 'fa fa-cloud-upload',
    [Icon.User]: 'pow-user',
    [Icon.ViewObject]: 'fa fa-edit',
    [Icon.Warning]: 'fa fa-exclamation-triangle',
    [Icon.Workflow]: 'pow-workflow',
    [Icon.ArrowUp]: 'fa fa-arrow-up',
    [Icon.ArrowDown]: 'fa fa-arrow-down',
  };

  static iconColorHexes: {} = {
    [Icon.Assessment]: greenColorHex,
    [Icon.Certificate]: whiteColorHex,
    [Icon.Chapter]: greenColorHex,
    [Icon.Course]: blueColorHex,
    [Icon.Document]: whiteColorHex,
    [Icon.Folder]: manilaColorHex,
    [Icon.Group]: greenColorHex,
    [Icon.Information]: whiteColorHex,
    [Icon.LinkedGroup]: greenColorHex,
    [Icon.Manual]: greenColorHex,
    [Icon.Standard]: whiteColorHex,
    [Icon.StorageProviderDocument]: whiteColorHex,
    [Icon.StorageProvidersRoot]: whiteColorHex,
    [Icon.Survey]: whiteColorHex,
    [Icon.Test]: whiteColorHex,
    [Icon.User]: blueColorHex,
  };

  static typeToIconMapping: { [key: string]: Icon } = {
    [NodeType.Chapter]: Icon.Chapter,
    [NodeType.Information]: Icon.Information,
    [NodeType.Standard]: Icon.Standard,
    [ObjectType.Assessment]: Icon.Assessment,
    [ObjectType.AssessmentContainer]: Icon.Assessment,
    [ObjectType.Certificate]: Icon.Certificate,
    [ObjectType.CertificateContainer]: Icon.Certificate,
    [ObjectType.CertificateType]: Icon.Folder,
    [ObjectType.Course]: Icon.Course,
    [ObjectType.CourseContainer]: Icon.Course,
    [ObjectType.CourseType]: Icon.Folder,
    [ObjectType.Document]: Icon.Document,
    [ObjectType.DocumentContainer]: Icon.Document,
    [ObjectType.Folder]: Icon.Folder,
    [ObjectType.Group]: Icon.Group,
    [ObjectType.LinkedGroup]: Icon.LinkedGroup,
    [ObjectType.Manual]: Icon.Manual,
    [ObjectType.ManualContainer]: Icon.Manual,
    [ObjectType.StorageProvider]: Icon.StorageProvider,
    [ObjectType.StorageProviderDocument]: Icon.Document,
    [ObjectType.StorageProviderFolder]: Icon.Folder,
    [ObjectType.StorageProvidersRoot]: Icon.StorageProvidersRoot,
    [ObjectType.Survey]: Icon.Survey,
    [ObjectType.SurveyContainer]: Icon.Survey,
    [ObjectType.Tag]: Icon.Tag,
    [ObjectType.Test]: Icon.Test,
    [ObjectType.TestContainer]: Icon.Test,
    [ObjectType.User]: Icon.User,
  };

  static iconTitleKeys: { [key: string]: any } = {
    [Icon.Assessment]: Keys.ObjectTypes.Assessment,
    [Icon.Certificate]: Keys.ObjectTypes.Certificate,
    [Icon.Chapter]: Keys.ObjectTypes.Chapter,
    [Icon.Course]: Keys.ObjectTypes.Course,
    [Icon.Discussion]: Keys.ObjectTypes.Discussion,
    [Icon.Document]: Keys.ObjectTypes.Document,
    [Icon.EventLog]: Keys.ObjectTypes.EventLog,
    [Icon.Folder]: Keys.ObjectTypes.Folder,
    [Icon.Group]: Keys.ObjectTypes.Group,
    [Icon.Information]: Keys.ObjectTypes.Information,
    [Icon.LinkedGroup]: Keys.ObjectTypes.LinkedGroup,
    [Icon.Manual]: Keys.ObjectTypes.Manual,
    [Icon.SearchSuggestion]: Keys.ObjectTypes.SearchSuggestion,
    [Icon.Standard]: Keys.ObjectTypes.Standard,
    [Icon.StorageProvider]: Keys.ObjectTypes.StorageProvider,
    [Icon.StorageProvidersRoot]: Keys.ObjectTypes.StorageProvidersRoot,
    [Icon.Survey]: Keys.ObjectTypes.Survey,
    [Icon.Tag]: Keys.ObjectTypes.Tag,
    [Icon.Test]: Keys.ObjectTypes.Test,
    [Icon.User]: Keys.ObjectTypes.User,
    [Icon.Workflow]: Keys.ObjectTypes.Workflow,
  };
}
